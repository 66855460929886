import {React,useState,useEffect} from "react";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {sendGetRequestAll, sendPostRequestAll, sendDeleteRequestAll} from "../../helpers/httprequesthelper";
import * as Constants from "constants/constants.js";
import {Modal} from "react-responsive-modal";
import {HttpStatusCode} from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "helpers/firebase.js";
import {useHistory} from "react-router-dom";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

export default function UpdateProduct() {
    var fetchBrandDataRetryCount = 0;
    var fetchCategoryDataRetryCount = 0;
    const maxRetryCount = 5;
    const [brandsData, setBrandsData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [searchId, setSearchId] = useState(null);
    const [barcodeId, setBarcodeId] = useState(null);
    const [barcode, setBarcode] = useState(null);
    const [openCamera, setOpenCamera] = useState(false);
    const [id, setId] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [data, setData] = useState(null);
    const [title, setTitle] = useState(null);
    const [subtitle, setSubtitle] = useState(null);
    const [description, setDescription] = useState(null);
    const [starRating, setStarRating] = useState(null);
    const [quantity, setQuantity] = useState(null);
    const [maxQuantityPerOrder, setMaxQuantityPerOrder] = useState(null);
    const [mrp, setMrp] = useState(null);
    const [costPrice, setCostPrice] = useState(null);
    const [sellingPrice, setSellingPrice] = useState(null);
    const [lowestSellingPrice, setLowestSellingPrice] = useState(null);
    const [wholesalePrice, setWholesalePrice] = useState(null);
    const [mediaLink, setMediaLink] = useState(null);
    const [isFavouriteChecked, setIsFavouriteChecked] = useState(false);
    const [isOriginalChecked, setIsOriginalChecked] = useState(true);
    const [isFreeDeliveryEligibleChecked, setIsFreeDeliveryEligibleChecked] = useState(true);
    const [isAvailableChecked, setIsAvailableChecked] = useState(true);
    const [openDeleteProductModal, setOpenDeleteProductModal] = useState(false);
    const [bulletPointComponent, setBulletPointComponent] = useState(null);
    const [imageUrlsComponent, setImageUrlsComponent] = useState(null);
    const [videoUrlsComponent, setVideoUrlsComponent] = useState(null);
    const [user, loading, error] = useAuthState(auth);
    const navigate = useHistory();
    const [openCameraForSearch, setOpenCameraForSearch] = useState(false);

    async function deleteProduct() {
        try {
            console.log("id ==== "  + id);
            var response = await sendDeleteRequestAll(Constants.backendUrl + "/admin/product/delete/" + id, {}, true);

            if (response.status == HttpStatusCode.Ok) {
                if (response.data != null) {
                    setOpenDeleteProductModal(false);
                    setSuccessMessage("Delete successful");
                    setOpenSuccessModal(true);
                    console.log(response.data);
                    setData(null);
                    setShowForm(false);
                }
            } else {
                setErrorMessage(response.data);
                setOpenErrorModal(true);
            }
        } catch (err) {
            console.log("Error in deleting the product data : ");
            console.error(err);
            setOpenErrorModal(true);
            setErrorMessage(err.response.data);
        }
    }

    function onCloseErrorModal() {
        setOpenErrorModal(false);
        setErrorMessage(null);
    }
    function onCloseSuccessModal() {
        setOpenSuccessModal(false);
        setSuccessMessage(null);
    }

    function onCloseDeleteProductModal() {
        setOpenDeleteProductModal(false);
    }

    function onOpenCameraForSearch() {
        setOpenCameraForSearch(true);
    }

    function onCloseCameraForSearch() {
        setOpenCameraForSearch(false);
    }

    function onOpenCamera() {
        setOpenCamera(true);
    }

    function onCloseCamera() {
        setOpenCamera(false);
    }

    const onOpenDeleteProductModal = () => setOpenDeleteProductModal(true);

    async function handleSubmit(e) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        var formJson = Object.fromEntries(formData.entries());
        console.log("Initial formjson  : " + JSON.stringify(formJson));
        if(!formJson.title || !formJson.title.trim()) {
            setErrorMessage("Title is empty");
            setOpenErrorModal(true);
            return;
        }
        if(!formJson.subtitle || !formJson.subtitle.trim()) {
            setErrorMessage("Subtitle is empty");
            setOpenErrorModal(true);
            return;
        }
        if(!formJson.description || !formJson.description.trim()) {
            setErrorMessage("Description is empty");
            setOpenErrorModal(true);
            return;
        }
        if(!formJson.starRating || !formJson.starRating.trim()) {
            setErrorMessage("Star Rating is empty");
            setOpenErrorModal(true);
            return;
        } else if (formJson.starRating <= 0 || formJson.starRating >=5) {
            setErrorMessage("Star Rating is not in range (0.1 - 5.0)");
            setOpenErrorModal(true);
            return;
        }
        if(!formJson.mediaLink || !formJson.mediaLink.trim()) {
            setErrorMessage("Media Link (First Image) is empty");
            setOpenErrorModal(true);
            return;
        }
        if(!formJson.quantity || !formJson.quantity.trim()) {
            setErrorMessage("Quantity is empty");
            setOpenErrorModal(true);
            return;
        } else if (formJson.quantity < 0) {
            setErrorMessage("Quantity can't be less than 0");
            setOpenErrorModal(true);
            return;
        }

        if(!formJson.mrp || !formJson.mrp.trim()) {
            setErrorMessage("Mrp is empty");
            setOpenErrorModal(true);
            return;
        } else if (formJson.mrp <= 0) {
            setErrorMessage("MRP is zero or less than zero");
            setOpenErrorModal(true);
            return;
        }

        if(!formJson.sellingPrice || !formJson.sellingPrice.trim()) {
            setErrorMessage("Selling Price is empty");
            setOpenErrorModal(true);
            return;
        } else if (formJson.sellingPrice <= 0) {
            setErrorMessage("Selling Price is zero or less than zero");
            setOpenErrorModal(true);
            return;
        }

        if(!formJson.maxQuantityPerOrder || !formJson.maxQuantityPerOrder.trim()) {
            formJson.maxQuantityPerOrder = null;
        }

        if(!formJson.costPrice || !formJson.costPrice.trim()) {
            setErrorMessage("Cost Price is empty");
            setOpenErrorModal(true);
            return;
        } else if (formJson.costPrice <= 0) {
            setErrorMessage("Cost Price is zero or less than zero");
            setOpenErrorModal(true);
            return;
        }

        if(!formJson.lowestSellingPrice || !formJson.lowestSellingPrice.trim()) {
            setErrorMessage("Lowest Selling Price is empty");
            setOpenErrorModal(true);
            return;
        } else if (formJson.lowestSellingPrice <= 0) {
            setErrorMessage("Lowest Selling Price is zero or less than zero");
            setOpenErrorModal(true);
            return;
        }

        if(!formJson.wholesalePrice || !formJson.wholesalePrice.trim()) {
            setErrorMessage("Wholesale Price is empty");
            setOpenErrorModal(true);
            return;
        } else if (formJson.wholesalePrice <= 0) {
            setErrorMessage("Wholesale Price is zero or less than zero");
            setOpenErrorModal(true);
            return;
        }

        var favouriteCheckbox = document.getElementById("isFavourite");
        if (favouriteCheckbox.checked == true){
            formJson.favourite = true;
        } else {
            formJson.favourite = false;
        }

        var originalCheckbox = document.getElementById("isOriginal");
        if (originalCheckbox.checked == true){
            formJson.isOriginal = true;
        } else {
            formJson.isOriginal = false;
        }

        var availableCheckbox = document.getElementById("isAvailable");
        if (availableCheckbox.checked == true){
            formJson.isAvailable = true;
        } else {
            formJson.isAvailable = false;
        }

        var freeDeliveryCheckbox = document.getElementById("isFreeDeliveryEligible");
        if (freeDeliveryCheckbox.checked == true){
            formJson.isFreeDeliveryEligible = true;
        } else {
            formJson.isFreeDeliveryEligible = false;
        }

        if (!selectedBrand) {
            setErrorMessage("Brand is not selected");
            setOpenErrorModal(true);
            return;
        } else {
            formJson.brandId = selectedBrand.value;
        }

        if (!selectedCategory) {
            setErrorMessage("Category is not selected");
            setOpenErrorModal(true);
            return;
        } else {
            formJson.categoryId = selectedCategory.value;
        }
        var bulletPoints = [];
        var bulletPointsElements = document.getElementsByName("bulletpoints")
        bulletPointsElements.forEach(function (element, index) {
            if (element.value && element.value.trim()) {
                bulletPoints.push(element.value);
            }
        });
        formJson.bulletPoints = bulletPoints;

        if(barcode && barcode.trim()) {
            formJson.barcode = barcode;
        }

        var imageUrls = [];
        var imageUrlsElements = document.getElementsByName("imageUrls")
        imageUrlsElements.forEach(function (element, index) {
            if (element.value && element.value.trim()) {
                imageUrls.push(element.value);
            }
        });
        formJson.imageUrls = imageUrls;

        var videoUrls = [];
        var videoUrlsElements = document.getElementsByName("videoUrls")
        videoUrlsElements.forEach(function (element, index) {
            if (element.value && element.value.trim()) {
                videoUrls.push(element.value);
            }
        });
        formJson.videoUrls = videoUrls;

        console.log("formJson : " + JSON.stringify(formJson));

        try {
            var response = await sendPostRequestAll(Constants.backendUrl + "/admin/product/update", formJson, true);

            if (response.status == HttpStatusCode.Ok) {
                if (response.data != null) {
//                    setSuccessMessage("Update successful");
//                    setOpenSuccessModal(true);
                    alert("Update successful")
                    window.location.reload();
                }
            } else {
                setErrorMessage(response.data);
                setOpenErrorModal(true);
            }
            console.log("Here the response : ");
            console.log(response);
        } catch (err) {
            console.log("Error in updating the product data : ");
            console.error(err);
            setOpenErrorModal(true);
            setErrorMessage(err.response.data);
        }
    }


    async function handleSearch(e) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        var formJson = Object.fromEntries(formData.entries());
        if(!formJson.searchId || !formJson.searchId.trim()) {
            setErrorMessage("Search Id is empty");
            setOpenErrorModal(true);
            return;
        }
        console.log("formJson");
        console.log(formJson)
        await fetchProductData(formJson.searchId);
    }

    async function handleSearchByBarcode(e) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        var formJson = Object.fromEntries(formData.entries());
        if(!formJson.barcodeId || !formJson.barcodeId.trim()) {
            setErrorMessage("Barcode Id is empty");
            setOpenErrorModal(true);
            return;
        }
        console.log("formJson");
        console.log(formJson)
        await fetchProductDataByBarcodeId(formJson.barcodeId);
    }

    async function fetchProductData(searchId) {
        try {
            var response = await sendGetRequestAll(Constants.backendUrl + "/admin/product/" + searchId, true);

            if (response.status == HttpStatusCode.Ok) {
                if (response.data) {
                    console.log("Hello fetchProductData : " + JSON.stringify(response.data));
                    updateFormData(response.data);
                }
            } else {
                setErrorMessage(response.data);
                setOpenErrorModal(true);
            }
        } catch (err) {
            console.log("Error in getting the product data : ");
            console.error(err);
            setOpenErrorModal(true);
            setErrorMessage(err.response.data);
        }
    }

    async function fetchProductDataByBarcodeId(barcodeId) {
        try {
            console.log("11111111 : ----- " + barcodeId);
            var response = await sendGetRequestAll(Constants.backendUrl + "/admin/product/barcode/" + barcodeId, true);

            if (response.status == HttpStatusCode.Ok) {
                if (response.data) {
                    console.log("Hello fetchProductData : " + JSON.stringify(response.data));
                    updateFormData(response.data);
                }
            } else {
                setErrorMessage(response.data);
                setOpenErrorModal(true);
            }
        } catch (err) {
            console.log("Error in getting the product data : ");
            console.error(err);
            setOpenErrorModal(true);
            setErrorMessage(err.response.data);
        }
    }

    function updateFormData(data) {
        setId(data.id);
        setTitle(data.title);
        setSubtitle(data.subtitle);
        setDescription(data.description);
        setStarRating(data.starRating);
        setQuantity(data.quantity);
        setMrp(data.mrp);
        setSellingPrice(data.sellingPrice);
        setCostPrice(data.costPrice);
        setLowestSellingPrice(data.lowestSellingPrice);
        setWholesalePrice(data.wholesalePrice);
        setMaxQuantityPerOrder(data.maxQuantityPerOrder);
        setIsAvailableChecked(data.isAvailable);
        setIsFavouriteChecked(data.favourite);
        setIsFreeDeliveryEligibleChecked(data.isFreeDeliveryEligible);
        setIsOriginalChecked(data.isOriginal);
        setMediaLink(data.mediaLink);
        populateSelectedBrand(data.brandId);
        populateBulletPoints(data.bulletPoints);
        populateImageUrls(data.imageUrls);
        populateVideoUrls(data.videoUrls);
        populateSelectedCategory(data.categoryId);
        setBarcode(data.barcode);
        setShowForm(true);
        setData(data);
    }

    function populateBulletPoints(bulletPoints) {
        var bulletPointInputs = [];
        if (bulletPoints != null && bulletPoints.length > 0) {

            bulletPoints.forEach((x, i) => {
                console.log(" X : " + x);
                bulletPointInputs.push(<input type="text" key={i} className="border-0 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" name="bulletpoints" defaultValue={x}/>)
            })
        } else {
            bulletPointInputs.push(<input type="text" className="border-0 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" name="bulletpoints"/>)
        }
        setBulletPointComponent(bulletPointInputs);
        console.log("bulletPointInputs : " + JSON.stringify(bulletPointInputs));
    }

    function populateImageUrls(imageUrls) {
        var imageUrlsInputs = [];
        if (imageUrls != null && imageUrls.length > 0) {

            imageUrls.forEach((x, i) => {
                console.log(" X : " + x);
                imageUrlsInputs.push(<input type="text" key={i} className="border-0 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" name="imageUrls" defaultValue={x}/>)
            })
        } else {
            imageUrlsInputs.push(<input type="text" className="border-0 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" name="imageUrls"/>)
        }
        setImageUrlsComponent(imageUrlsInputs);
        console.log("imageUrlsInputs : " + JSON.stringify(imageUrlsInputs));
    }

    function populateVideoUrls(videoUrls) {
        var videoUrlsInputs = [];
        if (videoUrls != null && videoUrls.length > 0) {

            videoUrls.forEach((x, i) => {
                console.log(" X : " + x);
                videoUrlsInputs.push(<input type="text" key={i} className="border-0 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" name="videoUrls" defaultValue={x}/>)
            })
        } else {
            videoUrlsInputs.push(<input type="text" className="border-0 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" name="videoUrls"/>)
        }
        setVideoUrlsComponent(videoUrlsInputs);
        console.log("videoUrlsInputs : " + JSON.stringify(videoUrlsInputs));
    }

    function populateSelectedBrand(brandId) {
        brandsData.forEach((x, i) =>  {
            if (x.value == brandId) {
                setSelectedBrand(x);
            }
        });
    }

    function populateSelectedCategory(categoryId) {
        categoryData.forEach((x, i) =>  {
            if (x.value == categoryId) {
                setSelectedCategory(x);
            }
        });
    }

    const addBulletPoint = () => {
        document.getElementById("bullet_point_div")
        .insertAdjacentHTML("beforeend",
            `<input
               type="text"
               class="border-0 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               name="bulletpoints"
            />`);
    }

    const addImageUrls = () => {
        document.getElementById("image_urls_div")
        .insertAdjacentHTML("beforeend",
            `<input
               type="text"
               className="border-0 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               name="imageUrls"
            />`);
    }

    const addVideoUrls = () => {
        document.getElementById("video_urls_div")
        .insertAdjacentHTML("beforeend",
            `<input
               type="text"
               class="border-0 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               name="videoUrls"
            />`);
    }

    const fetchBrandData = async () => {
        try {
            var response = await sendGetRequestAll(Constants.backendUrl + "/admin/brand", true);
            if (response.data) {
                setBrandsData(getBrandOptions(response.data))
            }
        } catch (err) {
            console.error(err);
            console.error("Error Occurred while fetchBrandData");
            setBrandsData([]);
            if (err instanceof TypeError && fetchBrandDataRetryCount < maxRetryCount) {
                console.log("Retrying");
                fetchBrandDataRetryCount = fetchBrandDataRetryCount + 1;
                setTimeout(fetchBrandData, 1000);
            }
        }
    };

    const fetchCategoryData = async () => {
        try {
            var response = await sendGetRequestAll(Constants.backendUrl + "/admin/category?level=2", true);
            if (response.data != null) {
                setCategoryData(getCategoriesOption(response.data))
            }
        } catch (err) {
            console.error(err);
            console.error("Error Occurred while fetchCategoryData");
            setCategoryData([]);
            if (err instanceof TypeError && fetchCategoryDataRetryCount < maxRetryCount) {
                console.log("Retrying");
                fetchCategoryDataRetryCount = fetchCategoryDataRetryCount + 1;
                setTimeout(fetchCategoryData, 1000);
            }
        }
    };

    function getBrandOptions(data) {
        var brandsOption = [];
        if (data != null) {
            data.forEach(function (brand, index) {
              brandsOption.push({value : brand.id, label : brand.title})
            });
        }
        return brandsOption;
    }

    function getCategoriesOption(data) {
        var categoriesOption = [];
        if (data != null) {
            data.forEach(function (category, index) {
              categoriesOption.push({value : category.id, label : category.title})
            });
        }
        return categoriesOption;
    }

     useEffect(async () => {
            if (loading) return;
            if (!user) return navigate.push("/");
            await fetchBrandData();
            await fetchCategoryData();
     }, [user, loading]);

    useEffect(async() => {
        const queryParams = new URLSearchParams(window.location.search);
        const value = queryParams.get('id');
        if (value && brandsData && categoryData && brandsData.length > 0 && categoryData.length > 0) {
            console.log("brandsData : " + brandsData);
            console.log("categoryData : " + categoryData);
            console.log("value : " + value);
            await fetchProductData(value);
        }
    }, [brandsData, categoryData])

    return (
        <>
            <div className="flex-auto px-4 pt-0">
            <Modal open={openErrorModal} onClose={onCloseErrorModal} center>
                            <h2 className="text-xl mb-4 font-bold border-b pb-2">Oops, An Error Occurred ! &nbsp; &nbsp; &nbsp; &nbsp;</h2>
                            <p>{errorMessage}</p>
                        </Modal>
            <Modal open={openSuccessModal} onClose={onCloseSuccessModal} center>
                <h2 className="text-xl mb-4 font-bold border-b pb-2">Operation successful &nbsp; &nbsp; &nbsp; &nbsp;</h2>
                <p>{successMessage}</p>
            </Modal>

            <Modal open={openDeleteProductModal} onClose={onCloseDeleteProductModal} center>
                <h2 className="text-xl mb-4 font-bold border-b pb-2">Confirm Delete this product?</h2>
                <p>Do you want to delete this product?</p>
                <p className="text-sm mb-2">(This is irreversible process)</p>
                <div className="flex mt-3">
                    <button className="text-white bg-lightBlue-600 font-bold uppercase text-sm p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        onClick={onCloseDeleteProductModal}>No</button>
                    <button className="ml-2 text-white bg-lightBlue-600 font-bold uppercase text-sm p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        onClick={deleteProduct}>Yes</button>
                 </div>
            </Modal>
            <h6 className="text-blueGray-700 text-md mt-3 mb-6 font-bold uppercase">
             Update Product &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;
             {id!= null && (
               <a href={"/product/" + id}><button
                  className="bg-indigo-500 mt-2 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                >
                  View Product
                </button></a>
                )}
            </h6>


            {showForm && (
              <form onSubmit={handleSubmit}>
                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Product Details
                </h6>
                <div className="flex flex-wrap">
                   <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Product Id (Unique *)
                      </label>
                      <input
                        type="text"
                        readOnly = {true}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        name="id" value={id}
                      />
                    </div>
                  </div>

                  <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Product Title (*)
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        onChange={e => setTitle(e.target.value)}
                        name="title" value={title}
                      />
                    </div>
                  </div>
                  <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Subtitle (*)
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        onChange={e => setSubtitle(e.target.value)}
                        name="subtitle" value={subtitle}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Description (*)
                    </label>
                    <textarea
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      rows="4"
                      onChange={e => setDescription(e.target.value)}
                      name="description" value={description}
                    ></textarea>
                  </div>
                </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Star Rating (Out of 5 in Decimal *)
                      </label>
                      <input
                        type="number"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        max="5.0"
                        onChange={e => setStarRating(e.target.value)}
                        name="starRating" step="0.1" value={starRating}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Quantity (Zero or more *)
                      </label>
                      <input
                        type="number"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        min="0" max="1000"
                        onChange={e => setQuantity(e.target.value)}
                        name="quantity" value={quantity}
                      />
                    </div>
                  </div>
                </div>

                <hr className="mt-6 border-b-1 border-blueGray-300" />
                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Pricing (Mandatory)
                </h6>
                <div className="flex flex-wrap">
                    <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            MRP (Number *)
                          </label>
                          <input
                            type="number"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            min="1"
                            onChange={e => setMrp(e.target.value)}
                            name="mrp" value={mrp}
                          />
                        </div>
                    </div>
                    <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Cost Price (Number *)
                          </label>
                          <input
                            type="number"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            min="1"
                            onChange={e => setCostPrice(e.target.value)}
                            name="costPrice" value={costPrice}
                          />
                        </div>
                    </div>
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Selling Price (Number *)
                        </label>
                        <input
                          type="number"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          min="1"
                          onChange={e => setSellingPrice(e.target.value)}
                          name="sellingPrice" value={sellingPrice}
                        />
                      </div>
                    </div>

                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Lowest Selling Price (Number *)
                        </label>
                        <input
                          type="number"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          min="1"
                          onChange={e => setLowestSellingPrice(e.target.value)}
                          name="lowestSellingPrice" value={lowestSellingPrice}
                        />
                      </div>
                    </div>

                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Wholesale Price (Number *)
                        </label>
                        <input
                          type="number"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          min="1"
                          onChange={e => setWholesalePrice(e.target.value)}
                          name="wholesalePrice" value={wholesalePrice}
                        />
                      </div>
                    </div>
                </div>

                <hr className="mt-6 border-b-1 border-blueGray-300" />
                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Bullet Points
                </h6>
                <div className="relative w-full mb-3 mt-4 px-4">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Bullet Points
                  </label>
                  <div id="bullet_point_div">
                       {bulletPointComponent!=null ? bulletPointComponent : <></>}
                   </div>
                   <button className="btn-outline mt-3" type="button" onClick={addBulletPoint}>Add More Point</button>
                </div>

                <hr className="mt-6 border-b-1 border-blueGray-300" />

                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Images
                </h6>
                <div className="relative w-full mb-3 mt-4 px-4">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Media Link (First Image *)
                  </label>
                  <div>
                      <input
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          name="mediaLink"
                          value={mediaLink}
                          onChange={e => setMediaLink(e.target.value)}
                       />
                   </div>
                </div>

                <div className="relative w-full mb-3 mt-4 px-4">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    More Images Urls
                  </label>
                  <div id="image_urls_div">
                      {imageUrlsComponent!=null ? imageUrlsComponent : <></>}
                   </div>
                   <button className="btn-outline mt-3" type="button" onClick={addImageUrls}>Add More Images</button>
                </div>

                <hr className="mt-6 border-b-1 border-blueGray-300" />

                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Videos
                </h6>

                <div className="relative w-full mb-3 mt-4 px-4">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Video Urls
                  </label>
                  <div id="video_urls_div">
                     {videoUrlsComponent!=null ? videoUrlsComponent : <></>}
                   </div>
                   <button className="btn-outline mt-3" type="button" onClick={addVideoUrls}>Add More Videos</button>
                </div>

                <hr className="mt-6 border-b-1 border-blueGray-300" />



                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Brand, Category and Merchant details
                </h6>

                <div className="relative w-full mb-3 mt-4 px-4">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Brand
                  </label>
                  <Dropdown options={brandsData} onChange={setSelectedBrand} value={selectedBrand} placeholder="Select an option" />
                </div>

                <div className="relative w-full mb-3 mt-4 px-4">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Category
                  </label>
                  <Dropdown options={categoryData} onChange={setSelectedCategory} value={selectedCategory} placeholder="Select an option" />
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />

                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Other Attributes (Optional)
                </h6>
                 <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Mark as Favourite &nbsp;&nbsp; <input type="checkbox" id="isFavourite" name="isFavourite" checked={isFavouriteChecked} onChange={e => setIsFavouriteChecked(e.target.checked)} />
                      </label>

                    </div>
                    <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Is Original &nbsp;&nbsp; <input type="checkbox" id="isOriginal" name="isOriginal" checked={isOriginalChecked} onChange={e => setIsOriginalChecked(e.target.checked)} />
                        </label>

                      </div>
                      <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Is Available &nbsp;&nbsp; <input type="checkbox" id="isAvailable" name="isAvailable" checked={isAvailableChecked} onChange={e => setIsAvailableChecked(e.target.checked)}/>
                          </label>

                        </div>
                      <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Is Free Delivery Eligible &nbsp;&nbsp; <input type="checkbox" id="isFreeDeliveryEligible" checked={isFreeDeliveryEligibleChecked} name="isFreeDeliveryEligible" onChange={e => setIsFreeDeliveryEligibleChecked(e.target.checked)}/>
                          </label>

                        </div>

                        <div className="relative w-full mb-3 mt-4">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              Max Quantity Per Order
                            </label>
                            <input
                              type="number"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              min="0" max="1000"
                              name="maxQuantityPerOrder" value={maxQuantityPerOrder}
                              onChange={e => setMaxQuantityPerOrder(e.target.value)}
                            />
                        </div>

                        <div className="relative w-full mb-3 mt-4">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              Barcode
                            </label>
                            {openCamera && (
                            <BarcodeScannerComponent
                                width={400}
                                height={400}
                                onUpdate={(err, result) => {
                                  if (result) setBarcode(result.text);
                                  else setBarcode(null);
                                }}
                              />
                            )}
                             {!openCamera && (
                                <button
                                    className="bg-indigo-500 mt-2 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={onOpenCamera}
                                  >
                                    Open Camera
                                  </button>
                            )}
                            {openCamera && (
                                <button
                                  className="bg-indigo-500 mt-2 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                  type="button"
                                  onClick={onCloseCamera}
                                >
                                  Close Camera
                                </button>
                            )}
                            <input
                                type="text"
                                className="border-0 mt-3 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                value={barcode} onChange={e => setBarcode(e.target.value)}
                              />

                        </div>
                  </div>





<div className="w-full mt-4 mb-3">
                        <button
                            className="bg-primary text-white font-bold uppercase text-sm px-4 p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="submit"
                        >
                            Update Product
                        </button>
                        <button
                            className="bg-red-500 text-white font-bold uppercase text-sm px-4 p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={onOpenDeleteProductModal}
                        >
                            Delete Product
                        </button>
                    </div>

              </form>
              )}

                 <hr className="mt-6 border-b-1 border-blueGray-300" />
                  <div className="card p-4 mt-4 bg-white">
                  <h2 className="text-xl mb-4 font-bold pb-2">Search Product by ID</h2>
                  <form onSubmit={handleSearch} className="mt-3">
                      <input
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          name="searchId" value={searchId} onChange={e => setSearchId(e.target.value)}
                        />
                      <button
                          className="bg-primary mt-4 text-white font-bold uppercase text-sm px-4 p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                          type="submit"
                      >
                          <i className="fa fa-search" aria-hidden="true"></i> &nbsp; &nbsp; Search Product
                      </button>
                  </form>
                  </div>

                  <hr className="mt-6 border-b-1 border-blueGray-300" />
                    <div className="card p-4 mt-4 bg-white">
                    <h2 className="text-xl mb-4 font-bold pb-2">Search Product by Barcode</h2>
                    <form onSubmit={handleSearchByBarcode} className="mt-3">
                        {openCameraForSearch && (
                            <BarcodeScannerComponent
                                width={400}
                                height={400}
                                onUpdate={(err, result) => {
                                  if (result) setBarcodeId(result.text);
                                  else setBarcodeId(null);
                                }}
                              />
                        )}
                         {!openCameraForSearch && (
                            <button
                                className="bg-indigo-500 mt-2 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={onOpenCameraForSearch}
                              >
                                Open Camera
                              </button>
                        )}
                        {openCameraForSearch && (
                            <button
                              className="bg-indigo-500 mt-2 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              type="button"
                              onClick={onCloseCameraForSearch}
                            >
                              Close Camera
                            </button>
                        )}
                        <input
                            type="text"
                            className="border-0 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            name="barcodeId" value={barcodeId} onChange={e => setBarcodeId(e.target.value)}
                          />
                        <button
                            className="bg-primary mt-4 text-white font-bold uppercase text-sm px-4 p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="submit"
                        >
                            <i className="fa fa-search" aria-hidden="true"></i> &nbsp; &nbsp; Search Product
                        </button>
                    </form>
                    </div>


            </div>
        </>
    )
}