import React, {useEffect, useState, useMemo} from "react";
import {useHistory} from "react-router-dom";
import {sendGetRequestAll} from "../../helpers/httprequesthelper";
import IndexNavbar from "../../components/Navbars/IndexNavbar";
import Footer from "../../components/Footers/Footer";
import QRCode from "react-qr-code";
import Carousel from "react-multi-carousel";
import * as Constants from "../../constants/constants";
import Rating from "react-rating";
import StarRating from 'react-star-rating';
import * as FormatHelper from "../../helpers/formathelper";
import PlacardComponent from "../../components/PlacardComponent";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import {daysLeft} from "../../helpers/formathelper";
import { Link } from "react-router-dom";
import { MaterialReactTable } from 'material-react-table';
import StaticProducts from "../../components/ProductComponents/StaticProducts";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "helpers/firebase.js";
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { Bars } from 'react-loader-spinner'
import {getDateFromTimestamp, getAddressLabelString, getDateAndTimeFromTimestamp} from "../../helpers/formathelper";
import Loader from "components/Loader/Loader.js"

export default function PageView(props) {
    const [data, setData] = useState(null);
    const navigate = useHistory();
    const [user, loading, error] = useAuthState(auth);

    const fetchData = async () => {
        try {
            var response = null;
            if (props.store) {
                response = await sendGetRequestAll(Constants.backendUrl + "/page/store", false);
            } else {
                response = await sendGetRequestAll(Constants.backendUrl + "/page/"+props.match.params.pid, false);
            }
            setData(response.data);
        } catch (err) {
            console.error(err);
            if (err && err.response && err.response.data) {
                alert("An error occured while loading page data : " + err.response.data);
            } else {
                alert("An error occured while loading page data");
            }
            return navigate.push("/");
        }
    };

    useEffect(() => {
        if (loading) return;
        fetchData();
    }, [user, loading]);

    return (
        <>
            <IndexNavbar />
            {!data && (
                                   <Loader data={data}/>
                                    )}
{data && (
<>
            <section className="relative block h-400-px">
            {data && data.bgMediaLink && (
                <div
                    className="absolute top-0 w-full h-full bg-center bg-cover"
                    style={{
                        backgroundImage:
                            "url(" + data.bgMediaLink + ")",
                    }}
                ><span
                                 id="blackOverlay"
                                 className="w-full h-full absolute opacity-50 bg-black"
                             ></span>
                                 </div>
            )}


                <div
                    className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                    style={{ transform: "translateZ(0)" }}
                >
                    <svg
                        className="absolute bottom-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="text-blueGray-200 fill-current"
                            points="2560 0 2560 100 0 100"
                        ></polygon>
                    </svg>
                </div>
            </section>


            <section className="relative py-16 bg-blueGray-200">
                <div className="container mx-auto px-2">
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-80">
                        <div className="p-2">

                            <div className="mb-4 flex flex-wrap justify-between w-full">
                            {data.mediaLink != null && (
                                <div className="lg:w-4/12 w-full p-2 flex flex-wrap justify-center">
                                    <img
                                        alt="..."
                                        src={data.mediaLink}
                                        className="max-h-200-px"
                                    />
                                </div>
                            )}
                                <div className="lg:w-8/12 p-3">
                                    {data.title != null && (
                                        <h3 className="text-4xl uppercase font-semibold leading-normal mb-2 text-blueGray-700">
                                            {data.title}
                                        </h3>
                                    )}
                                    {data.description != null && (
                                        <div className="mt-3">
                                            <p className="text-md">{data.description}</p>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {data.childCategories!= null && data.childCategories.length>0 && (
                                <div className="mb-4">
                                    <StaticProducts
                                        data={data.childCategories}
                                        title="Explore More"
                                        description="Subcategories that might interest you"
                                        />
                                </div>
                            )}
                            <div>
                                <PlacardComponent data={data.placardData}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            </>
)}
        </>
    );
}