import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import * as Constants from "constants/constants.js";
import {sendGetRequestAll} from "../helpers/httprequesthelper";
import {HttpStatusCode} from "axios";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";

import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCzgg3S87E6yYalaDsSY8nza909FT-_I0w",
  authDomain: "adarshnagarhyperlocal.firebaseapp.com",
  projectId: "adarshnagarhyperlocal",
  storageBucket: "adarshnagarhyperlocal.appspot.com",
  messagingSenderId: "181979308901",
  appId: "1:181979308901:web:b6c0c12a529b98cdb3378c",
  measurementId: "G-KJFLW2SLHX"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const db = getFirestore(app);

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    console.log(user);
    await updateUserData();
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

async function updateUserData() {
    try {
        var response = await sendGetRequestAll(Constants.backendUrl + "/user/googlesignin", true);
        if (response.status != HttpStatusCode.Ok) {
            signOut(auth);
            return;
        } else {
            if(response.data == true) {
                alert("Please Update your mobile number to continue");
                window.location.href = "/manage/account";
            }
        }
    } catch (err) {
        signOut(auth);
        console.log("Error in updateUserData : " + err.response.data.toString());
        alert("Unable to sign in");
    }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (name, phoneNumber, age, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      phoneNumber,
      age,
      authProvider: "manual",
      joiningDate: new Date(),
      email,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  app,
  firebaseConfig
};