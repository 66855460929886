
import React from "react";
import StandardPricingAndCartSection from "./StandardPricingAndCartSection";
import {Link} from "react-router-dom";

export default function DiaProduct(props) {
    return (
        <>
            <div className="p-2" key={props.dataelement.id}>
                <Link to={props.dataelement.urlLink}>
                    <div className="break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-primary">
                        <div className="p-2 bg-white">
                        <img
                            alt="..."
                            src={props.dataelement.mediaLink}
                            className="w-full align-middle rounded-t-lg h-200-px"
                        />
                        </div>
                        <blockquote className="relative pb-2 px-2">
                            <svg
                                preserveAspectRatio="none"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 583 95"
                                className="absolute left-0 w-full block h-95-px -top-94-px"
                            >
                                <polygon
                                    points="-30,95 583,95 583,75"
                                    className="text-lightBlue-500 fill-current"
                                ></polygon>
                            </svg>
                            { props.dataelement.title && (
                                <h4 className="product-carousel-title text-white">
                                    {props.dataelement.title}
                                </h4>
                            )}
                            <StandardPricingAndCartSection id={props.dataelement.id} mrp={props.dataelement.mrp} sellingPrice={props.dataelement.sellingPrice} discountPercentage={props.dataelement.discountPercentage} invertColors={true}/>
                        </blockquote>
                    </div>
                </Link>
            </div>
        </>
    );
}