export const name = 'My Adarsh Nagar'
export const pincode = '110033'
export const areas = ["Adarsh Nagar", "Kewal Park", "Majlis Park", "Indra Nagar", "Panchwati"]

export const roles = ["SUPERADMIN", "ADMIN", "MERCHANT", "USER", "DRIVER"]        //Mapped to backend
export const userMembershipType = ["FAMILY", "NORMAL", "WHOLESALE", "HYPER"]        //Mapped to backend
export const userAreas = ["Adarsh Nagar", "Kewal Park"]        //Mapped to backend
export const userGender = ["MALE", "FEMALE", "OTHER"]
export const beginMonthAndYear = 'August 2023'
export const freeTillMonthAndYear = 'November 2023'
export const merchantSupportNumber = '8700223595'
export const placeOrderSupportNumber = '8700223595'
export const city = 'New Delhi'
export const currency = "₹"
export const logoUrlForInvoice = "https://media.istockphoto.com/id/1331491686/vector/element-design.jpg?s=612x612&w=0&k=20&c=QIMRS2IPiQyyTZJR_G1JAjH_ErBBkrDPtQe2GBNgm2w="
export const nameForInvoice = "Adarsh Nagar Store";
export const addressLine1ForInvoice = "D-41, Gali No. 6, Near Subhash Park";
export const addressLine2ForInvoice = "Adarsh Nagar";
export const addressLine3ForInvoice = "Delhi-110033";
export const contactNumberForInvoice = "9718199993";
export const imgurClientId = "1bd76979ce026b8e1b2f814ce658b51cde172e33";

export const backendUrl = "https://api.myadarshnagar.in";
export const notLoggedInImageUrl = 'https://img.freepik.com/premium-vector/no-user-found-concept-isometric-vector-illustrations-banner-website-landing-page-flyer_106788-3902.jpg?w=2000';
export const baseUrl = "http://www.myadarshnagar.in";

export const aboutUsSubtitle = "is created under the vision of bringing people together of an area. We offer a platform which can help shop at lowest prices, find services, and know what's going on in your area."
export const aboutUsBackgroundImage = "https://i.imgur.com/zHrddRl.jpg";
export const aboutUsSavingImage = "https://i.imgur.com/SA0Bi4p.jpg";
export const aboutUsWorkImage = "https://i.imgur.com/C48eqDG.jpg";
export const rangMartImage = "https://i.imgur.com/F5QvV59.png";
export const rangMartGoogleMapsLocation = "https://maps.app.goo.gl/neFKR6BC8h5gVAnDA";
export const rangMartWhatsappUrl = "https://wa.me/+919718199993";
export const rangMartInstagramUrl = "https://www.instagram.com/rang_mart/";
export const rangMartTelUrl = "tel:+919718199993";
export const adarshNagarStoreImage = "https://i.imgur.com/nemu6NL.png";
export const adarshNagarStoreTelUrl = "tel:+919990911698";
export const adarshNagarStoreWhatsappUrl = "https://wa.me/+919990911698";

export const contactUsBackgroundImage = "https://i.imgur.com/RrnHh2P.jpg";
export const contactUsSubtitle = "We are sincere toward people and our customers. Your money is 100% safe and platform is genuine as we accept orders both through shop and online."
export const contactUsAdvertiseImage = "https://i.imgur.com/wL110BY.png";
export const advertiseWhatsappUrl = "https://wa.me/+919990911698";


export const categoryViewBackgroundImage = "https://images.unsplash.com/photo-1499336315816-097655dcfbda?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2710&q=80";
export const brandViewBackgroundImage = "https://images.unsplash.com/photo-1499336315816-097655dcfbda?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2710&q=80";
export const comingSoonViewBackgroundImage = "https://images.unsplash.com/photo-1499336315816-097655dcfbda?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2710&q=80";
export const productViewBackgroundImage = "https://t4.ftcdn.net/jpg/04/25/48/77/360_F_425487737_aXU9KVkZzThZJyWr86xsnfhOSQNjszdI.jpg"
export const userAddressViewBackgroundImage = "https://images.unsplash.com/photo-1499336315816-097655dcfbda?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2710&q=80";
export const userCartViewBackgroundImage = "https://img.freepik.com/free-vector/hand-drawn-shopping-pattern-design_23-2149628714.jpg";
export const userCheckoutViewBackgroundImage = "https://cdn.dribbble.com/users/2096933/screenshots/8974138/media/1027826d8e44d15c42b1e767c12ec4a2.gif";
export const userOrderDetailViewBackgroundImage = "https://media.tenor.com/FbP9hBx38tMAAAAC/happy-janmashtami-lord-krishna.gif";
export const userOrderPlacedViewBackgroundImage = "https://media.tenor.com/FbP9hBx38tMAAAAC/happy-janmashtami-lord-krishna.gif";
export const userOrdersViewBackgroundImage = "https://media.tenor.com/FbP9hBx38tMAAAAC/happy-janmashtami-lord-krishna.gif";
export const userWishlistViewBackgroundImage = "https://media.tenor.com/FbP9hBx38tMAAAAC/happy-janmashtami-lord-krishna.gif";
export const profileBackgroundImage = "https://media1.giphy.com/media/BHNfhgU63qrks/giphy.gif";
