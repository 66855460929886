import {React,useState,useEffect,useMemo} from "react";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {sendGetRequestAll, sendPostRequestAll} from "../../helpers/httprequesthelper";
import * as Constants from "constants/constants.js";
import {Modal} from "react-responsive-modal";
import {HttpStatusCode} from "axios";
import Multiselect from 'multiselect-react-dropdown';
import {useHistory} from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "helpers/firebase.js";
import { MaterialReactTable } from 'material-react-table';

export default function AllUser() {

    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [data, setData] = useState(null);
    const [user, loading, error] = useAuthState(auth);
    const navigate = useHistory();
    const [userData, setUserData] = useState([]);

    const createUserDataObject = (data) => {
        if (data == null) {
            return;
        }
        var userData = [];
        data.forEach(function (item, index) {
            userData.push({
                  "id" : item.id,
                  "name" : item.name,
                  "phone" : item.phoneNumber,
                  "area" : item.area,
                  "age" : item.age,
                  "gender" : item.userGender,
                  "role" : item.roles,
                  "email" : item.email,
                  "pincode" : item.pinCode,
                  "emailverified" : item.emailVerified == null ? "Null" : item.emailVerified.toString(),
                  "userMembershipType" : item.userMembershipType,
                  "rejectedOrders" : item.rejectedOrders,
                  "editUrl" : "/admin/users/update?id=" + item.id })
        });
        console.log(userData);
        setUserData(userData);
    }


    const columns = useMemo(
        () => [

            {
                header: "Id",
                accessorKey: 'id', //access nested data with dot notation
                size: 6
            },
            {
                header: "Name",
                accessorKey: 'name', //access nested data with dot notation
                size: 6
            },
            {
                header: "Phone",
                accessorKey: 'phone', //access nested data with dot notation
                size: 6
            },
            {
                header: "Area",
                accessorKey: 'area', //access nested data with dot notation
                size: 6
            },
            {
                header: "Pincode",
                accessorKey: 'pincode', //access nested data with dot notation
                size: 6
            },
            {
                header: "Age",
                accessorKey: 'age', //access nested data with dot notation
                size: 6
            },
            {
                header: "Email",
                accessorKey: 'email', //access nested data with dot notation
                size: 6
            },
            {
                header: "Email Verified",
                accessorKey: 'emailverified', //access nested data with dot notation
                size: 6
            },
            {
                header: "Gender",
                accessorKey: 'gender', //access nested data with dot notation
                size: 10
            },
            {
                header: "Membership Type",
                accessorKey: 'userMembershipType',
                size: 10
            },
            {
                header: "Rejected Order",
                accessorKey: 'rejectedOrders',
                size: 10
            },
            {
                header: "Edit",
                accessorKey: 'editUrl', //access nested
                Cell: ({ cell }) => (
                    <>
                        <a href={cell.getValue()} className="font-bold">
                            <i className="fa fa-edit" aria-hidden="true"></i>
                        </a>
                    </>
                ),
                size: 10
            }
        ],
        [],
    );
    function onCloseErrorModal() {
        setOpenErrorModal(false);
        setErrorMessage(null);
    }

    function onCloseSuccessModal() {
        setOpenSuccessModal(false);
        setSuccessMessage(null);
    }

    async function fetchAllUserData() {
        try {
            var response = await sendGetRequestAll(Constants.backendUrl + "/admin/user", true);
            if (response.data != null) {
                setData(response.data);
                createUserDataObject(response.data);
            }
        } catch (err) {
            console.error(err);
            console.error(err);
            if (err && err.response && err.response.data) {
                alert("An error occured while loading user data : " + err.response.data);
            } else {
                alert("An error occured while loading user data");
            }
        }
    };

     useEffect(() => {
        if (loading) return;
        if (!user) return navigate.push("/auth/login");
        fetchAllUserData();
     }, [user, loading]);

    return (
        <>
            <div className="flex-auto px-4 pt-0">
            <Modal open={openErrorModal} onClose={onCloseErrorModal} center>
                <h2 className="text-xl mb-4 font-bold border-b pb-2">Oops, An Error Occurred ! &nbsp; &nbsp; &nbsp; &nbsp;</h2>
                <p>{errorMessage}</p>
            </Modal>
            <Modal open={openSuccessModal} onClose={onCloseSuccessModal} center>
                <h2 className="text-xl mb-4 font-bold border-b pb-2">Operation successful &nbsp; &nbsp; &nbsp; &nbsp;</h2>
                <p>{successMessage}</p>
            </Modal>
                <h6 className="text-blueGray-700 text-md mt-3 mb-2 font-bold uppercase">
                 All User Data
                </h6>
                {data!=null && data.length > 0 && (
                    <p className="mb-4">Total {data.length} Users</p>
                )}


                <div className="flex flex-wrap">
                   <div className="w-full px-4">
                        { data!= null && data.length > 0 && (
                            <div className="mt-2">
                                <MaterialReactTable columns={columns} data={userData}
                                    enableColumnActions={true}
                                    enableColumnFilters={true}
                                    enablePagination={false}
                                    enableSorting={true}
                                    enableBottomToolbar={false}
                                    enableTopToolbar={false}
                                    muiTableBodyRowProps={{ hover: false }}/>
                            </div>
                        )}
                    </div>
                  </div>







            </div>
        </>
    )
}