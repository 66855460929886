import { Link } from "react-router-dom";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import React from "react";
import PlacardHeader from "../Headers/PlacardHeader";
import {registerWithEmailAndPassword} from "../../helpers/firebase";
import StandardPricingAndCartSection from "../ProductComponents/StandardPricingAndCartSection";
import DiaProduct from "../ProductComponents/DiaProduct";


export default function ProductCarouselDia(props) {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 2 // optional, default to 1.
        },
        laptop: {
            breakpoint: { max: 1023, min: 860 },
            items: 3,
            slidesToSlide: 2 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 860, min: 464 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <div className="card p-2 pb-4 mx-1" style={{
            backgroundImage:
                "url('https://images.template.net/103023/free-transparent-raksha-bandhan-background-klhuk.jpg')",
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        }}>
            <PlacardHeader title={props.title} subtitle={props.subtitle} description={props.description} viewAllLink={props.viewAllLink}/>
            <Carousel autoPlay="true" interval="2000" swipeable={true}
                      draggable={true} responsive={responsive} infinite={true} showThumbs={false}>
                {props.data!=null && props.data.map(function(dataelement, index){
                    return <div key={index}><DiaProduct dataelement={dataelement}/></div>
                })}

            </Carousel>
        </div>
    );
}