import BannerCarousel from "./Carousel/BannerCarousel";
import TextMarquee from "./Marquee/TextMarquee";
import SmallImageMarquee from "./Marquee/SmallImageMarquee";
import MediumImageMarquee from "./Marquee/MediumImageMarquee";
import LargeImageMarquee from "./Marquee/LargeImageMarquee";
import XLargeImageMarquee from "./Marquee/XLargeImageMarquee";
import VideoMarquee from "./Marquee/VideoMarquee";
import StaticVideoPlayer from "./Video/StaticVideoPlayer";
import ProductCarousel from "./Carousel/ProductCarousel";
import ProductCarouselDia from "./Carousel/ProductCarouselDia";
import StaticImages from "./Images/StaticImages";
import SideCarousel from "./Carousel/SideCarousel";
import VideoPlayer from "./Video/VideoPlayer";
import VideoCarousel from "./Carousel/VideoCarousel";
import BlogCarousel from "./Carousel/BlogCarousel";
import React from "react";
import {
    BannerCarouselType,
    MediumImageMarqueeType,
    SmallImageMarqueeType, StaticVideoPlayerType,
    TextMarqueeType
} from "../constants/placardConstants";
import StaticProducts from "./ProductComponents/StaticProducts";
import StaticProducts4Half from "./ProductComponents/StaticProducts4Half";

export default function PlacardComponent(props) {
    return (
            <>

                {props.data != null && props.data.map(function(dataelement, index){
                    return (
                        <div key={index}>
                    {dataelement != null && dataelement.content != null && dataelement.placardType == "BannerCarousel" && (
                            <section className="flex flex-wrap text-center header mb-3 items-center" key={index}>
                            <div className="mx-auto items-center">
                                <div className="w-full">
                                    <div className="max-h-800-px">
                                        <BannerCarousel
                                            data={dataelement.content}
                                            title={dataelement.title}
                                            subtitle={dataelement.subtitle}
                                            description={dataelement.description}
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}

                    {dataelement != null && dataelement.subtitle != null && dataelement.placardType == "TextMarquee" && (
                        <section className="text-center header items-center mt-4 mb-3" key={index}>
                            <div className="mx-auto items-center">
                                <div className="w-full">
                                    <div className="max-h-800-px">
                                        <TextMarquee subtitle={dataelement.subtitle}/>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}

                    {dataelement != null && dataelement.content != null && dataelement.placardType == "SmallImageMarquee" && (
                        <section className="text-center header items-center mt-8 mb-3" key={index}>
                            <div className="mx-auto items-center">
                                <div className="w-full">
                                    <div className="max-h-800-px">
                                        <SmallImageMarquee
                                            data={dataelement.content}
                                            title={dataelement.title}
                                            subtitle={dataelement.subtitle}
                                            description={dataelement.description}
                                            viewAllLink={dataelement.viewAllLink}/>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}

                    {dataelement != null && dataelement.content != null && dataelement.placardType == "MediumImageMarquee" && (
                        <section className="text-center header items-center mt-8 mb-3" key={index}>
                            <div className="mx-auto items-center">
                                <div className="w-full">
                                    <div className="max-h-800-px">
                                        <MediumImageMarquee
                                            data={dataelement.content}
                                            title={dataelement.title}
                                            subtitle={dataelement.subtitle}
                                            description={dataelement.description}
                                            viewAllLink={dataelement.viewAllLink}/>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}

                    {dataelement != null && dataelement.content != null && dataelement.placardType == "LargeImageMarquee" && (
                        <section className="text-center header items-center mt-8 mb-3" key={index}>
                            <div className="mx-auto items-center">
                                <div className="w-full">
                                    <div className="max-h-800-px">
                                        <LargeImageMarquee
                                            data={dataelement.content}
                                            title={dataelement.title}
                                            subtitle={dataelement.subtitle}
                                            description={dataelement.description}
                                            viewAllLink={dataelement.viewAllLink}/>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}

                    {dataelement != null && dataelement.content != null && dataelement.placardType == "XLargeImageMarquee" && (
                        <section className="text-center header items-center mt-8 mb-3" key={index}>
                            <div className="mx-auto items-center">
                                <div className="w-full">
                                    <div className="max-h-800-px">
                                        <XLargeImageMarquee
                                            data={dataelement.content}
                                            title={dataelement.title}
                                            subtitle={dataelement.subtitle}
                                            description={dataelement.description}
                                            viewAllLink={dataelement.viewAllLink}/>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}

                    {dataelement != null && dataelement.content != null && dataelement.placardType == "StaticProducts" && (
                        <section className="text-center header items-center mt-8 mb-3" key={index}>
                            <div className="mx-auto items-center">
                                <div className="w-full">
                                        <StaticProducts
                                            data={dataelement.content}
                                            title={dataelement.title}
                                            subtitle={dataelement.subtitle}
                                            description={dataelement.description}
                                            viewAllLink={dataelement.viewAllLink}/>
                                </div>
                            </div>
                        </section>
                    )}

                    {dataelement != null && dataelement.content != null && dataelement.placardType == "VideoMarquee" && (
                        <section className="text-center header items-center mt-8 mb-3" key={index}>
                            <div className="mx-auto items-center">
                                <div className="w-full">
                                    <div className="max-h-800-px">
                                        <VideoMarquee
                                            data={dataelement.content}
                                            title={dataelement.title}
                                            subtitle={dataelement.subtitle}
                                            description={dataelement.description}
                                            viewAllLink={dataelement.viewAllLink}/>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}

                    {dataelement != null && dataelement.content != null && dataelement.placardType == "StaticVideoPlayer" && (
                        <section className="text-center header  items-center mt-4 mb-3" key={index}>
                            <div className="mx-auto items-center">
                                <div className="w-full">
                                    <StaticVideoPlayer
                                        data={dataelement.content}
                                        title={dataelement.title}
                                        subtitle={dataelement.subtitle}
                                        description={dataelement.description}
                                        viewAllLink={dataelement.viewAllLink}/>
                                </div>
                            </div>
                        </section>
                    )}


                    {dataelement != null && dataelement.content != null && dataelement.placardType == "ProductCarousel" && (
                        <section className="text-center header items-center mt-4 mb-3" key={index}>
                            <div className="mx-auto items-center">
                                <div className="w-full">
                                    <ProductCarousel
                                        data={dataelement.content}
                                        title={dataelement.title}
                                        subtitle={dataelement.subtitle}
                                        description={dataelement.description}
                                        viewAllLink={dataelement.viewAllLink}/>
                                </div>
                            </div>
                        </section>
                    )}

                    {dataelement != null && dataelement.content != null && dataelement.placardType == "ProductCarouselDia" && (
                        <section className="text-center header items-center mt-4 mb-3" key={index}>
                            <div className="mx-auto items-center">
                                <div className="w-full">
                                    <ProductCarouselDia
                                        data={dataelement.content}
                                        title={dataelement.title}
                                        subtitle={dataelement.subtitle}
                                        description={dataelement.description}
                                        viewAllLink={dataelement.viewAllLink}/>
                                </div>
                            </div>
                        </section>
                    )}

                    {dataelement != null && dataelement.content != null && dataelement.placardType == "StaticImages" && (
                        <section className="text-center header  items-center mt-4 mb-3" key={index}>
                            <div className="mx-auto items-center">
                                <div className="w-full">
                                    <StaticImages
                                        data={dataelement.content}
                                        title={dataelement.title}
                                        subtitle={dataelement.subtitle}
                                        description={dataelement.description}
                                        viewAllLink={dataelement.viewAllLink}/>
                                </div>
                            </div>
                        </section>
                    )}

                    {dataelement != null && dataelement.content != null && dataelement.placardType == "SideCarousel" && (
                        <section className="text-center header  items-center mt-4 mb-3" key={index}>
                            <div className="mx-auto items-center">
                                <div className="w-full">
                                    <SideCarousel
                                        data={dataelement.content}
                                        title={dataelement.title}
                                        subtitle={dataelement.subtitle}
                                        description={dataelement.description}
                                        viewAllLink={dataelement.viewAllLink}/>
                                </div>
                            </div>
                        </section>
                    )}


                    {dataelement != null && dataelement.content != null && dataelement.placardType == "VideoPlayer" && (
                        <section className="text-center header  items-center mt-4 mb-3" key={index}>
                            <div className="mx-auto items-center">
                                <div className="w-full">
                                    <VideoPlayer
                                        data={dataelement.content}
                                        title={dataelement.title}
                                        subtitle={dataelement.subtitle}
                                        description={dataelement.description}
                                        viewAllLink={dataelement.viewAllLink}/>
                                </div>
                            </div>
                        </section>
                    )}

                    {dataelement != null && dataelement.content != null && dataelement.placardType == "VideoCarousel" && (
                        <section className="text-center header  items-center mt-4 mb-3" key={index}>
                            <div className="mx-auto items-center">
                                <div className="w-full">
                                    <VideoCarousel
                                        data={dataelement.content}
                                        title={dataelement.title}
                                        subtitle={dataelement.subtitle}
                                        description={dataelement.description}
                                        viewAllLink={dataelement.viewAllLink}/>
                                </div>
                            </div>
                        </section>
                    )}

                    {dataelement != null && dataelement.content != null && dataelement.placardType == "StaticProducts4Half" && (
                        <section className="text-center header  items-center mt-4 mb-3" key={index}>
                            <div className="mx-auto">
                                <div className="lg:w-6/12 sm:w-full xs:w-full">
                                    <StaticProducts4Half
                                        data={dataelement.content}
                                        title={dataelement.title}
                                        subtitle={dataelement.subtitle}
                                        description={dataelement.description}
                                        viewAllLink={dataelement.viewAllLink}/>
                                </div>
                            </div>
                        </section>
                    )}
                    {dataelement != null && dataelement.content != null && dataelement.placardType == "BlogCarousel" && (
                        <section className="text-center header  items-center mt-4 mb-3" key={index}>
                            <div className="mx-auto items-center">
                                <div className="w-full">
                                    <BlogCarousel
                                        data={dataelement.content}
                                        title={dataelement.title}
                                        subtitle={dataelement.subtitle}
                                        description={dataelement.description}
                                        viewAllLink={dataelement.viewAllLink}/>
                                </div>
                            </div>
                        </section>
                    )}
                        </div>
                    );





                })}

                {/*{props.data != null && props.data.map(function(dataelement, index){*/}
                {/*    {dataelement != null && dataelement.subtitle != null && dataelement.placardType == TextMarqueeType && (*/}
                {/*        <section className="text-center header items-center mt-4">*/}
                {/*            <div className="mx-auto items-center">*/}
                {/*                <div className="w-full">*/}
                {/*                    <div className="max-h-800-px">*/}
                {/*                        <TextMarquee subtitle={dataelement.subtitle}/>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </section>*/}
                {/*    )}*/}
                {/*})}*/}


                {/*{props.data != null && props.data.map(function(dataelement, index){*/}
                {/*    {dataelement != null && dataelement.content != null && dataelement.placardType == SmallImageMarqueeType && (*/}
                {/*        <section className="text-center header items-center mt-8">*/}
                {/*            <div className="mx-auto items-center">*/}
                {/*                <div className="w-full">*/}
                {/*                    <div className="max-h-800-px">*/}
                {/*                        <SmallImageMarquee*/}
                {/*                            data={dataelement.content}*/}
                {/*                            title={dataelement.title}*/}
                {/*                            subtitle={dataelement.subtitle}*/}
                {/*                            description={dataelement.description}*/}
                {/*                            viewAllLink={dataelement.viewAllLink}/>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </section>*/}
                {/*    )}*/}
                {/*})}*/}

                {/*{props.data != null && props.data.map(function(dataelement, index){*/}
                {/*    {dataelement != null && dataelement.content != null && dataelement.placardType == MediumImageMarqueeType && (*/}
                {/*        <section className="text-center header items-center mt-8">*/}
                {/*            <div className="mx-auto items-center">*/}
                {/*                <div className="w-full">*/}
                {/*                    <div className="max-h-800-px">*/}
                {/*                        <MediumImageMarquee*/}
                {/*                            data={dataelement.content}*/}
                {/*                            title={dataelement.title}*/}
                {/*                            subtitle={dataelement.subtitle}*/}
                {/*                            description={dataelement.description}*/}
                {/*                            viewAllLink={dataelement.viewAllLink}/>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </section>*/}
                {/*    )}*/}
                {/*})}*/}

                {/*{props.data != null && props.data.map(function(dataelement, index){*/}
                {/*    {dataelement != null && dataelement.content != null && dataelement.placardType == LargeImageMarqueeType && (*/}
                {/*        <section className="text-center header items-center mt-8">*/}
                {/*            <div className="mx-auto items-center">*/}
                {/*                <div className="w-full">*/}
                {/*                    <div className="max-h-800-px">*/}
                {/*                        <LargeImageMarquee*/}
                {/*                            data={dataelement.content}*/}
                {/*                            title={dataelement.title}*/}
                {/*                            subtitle={dataelement.subtitle}*/}
                {/*                            description={dataelement.description}*/}
                {/*                            viewAllLink={dataelement.viewAllLink}/>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </section>*/}
                {/*    )}*/}
                {/*})}*/}

                {/*{props.data != null && props.data.map(function(dataelement, index){*/}
                {/*    {dataelement != null && dataelement.content != null && dataelement.placardType == XLargeImageMarqueeType && (*/}
                {/*        <section className="text-center header items-center mt-8">*/}
                {/*            <div className="mx-auto items-center">*/}
                {/*                <div className="w-full">*/}
                {/*                    <div className="max-h-800-px">*/}
                {/*                        <XLargeImageMarquee*/}
                {/*                            data={dataelement.content}*/}
                {/*                            title={dataelement.title}*/}
                {/*                            subtitle={dataelement.subtitle}*/}
                {/*                            description={dataelement.description}*/}
                {/*                            viewAllLink={dataelement.viewAllLink}/>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </section>*/}
                {/*    )}*/}
                {/*})}*/}

                {/*{props.data != null && props.data.map(function(dataelement, index){*/}
                {/*    {dataelement != null && dataelement.content != null && dataelement.placardType == VideoMarqueeType && (*/}
                {/*        <section className="text-center header items-center mt-8">*/}
                {/*            <div className="mx-auto items-center">*/}
                {/*                <div className="w-full">*/}
                {/*                    <div className="max-h-800-px">*/}
                {/*                        <VideoMarquee*/}
                {/*                            data={dataelement.content}*/}
                {/*                            title={dataelement.title}*/}
                {/*                            subtitle={dataelement.subtitle}*/}
                {/*                            description={dataelement.description}*/}
                {/*                            viewAllLink={dataelement.viewAllLink}/>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </section>*/}
                {/*    )}*/}
                {/*})}*/}


                {/*{props.data != null && props.data.map(function(dataelement, index){*/}
                {/*    {dataelement != null && dataelement.content != null && dataelement.placardType == StaticVideoPlayerType && (*/}
                {/*        <section className="text-center header  items-center mt-4">*/}
                {/*            <div className="mx-auto items-center">*/}
                {/*                <div className="w-full">*/}
                {/*                    <StaticVideoPlayer*/}
                {/*                        data={dataelement.content}*/}
                {/*                        title={dataelement.title}*/}
                {/*                        subtitle={dataelement.subtitle}*/}
                {/*                        description={dataelement.description}*/}
                {/*                        viewAllLink={dataelement.viewAllLink}/>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </section>*/}
                {/*    )}*/}
                {/*})}*/}

                {/*{props.data != null && props.data.map(function(dataelement, index){*/}
                {/*    {dataelement != null && dataelement.content != null && dataelement.placardType == ProductCarouselType && (*/}
                {/*        <section className="text-center header items-center mt-4">*/}
                {/*            <div className="mx-auto items-center">*/}
                {/*                <div className="w-full">*/}
                {/*                    <ProductCarousel*/}
                {/*                        data={dataelement.content}*/}
                {/*                        title={dataelement.title}*/}
                {/*                        subtitle={dataelement.subtitle}*/}
                {/*                        description={dataelement.description}*/}
                {/*                        viewAllLink={dataelement.viewAllLink}/>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </section>*/}
                {/*    )}*/}
                {/*})}*/}

                {/*{props.data != null && props.data.map(function(dataelement, index){*/}
                {/*    {dataelement != null && dataelement.content != null && dataelement.placardType == ProductCarouselDiaType && (*/}
                {/*        <section className="text-center header items-center mt-4">*/}
                {/*            <div className="mx-auto items-center">*/}
                {/*                <div className="w-full">*/}
                {/*                    <ProductCarouselDia*/}
                {/*                        data={dataelement.content}*/}
                {/*                        title={dataelement.title}*/}
                {/*                        subtitle={dataelement.subtitle}*/}
                {/*                        description={dataelement.description}*/}
                {/*                        viewAllLink={dataelement.viewAllLink}/>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </section>*/}
                {/*    )}*/}
                {/*})}*/}

                {/*{props.data != null && props.data.map(function(dataelement, index){*/}
                {/*    {dataelement != null && dataelement.content != null && dataelement.placardType == StaticImagesType && (*/}
                {/*        <section className="text-center header  items-center mt-4">*/}
                {/*            <div className="mx-auto items-center">*/}
                {/*                <div className="w-full">*/}
                {/*                    <StaticImages*/}
                {/*                        data={dataelement.content}*/}
                {/*                        title={dataelement.title}*/}
                {/*                        subtitle={dataelement.subtitle}*/}
                {/*                        description={dataelement.description}*/}
                {/*                        viewAllLink={dataelement.viewAllLink}/>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </section>*/}
                {/*    )}*/}
                {/*})}*/}

                {/*{props.data != null && props.data.map(function(dataelement, index){*/}
                {/*    {dataelement != null && dataelement.content != null && dataelement.placardType == SideCarouselType && (*/}
                {/*        <section className="text-center header  items-center mt-4">*/}
                {/*            <div className="mx-auto items-center">*/}
                {/*                <div className="w-full">*/}
                {/*                    <SideCarousel*/}
                {/*                        data={dataelement.content}*/}
                {/*                        title={dataelement.title}*/}
                {/*                        subtitle={dataelement.subtitle}*/}
                {/*                        description={dataelement.description}*/}
                {/*                        viewAllLink={dataelement.viewAllLink}/>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </section>*/}
                {/*    )}*/}
                {/*})}*/}


                {/*{props.data != null && props.data.map(function(dataelement, index){*/}
                {/*    {dataelement != null && dataelement.content != null && dataelement.placardType == VideoPlayerType && (*/}
                {/*        <section className="text-center header  items-center mt-4">*/}
                {/*            <div className="mx-auto items-center">*/}
                {/*                <div className="w-full">*/}
                {/*                    <VideoPlayer*/}
                {/*                        data={dataelement.content}*/}
                {/*                        title={dataelement.title}*/}
                {/*                        subtitle={dataelement.subtitle}*/}
                {/*                        description={dataelement.description}*/}
                {/*                        viewAllLink={dataelement.viewAllLink}/>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </section>*/}
                {/*    )}*/}
                {/*})}*/}

                {/*{props.data != null && props.data.map(function(dataelement, index){*/}
                {/*    {dataelement != null && dataelement.content != null && dataelement.placardType == VideoCarouselType && (*/}
                {/*        <section className="text-center header  items-center mt-4">*/}
                {/*            <div className="mx-auto items-center">*/}
                {/*                <div className="w-full">*/}
                {/*                    <VideoCarousel*/}
                {/*                        data={dataelement.content}*/}
                {/*                        title={dataelement.title}*/}
                {/*                        subtitle={dataelement.subtitle}*/}
                {/*                        description={dataelement.description}*/}
                {/*                        viewAllLink={dataelement.viewAllLink}/>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </section>*/}
                {/*    )}*/}
                {/*})}*/}

                {/*{props.data != null && props.data.map(function(dataelement, index){*/}
                {/*    {dataelement != null && dataelement.content != null && dataelement.placardType == BlogCarouselType && (*/}
                {/*        <section className="text-center header  items-center mt-4">*/}
                {/*            <div className="mx-auto items-center">*/}
                {/*                <div className="w-full">*/}
                {/*                    <BlogCarousel*/}
                {/*                        data={dataelement.content}*/}
                {/*                        title={dataelement.title}*/}
                {/*                        subtitle={dataelement.subtitle}*/}
                {/*                        description={dataelement.description}*/}
                {/*                        viewAllLink={dataelement.viewAllLink}/>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </section>*/}
                {/*    )}*/}
                {/*})}*/}
            </>
    );

}