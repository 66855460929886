import React, {useEffect, useState} from "react";
import IndexNavbar from "../../components/Navbars/IndexNavbar";
import Footer from "../../components/Footers/Footer";
import 'react-responsive-modal/styles.css';
import * as Constants from "../../constants/constants";
import { useLocation, Link, useHistory} from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "helpers/firebase.js";

export default function UserOrderPlacedView() {
    const location = useLocation();
    const params = location.state.params;
    const [data, setData] = useState(params);
    const navigate = useHistory();
    const [user, loading, error] = useAuthState(auth);

    useEffect (() => {
        if (loading) return;
        if (!user) return navigate.push("/");
    }, [user, loading]);

    return (
        <>
            <IndexNavbar />

            {data != null && (
                <>
                    {console.log(data)}
                    <section className="relative block h-400-px">
                        <div
                            className="absolute top-0 w-full h-full bg-center bg-cover"
                            style={{
                                   backgroundImage: "url(" + Constants.userOrderPlacedViewBackgroundImage + ")",
                            }}
                        >
                    <span
                        id="blackOverlay"
                        className="w-full h-full absolute opacity-50 bg-black"
                    ></span>
                        </div>
                        <div
                            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                            style={{ transform: "translateZ(0)" }}
                        >
                            <svg
                                className="absolute bottom-0 overflow-hidden"
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                version="1.1"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="text-blueGray-200 fill-current"
                                    points="2560 0 2560 100 0 100"
                                ></polygon>
                            </svg>
                        </div>
                    </section>


                    <section className="relative py-16 bg-blueGray-200">
                        <div className="container mx-auto px-2">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-80">
                                <div className="p-2">
                                    <div className="mb-4 flex flex-wrap justify-between w-full">
                                        <div className="w-full p-3">
                                            <h3 className="text-4xl text-center uppercase font-semibold leading-normal mb-2 text-blueGray-700">
                                                Order Placed
                                            </h3>
                                        </div>
                                        <div className="w-full flex flex-wrap px-3">
                                            <div className="md:w-6/12 lg:w-5/12 w-full mt-4">
                                                <div className="w-full flex flex-wrap justify-center">
                                                    <img src="https://img.freepik.com/premium-vector/isometric-people-silhouette-celebrate-birthday-flat-vector-illustration_580420-262.jpg?w=2000" className="cart-view-empty-cart" />
                                                </div>
                                                <div className="w-full flex flex-wrap justify-center mt-3">
                                                    <Link to={"/myorders/" + params.id}>
                                                        <button
                                                            className="bg-orange-500 text-white active:bg-lightBlue-600 font-bold uppercase text-md p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                            type="button"
                                                        >
                                                            View Order Details <i className="fas fa-arrow-right"></i>
                                                        </button>
                                                    </Link>

                                                </div>

                                            </div>
                                            <div className="md:w-6/12 lg:w-7/12 w-full card mt-4 p-3">
                                                <div className="w-full">
                                                    <p className="text-center font-bold text-md">
                                                        Order Summary
                                                    </p>
                                                    {data.totalMrp != null && data.totalMrp > 0 && (
                                                        <div className="flex justify-between mt-2">
                                                            <h1>MRP ({data.totalQuantity + " items"})</h1>
                                                            <p>{Constants.currency + " " + data.totalMrp}</p>
                                                        </div>

                                                    )}
                                                    {data.totalMrp - data.totalAmount > 0 && (
                                                        <div className="flex justify-between mt-2 mb-2">
                                                            <h1 className="text-md font-green">
                                                                Total Discount
                                                            </h1>
                                                            <p className="font-green">{" - " + Constants.currency + " " + (data.totalMrp - data.totalAmount)}</p>
                                                        </div>
                                                    )}
                                                    <hr/>
                                                    {data.totalAmount != null && data.totalAmount > 0 && (
                                                        <div className="flex justify-between mt-2">
                                                            <h1 className="font-bold">Total Price</h1>
                                                            <p className="font-bold">{Constants.currency + " " + data.totalAmount}</p>

                                                        </div>

                                                    )}
                                                    {data.paymentMethod != null && (
                                                        <div className="flex justify-between mt-2">
                                                            <h1 className="font-bold">Payment Mode</h1>
                                                            <p className="font-bold">{data.paymentMethod}</p>
                                                        </div>
                                                    )}

                                                </div>
                                                {/*{data.paymentReceived != data.totalAmount && (*/}
                                                {/*    <div className="w-full mt-3">*/}
                                                {/*        <p className="text-center text-sm text-blueGray-600">You have grabbed the highest discounts ever</p>*/}
                                                {/*    </div>*/}
                                                {/*)}*/}

                                                <div className="w-full flex flex-wrap justify-center mt-4 font-green">
                                                    <p>Congratulations 🥳, You just saved {Constants.currency + " " + (data.totalMrp - data.totalAmount)} </p>
                                                </div>
                                                <div className="w-full mt-3">
                                                    <p className="text-center text-sm text-blueGray-500">Orders are processed in first come first serve manner. We would request you to go to order details for next steps.</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="w-full p-3 mt-8">
                                            <p className="text-sm font-bold text-blueGray-600 text-center">Disclaimer</p>
                                            <p className="text-sm text-blueGray-500 mt-2 text-center">Since you have opted for online payments, we will contact you shortly from our registered mobile number. <br/>Please don't pay to anyone else who impersonate us and save yourself from online fraud.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )}
            <Footer />

        </>
    );
}