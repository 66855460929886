import {React,useState,useEffect,useMemo} from "react";
import {sendGetRequestAll, sendPostRequestAll, sendDeleteRequestAll} from "../../helpers/httprequesthelper";
import * as Constants from "constants/constants.js";
import {Modal} from "react-responsive-modal";
import {HttpStatusCode} from "axios";
import { MaterialReactTable } from 'material-react-table';

export default function FindProduct() {
    var retryCount = 0;
    const maxRetryCount = 5;
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [query, setQuery] = useState(null);
    const [data, setData] = useState(null);
    const [productData, setProductData] = useState([]);

    function onCloseErrorModal() {
        setOpenErrorModal(false);
        setErrorMessage(null);
    }
    function onCloseSuccessModal() {
        setOpenSuccessModal(false);
        setSuccessMessage(null);
    }

    const createProductDataObject = (data) => {
        if (data == null) {
            return;
        }
        var productData = [];
        data.forEach(function (item, index) {
            productData.push({
                          "id" : item._id,
                          "title" : item.title,
                          "subtitle" : item.subtitle,
                          "image" : item.mediaLink,
                          "category" : item.categoryId,
                          "mrp" : item.mrp,
                          "costprice" : item.costPrice,
                          "sellingprice" : item.sellingPrice,
                          "lowsellingprice" : item.lowestSellingPrice,
                          "discount" : item.discountPercentage,
                          "quantity" : item.quantity,
                          "favourite" : item.favourite == null ? "Null" : item.favourite.toString(),
                          "brand" : item.brandId,
                          "isAvailable" : item.isAvailable == null ? "Null" : item.isAvailable.toString(),
                          "viewUrl" : item.urlLink,
                          "editUrl" : "/admin/products/update?id=" + item._id })
        });
        console.log(productData);
        setProductData(productData);
    }

    const columns = useMemo(
        () => [

            {
                header: "Id",
                accessorKey: 'id', //access nested data with dot notation
                size: 6
            },
            {
                header: "Product Image",
                accessorKey: 'image', //access nested data with dot notation
                Cell: ({ cell }) => (
                    <img
                      alt="avatar"
                      height={10}
                      src={cell.getValue()}
                      loading="lazy"
                      className="w-auto h-auto align-middle max-h-120-px max-w-120-px"
                    />
                ),
                size: 30
            },
            {
                header: "Title",
                accessorKey: 'title', //access nested data with dot notation
                size: 6
            },
            {
                header: "Subtitle",
                accessorKey: 'subtitle', //access nested data with dot notation
                size: 6
            },
            {
                header: "Quantity",
                accessorKey: 'quantity', //access nested data with dot notation
                size: 6
            },
            {
                header: "Mrp",
                accessorKey: 'mrp', //access nested data with dot notation
                size: 6
            },
            {
                header: "Discount %",
                accessorKey: 'discount', //access nested data with dot notation
                size: 6
            },
            {
                header: "Selling Price",
                accessorKey: 'sellingprice', //access nested data with dot notation
                size: 6
            },
            {
                header: "L. Selling Price",
                accessorKey: 'lowsellingprice', //access nested data with dot notation
                size: 6
            },
            {
                header: "Cost Price",
                accessorKey: 'costprice', //access nested data with dot notation
                size: 6
            },
            {
                header: "Category Id",
                    accessorKey: 'category', //access nested
                    Cell: ({ cell }) => (
                        <>
                            <a href={"/admin/categories/update?id=" + cell.getValue()} className="font-bold">
                                {cell.getValue()}
                            </a>
                        </>
                    ),
                    size: 10
            },
            {
                header: "Brand Id",
                    accessorKey: 'category', //access nested
                    Cell: ({ cell }) => (
                        <>
                            <a href={"/admin/brands/update?id=" + cell.getValue()} className="font-bold">
                                {cell.getValue()}
                            </a>
                        </>
                    ),
                    size: 10
            },
            {
                header: "isAvailable",
                accessorKey: 'isAvailable', //access nested data with dot notation
                size: 10
            },
            {
                header: "Favourite",
                accessorKey: 'favourite', //access nested data with dot notation
                size: 10
            },
            {
                header: "View",
                accessorKey: 'viewUrl', //access nested
                Cell: ({ cell }) => (
                    <>
                        <a href={cell.getValue()} className="font-bold">
                            <i class="fa fa-eye" aria-hidden="true"></i>
                        </a>
                    </>
                ),
                size: 10
            },
            {
                header: "Edit",
                accessorKey: 'editUrl', //access nested
                Cell: ({ cell }) => (
                    <>
                        <a href={cell.getValue()} className="font-bold">
                            <i class="fa fa-edit" aria-hidden="true"></i>
                        </a>
                    </>
                ),
                size: 10
            }
        ],
        [],
    );


    async function handleSearch(e) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        var formJson = Object.fromEntries(formData.entries());
        if(!formJson.query || !formJson.query.trim()) {
            setErrorMessage("Query is empty");
            setOpenErrorModal(true);
            return;
        }
        console.log("formJson");
        console.log(formJson)
        await fetchProductData(formJson.query);
    }

    async function fetchProductData(query) {
        try {
            var response = await sendGetRequestAll(Constants.backendUrl + "/admin/product/find?query=" + encodeURIComponent(query), true);

            if (response.status == HttpStatusCode.Ok) {
                if (response.data != null) {
                    setData(response.data);
                    createProductDataObject(response.data);
                    console.log("Hello : " + JSON.stringify(response.data));
                }
            } else {
                setErrorMessage(response.data);
                setOpenErrorModal(true);
            }
            console.log("Here the response : ");
            console.log(response);
        } catch (err) {
            console.log("Error in getting the product data : ");
            console.error(err);
            setOpenErrorModal(true);
            setErrorMessage(err.response.data);
        }
    }

     useEffect(() => {
     }, []);

    return (
        <>
            <div className="flex-auto px-4 pt-0">
                <Modal open={openErrorModal} onClose={onCloseErrorModal} center>
                                <h2 className="text-xl mb-4 font-bold border-b pb-2">Oops, An Error Occurred ! &nbsp; &nbsp; &nbsp; &nbsp;</h2>
                                <p>{errorMessage}</p>
                            </Modal>
                <Modal open={openSuccessModal} onClose={onCloseSuccessModal} center>
                    <h2 className="text-xl mb-4 font-bold border-b pb-2">Operation successful &nbsp; &nbsp; &nbsp; &nbsp;</h2>
                    <p>{successMessage}</p>
                </Modal>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
                <div className="card p-4 mt-4 bg-white">
                <h2 className="text-xl mb-4 font-bold pb-2">Find Products by query</h2>
                     <div className="flex flex-wrap">
                       <div className="w-full px-4">
                            { data!= null && data.length > 0 && (
                                <div className="mt-2">
                                    <MaterialReactTable columns={columns} data={productData}
                                        enableColumnActions={true}
                                        enableColumnFilters={true}
                                        enablePagination={false}
                                        enableSorting={true}
                                        enableBottomToolbar={false}
                                        enableTopToolbar={false}
                                        muiTableBodyRowProps={{ hover: false }}/>
                                </div>
                            )}
                        </div>
                      </div>
                  <form onSubmit={handleSearch} className="mt-3">
                      <input
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          name="query" value={query}
                        />
                      <button
                          className="bg-primary mt-4 text-white font-bold uppercase text-sm px-4 p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                          type="submit"
                      >
                          <i class="fa fa-search" aria-hidden="true"></i> &nbsp; &nbsp; Search Product
                      </button>
                  </form>
                  <div className="mt-3">
                    <h2 className="mt-2 font-bold text-md mb-2">Examples : </h2>
                    <ul className="list-none">
                        <li className="mt-6">
                            <p>Find all products of brand with id 'brandId1'</p>
                            <p className="text-sm mt-2">{"{ 'find': 'product', filter: { brandId: 'brandId1' }, limit: 10 }"}</p>
                        </li>
                        <li className="mt-6">
                            <p>Find all products of category with id 'categoryId1'</p>
                            <p className="text-sm mt-2">{"{ 'find': 'product', filter: { categoryId: 'categoryId1' }, limit: 10 }"}</p>
                        </li>
                        <li className="mt-6">
                            <p>Find all products of brand with id 'brandId1' and mrp less than 5000</p>
                            <p className="text-sm mt-2">{"{ 'find': 'product', filter: { brandId: 'brandId1' , mrp: { $lt: 5000 }}}"}</p>
                        </li>
                    </ul>

                    <div className="card p-2 mt-4">
                        <h2 className="font-bold text-md">Valid Columns</h2>
                        <ul className="list-none">
                            <li className="mt-2">id</li>
                            <li className="mt-2">brandId</li>
                            <li className="mt-2">categoryId</li>
                            <li className="mt-2">merchantId</li>
                            <li className="mt-2">creationDate</li>
                            <li className="mt-2">title</li>
                            <li className="mt-2">subtitle</li>
                            <li className="mt-2">description</li>
                            <li className="mt-2">starRating</li>
                            <li className="mt-2">favourite</li>
                            <li className="mt-2">ttl</li>
                            <li className="mt-2">showAfter</li>
                            <li className="mt-2">showBefore</li>
                            <li className="mt-2">shouldShow</li>
                            <li className="mt-2">mediaLink</li>
                            <li className="mt-2">quantity</li>
                            <li className="mt-2">mrp</li>
                            <li className="mt-2">costPrice</li>
                            <li className="mt-2">sellingPrice</li>
                            <li className="mt-2">wholesalePrice</li>
                            <li className="mt-2">minimumWholesaleQuantity</li>
                            <li className="mt-2">isAvailable</li>
                            <li className="mt-2">isOriginal</li>
                            <li className="mt-2">comingSoon</li>
                            <li className="mt-2">isFreeDeliveryEligible</li>
                            <li className="mt-2">discountPercentage</li>
                        </ul>
                    </div>

                  </div>
                </div>
            </div>
        </>
    )
}