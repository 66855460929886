import React from "react";
import { Bars } from 'react-loader-spinner'

export default function Loader(props) {
  return (
    <>
    {props && !props.data && (

       <div className="w-full mt-48 mb-24 flex flex-wrap justify-center">
                                  <Bars
                                    height="120"
                                    width="120"
                                    color="#4fa94d"
                                    ariaLabel="bars-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={!props.data}
                                    />
                              </div>
                              )}
    </>
  );
}
