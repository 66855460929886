import React from "react";
import CardLineChart from "components/Cards/CardLineChart.js";
import CardBarChart from "components/Cards/CardBarChart.js";
import CardPageVisits from "components/Cards/CardPageVisits.js";
import CardSocialTraffic from "components/Cards/CardSocialTraffic.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import ProductsTable from "components/Cards/ProductsTable.js"

export default function ProductDashboard() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">
                  <ProductsTable />
                </div>

      </div>
    </>
  );
}
