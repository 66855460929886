
import React from "react";
import StandardPricingAndCartSection from "./StandardPricingAndCartSection";
import {Link} from "react-router-dom";
import * as FormatHelper from "helpers/formathelper.js";

export default function ProductLarge(props) {
    return (
        <>
            <Link to={props.dataelement.urlLink} key={props.dataelement.id}>
                <div className="p-2">
                    <div className="w-full inline-flex items-center justify-center">
                        <img
                            src={props.dataelement.mediaLink}
                            className="w-auto h-auto align-middle rounded-t-lg max-h-200-px"/>
                    </div>
                    { props.dataelement.title && (
                        <h4 className="product-carousel-title font-bold mt-2">{FormatHelper.truncateString(props.dataelement.title, 30)}</h4>
                    )}
                </div>
                <StandardPricingAndCartSection id={props.dataelement.id} mrp={props.dataelement.mrp} sellingPrice={props.dataelement.sellingPrice} discountPercentage={props.dataelement.discountPercentage} invertColors={false}/>
            </Link>
        </>
    );
}