import React, {useEffect, useState, useMemo} from "react";
import {useHistory} from "react-router-dom";
import {sendGetRequestAll} from "../../helpers/httprequesthelper";
import IndexNavbar from "../../components/Navbars/IndexNavbar";
import Footer from "../../components/Footers/Footer";
import QRCode from "react-qr-code";
import Carousel from "react-multi-carousel";
import * as Constants from "../../constants/constants";
import Rating from "react-rating";
import StarRating from 'react-star-rating';
import * as FormatHelper from "../../helpers/formathelper";
import PlacardComponent from "../../components/PlacardComponent";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import {daysLeft} from "../../helpers/formathelper";
import { Link } from "react-router-dom";

export default function MerchantView(props) {
    const [data, setData] = useState([]);
    const [merchantData, setMerchantData] = useState([]);
    const [openContactModal, setOpenContactModal] = useState(false);

    const [openInfoModal, setOpenInfoModal] = useState(false);


    const onOpenContactModal = () => setOpenContactModal(true);
    const onCloseContactModal = () => setOpenContactModal(false);

    const onOpenInfoModal = () => setOpenInfoModal(true);
    const onCloseInfoModal = () => setOpenInfoModal(false);

    const navigate = useHistory();

    const responsive = {
        tv: {
            breakpoint: { max: 3000, min: 1401 },
            items: 4,
            slidesToSlide: 1 // optional, default to 1.
        },
        desktop: {
            breakpoint: { max: 1400, min: 1024 },
            items: 4,
            slidesToSlide: 1 // optional, default to 1.
        },
        laptop: {
            breakpoint: { max: 1023, min: 860 },
            items: 4,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 860, min: 464 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    const fetchData = async () => {
        try {
            var response = await sendGetRequestAll(Constants.backendUrl + "/merchant/"+props.match.params.mid, false);
            setData(response.data);
        } catch (err) {
            console.error(err);
            alert("An error occured while loading merchant.");
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <IndexNavbar />
                <section className="relative block h-400-px">
                    <div
                        className="absolute top-0 w-full h-full bg-center bg-cover"
                        style={{
                            backgroundImage:
                                "url('https://images.unsplash.com/photo-1499336315816-097655dcfbda?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2710&q=80')",
                        }}
                    >
            <span
                id="blackOverlay"
                className="w-full h-full absolute opacity-50 bg-black"
            ></span>
                    </div>
                    <div
                        className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                        style={{ transform: "translateZ(0)" }}
                    >
                        <svg
                            className="absolute bottom-0 overflow-hidden"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="text-blueGray-200 fill-current"
                                points="2560 0 2560 100 0 100"
                            ></polygon>
                        </svg>
                    </div>
                </section>


                <section className="relative py-16 bg-blueGray-200">
                    <div className="container mx-auto px-2">
                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
                            <div className="px-2">
                                <div className="flex flex-wrap justify-center">
                                    <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                                        <div className="relative">
                                            {data.logoUrl!=null && (
                                                <img
                                                    alt="..."
                                                    src={data.logoUrl}
                                                    className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mt-24 mb-4">
                                    {data.name != null && (
                                        <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700">
                                            {data.name}
                                        </h3>
                                    )}
                                    {data.merchantCategory != null && data.merchantCategory.name != null && (
                                        <Link to={data.merchantCategory.urlLink}>
                                            <h3 className="text-xl font-semibold leading-normal mb-2 text-lightBlue-600">
                                                {data.merchantCategory.name}
                                            </h3>
                                        </Link>
                                    )}
                                    {data.starRating != null && (
                                        <div className="text-center mt-2">
                                            <Rating initialRating={data.starRating} emptySymbol={<i className="fas fa-star brand-rating-star-empty text-md"></i>} fullSymbol={<i className="fas fa-star brand-rating-star-filled text-md"></i>} readonly={true}/>
                                        </div>
                                    )}
                                    {data.address != null && (
                                        <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase mt-4">
                                            <i className="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>{" "}
                                            {data.address}
                                        </div>
                                    )}
                                    {data.daysOpen != null && data.timings != null && (
                                    <div className="text-center">
                                        <p><span className="font-semibold">Timings </span> : {FormatHelper.formatAreasWithCommaAnd(data.daysOpen)} &nbsp;&nbsp; ( {data.timings} )</p>
                                    </div>
                                    )}
                                    {data.mapsLocation != null && (

                                            <div className="mb-2 mt-6 text-blueGray-600 text-center">

                                                {data.phoneNumbers != null && data.phoneNumbers.length > 0 && (
                                                    <>
                                                        <button className="btn-outline btn-success-outline text-sm mt-2" onClick={onOpenContactModal}>
                                                            Contact &nbsp;&nbsp;<i className="fas fa-phone"></i>
                                                        </button>
                                                        <Modal open={openContactModal} onClose={onCloseContactModal} center>
                                                            <h2 className="text-xl mb-4 font-bold border-b pb-2">Contact Us</h2>
                                                            <p>
                                                            We are reachable over below numbers. Please feel free to call us.

                                                            </p>
                                                            {data.daysOpen != null && data.timings != null && (
                                                                <div className="text-center mt-4">
                                                                    <p><span className="font-semibold">Timings </span> : {FormatHelper.formatAreasWithCommaAnd(data.daysOpen)} &nbsp;&nbsp;<br/>( {data.timings} )</p>
                                                                </div>
                                                            )}
                                                            <div className="mt-6 text-center">
                                                                {data.phoneNumbers != null && data.phoneNumbers.map(function(phoneNumber, index){
                                                                    return (<>
                                                                            <a href={"tel:+91" + phoneNumber}>
                                                                                <button className="btn-outline btn-success-outline text-sm mt-2">
                                                                                    <i className="fas fa-phone"></i>&nbsp; +91 {phoneNumber}
                                                                                </button>&nbsp;&nbsp;&nbsp;
                                                                            </a>
                                                                        </>
                                                                    );

                                                                })}
                                                            </div>

                                                        </Modal>
                                                    </>
                                                )}




                                                &nbsp;&nbsp;
                                                <a href={data.mapsLocation}>
                                                    <button className="btn-outline btn-success-outline text-sm mt-2">
                                                        Google Map &nbsp;&nbsp;<i className="fas fa-location-arrow"></i>
                                                    </button>
                                                </a>

                                                {(data.description != null || data.createdAt != null) && (
                                                    <>
                                                        &nbsp;&nbsp;
                                                        <button className="btn-outline btn-success-outline text-sm mt-2" onClick={onOpenInfoModal}>
                                                            More Info&nbsp;&nbsp; <i className="fas fa-info"></i>
                                                        </button>


                                                        <Modal open={openInfoModal} onClose={onCloseInfoModal} center>
                                                            <h2 className="text-xl mb-4 font-bold border-b pb-2">About {data.name}</h2>
                                                            <p>{data.description}</p>
                                                            <p className="mt-4">This Merchant is on the platform since <span className="font-semibold">{daysLeft(data.createdAt)}</span> days</p>
                                                        </Modal>
                                                    </>
                                                )}
                                                &nbsp;&nbsp;
                                                <button className="btn-outline btn-success-outline text-sm mt-2">
                                                    Whatsapp&nbsp;&nbsp; <i className="fab fa-whatsapp"></i>
                                                </button>
                                                &nbsp;&nbsp;
                                                <button className="btn-outline btn-success-outline text-sm mt-2">
                                                    Instagram&nbsp;&nbsp; <i className="fab fa-instagram"></i>
                                                </button>
                                                &nbsp;&nbsp;
                                                <button className="btn-outline btn-success-outline text-sm mt-2">
                                                    Facebook&nbsp;&nbsp; <i className="fab fa-facebook-square"></i>
                                                </button>
                                            </div>

                                    )}

                                    <div className="text-center mt-4">
                                        {data != null && data.servicesOffered != null && data.servicesOffered.map(function(service, index){
                                            return (
                                                <>
                                                    <span className="text-sm font-semibold inline-block py-1 px-2 mt-2 uppercase rounded text-teal-600 bg-teal-200 uppercase last:mr-0 mr-2">
                                                        {service}
                                                    </span>
                                                </>
                                            )

                                        })}
                                    </div>
                                </div>
                                {data != null && data.placardData!=null && data.placardData.length > 0 && (
                                    <div className="mt-10 border-t border-blueGray-200 mb-8">
                                        <PlacardComponent data={data.placardData}/>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            <Footer />

        </>
    );
}