import {Link, useHistory} from "react-router-dom";
import {sendGetRequestAll, sendPostRequestAll} from "../../helpers/httprequesthelper";
import React, {useState,useEffect} from "react";
import {HttpStatusCode} from "axios";
import * as Constants from "constants/constants.js";
import * as cartViewConstant from "../../constants/cartViewConstant";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {Modal} from "react-responsive-modal";
import * as FormatHelper from "../../helpers/formathelper";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "helpers/firebase.js";

export default function MerchantCart(props) {
    const [cartMap, setCartMap] = useState(props.data.productsMap);
    const [totalAmount, setTotalAmount] = useState(getTotalAmount());
    const [totalQuantity, setTotalQuantity] = useState(getTotalQuantity());
    const [totalSavings, setTotalSavings] = useState(getTotalSavings());
    const [cartMessage, setCartMessage] = useState(null);
    const [addressOption, setAddressOption] = useState(null);
    const [defaultAddressOption, setDefaultAddressOption] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [addressData, setAddressData] = useState(null);
    const [retryCount, setRetryCount] = useState(0);
    const maxRetryCount = 5;
    const [user, loading, error] = useAuthState(auth);
    const navigate = useHistory();
    const [selectedPaymentOption, setSelectedPaymentOption] = useState({value : "UPI", label : "UPI"});

    const fetchAddressData = async () => {
        try {
            var addressResponse = await sendGetRequestAll(Constants.backendUrl + "/myaddresses", true);
            if (addressResponse.status == HttpStatusCode.Ok) {
                setAddressData(addressResponse.data);
                setAddressOptionJson(addressResponse.data);
            } else {
                setAddressData(null);
            }
        } catch (err) {
            console.error(err);
            console.error("Error Occurred");
            setAddressData(null);
            if (addressData == null && retryCount < maxRetryCount) {
                console.log("Retrying");
                setRetryCount(retryCount+1);
                setTimeout(fetchAddressData, 1000);
            }
        }
    }

    function onCloseErrorModal() {
        setOpenErrorModal(false);
        setErrorMessage(null);
    }

    function getTotalAmount() {
        var amount = 0;
        if (cartMap != null) {
            for (let productId of Object.keys(cartMap)) {
                var values = cartMap[productId];
                amount += (values.cartQuantity * values.sellingPrice);
            }
        }
        console.log(amount);
        return amount;
    }

    async function checkout() {
        if (selectedAddress == null) {
            setOpenErrorModal(true);
            setErrorMessage("Please select an address to continue.")
            return;
        }
        if (totalAmount <= 0) {
            setOpenErrorModal(true);
            setErrorMessage("You can't checkout with this amount. Please add product and try again.")
            return;
        }
        await updateSelectedAddressAndPayment(selectedAddress, selectedPaymentOption);
        navigate.push("/checkout");
    }

    async function updateSelectedAddressAndPayment(selectedAddress, selectedPaymentOption) {
        console.log(selectedAddress.value);
        console.log(selectedPaymentOption.value);
        var data = {
            "addressId": selectedAddress.value,
            "paymentMethod": selectedPaymentOption.value
        }
        try {
            var response = await sendPostRequestAll(Constants.backendUrl + "/checkout", data, true);
            console.log(response);
        } catch (err) {
            console.log("Error in adjustQuantity : ");
            console.error(err);
        }
    }

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate.push("/");
        fetchAddressData();
    }, [loading, user]);

    function setAddressOptionJson(addressData) {
        if (addressData != null) {
            const options = [];
            addressData.map(function(dataelement, index) {
                var labelString = FormatHelper.getAddressLabelString(dataelement);
                if (dataelement.default == true) {
                    setDefaultAddressOption({value : dataelement.id, label : labelString});
                    setSelectedAddress({value : dataelement.id, label : labelString});
                }
                options.push({value : dataelement.id, label : labelString})
            });
            setAddressOption(options);
        }
    }

    function getPaymentOptions() {
        var options = [];
        options.push({value : "CASH", label : "CASH"});
        options.push({value : "UPI", label : "UPI"});
        return options;
    }

    function getTotalQuantity() {
        var quantity = 0;
        if (cartMap != null) {
            for (let productId of Object.keys(cartMap)) {
                var values = cartMap[productId];
                quantity += values.cartQuantity;
            }
        }
        return quantity;
    }

    function hideCartMessage() {
       setCartMessage(null);
    }

    function getTotalSavings() {
        var savings = 0;
        if (cartMap != null) {
            for (let productId of Object.keys(cartMap)) {
                var values = cartMap[productId];
                savings += (values.cartQuantity * (values.mrp - values.sellingPrice));
            }
        }
        return savings;
    }


    async function adjustQuantity (productId, quantity, moveToWishlist) {
        setCartMessage(null);
        var data = {
            "productId" : productId,
            "quantity" : quantity,
            "moveToWishlist" : moveToWishlist
        }
        try {
            var response = await sendPostRequestAll(Constants.backendUrl + "/mycart/product/quantity", data, true);
            if (response.status == HttpStatusCode.Ok) {
                if (response.data.message != null) {
                    setCartMessage(response.data.message);
                    setTimeout(hideCartMessage, 3000);
                }
                var updatedProduct = cartMap[response.data.productId];
                if(response.data.quantity <= 0) {
                  delete cartMap[response.data.productId];
                } else {
                    updatedProduct.cartQuantity = response.data.quantity;
                    cartMap[response.data.productId] = updatedProduct;
                    setCartMap(cartMap);
                }
                setTotalAmount(getTotalAmount());
                setTotalSavings(getTotalSavings());
                setTotalQuantity(getTotalQuantity());
            }
        } catch (err) {
            console.log("Error in adjustQuantity : ");
            console.error(err);
        }
    };

    return (
        <div className="w-full" id="root">
            <Modal open={openErrorModal} onClose={onCloseErrorModal} center>
                <h2 className="text-xl mb-4 font-bold border-b pb-2">Error Occurred</h2>
                <p>{errorMessage}</p>
            </Modal>

            { cartMessage!= null && (
                <div className="text-white px-6 py-3 border-0 rounded relative mb-4 bg-emerald-500">
                  <span className="text-xl inline-block mr-5 align-middle">
                    <i className="fas fa-bell"></i>
                  </span>
                    <span className="inline-block align-middle mr-8">
                     {cartMessage}
                  </span>
                    <button className="absolute bg-transparent text-2xl font-semibold leading-none right-10 top-0 mt-4 mr-6 outline-none focus:outline-none" onClick={()=>hideCartMessage()}>
                        <span>×</span>
                    </button>
                </div>
            )}

                 <div className="p-2 w-full">
                    <div>
                        {cartMap != null && Object.keys(cartMap).map((key, index) => {
                            return (
                                <>
                                    {cartMap[key].cartQuantity > 0 && (
                                        <div className="p-2 border-b">
                                            <div className="w-full mt-2 flex justify-between">
                                                <div className="flex">
                                                    <div>
                                                        <Link to={cartMap[key].urlLink}>
                                                            <img
                                                                src={cartMap[key].mediaLink}
                                                                className="w-auto h-auto align-middle rounded-t-lg max-h-100-px max-w-100-px"/>
                                                        </Link>
                                                    </div>
                                                    <div className="ml-4">
                                                        <h4 className="product-carousel-title font-bold">{cartMap[key].title}</h4>
                                                        <h4 className="product-carousel-title mt-2">{cartMap[key].subtitle}</h4>
                                                        <p className="mt-3"> <span className="mrp text-sm">{Constants.currency + " " + cartMap[key].mrp}</span> <span className="text-lg ml-3 font-bold">{Constants.currency + " " + cartMap[key].sellingPrice}</span><span className="text-2xs ml-3 font-semibold inline-block p-1 uppercase rounded text-emerald-600 bg-emerald-200 uppercase mr-1">
                                      {Math.trunc(cartMap[key].discountPercentage)} % off
                                    </span></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="flex mt-3">
                                                    <div>
                                                        <button
                                                            className="cursor-pointer text-xl px-3 py-1 text-md border border-solid rounded"
                                                            type="button"
                                                            onClick={() => adjustQuantity(cartMap[key].id, cartMap[key].cartQuantity-1, false)}
                                                        >
                                                            -
                                                        </button>
                                                        <span className="px-2">{cartMap[key].cartQuantity}</span>
                                                        <button
                                                            className="cursor-pointer text-xl px-3 py-1 text-md border border-solid rounded"
                                                            type="button"
                                                            onClick={() => adjustQuantity(key, cartMap[key].cartQuantity + 1, false)}
                                                        >
                                                            +
                                                        </button>
                                                        <button
                                                            className="cursor-pointer font-bold text-md mt-2 px-2 py-0 text-md ml-3"
                                                            type="button"
                                                            onClick={() => adjustQuantity(key, 0, false)}
                                                        >
                                                            Remove
                                                        </button>
                                                        <button
                                                            className="cursor-pointer font-bold text-md mt-2 ml-2 px-2 py-0 text-md "
                                                            type="button"
                                                            onClick={() => adjustQuantity(key, cartMap[key].cartQuantity, true)}
                                                        >
                                                            Move to Wishlist
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>);
                        })}

                    </div>
                </div>

            {cartMap == null || Object.keys(cartMap).length == 0 && (
                <div className="w-full ">
                    <div className="flex flex-wrap justify-center">
                        <img src={cartViewConstant.emptyCartImageUrl} className="cart-view-empty-cart" />
                    </div>
                    <div>
                        <p className="text-center font-bold text-xl">Your cart is empty !!</p>
                        <p className="text-center text-lg">Explore our hot categories and save more.</p>
                    </div>
                    <div className="flex flex-wrap justify-center mt-6">
                        <Link to="/store">
                            <button
                                className="bg-primary text-white active:bg-lightBlue-600 font-bold uppercase text-md px-4 p-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                type="button"
                            >
                                <i className="fa-solid fa-store"></i> &nbsp;&nbsp; Shop Now
                            </button>
                        </Link>
                    </div>

                </div>
            )}


            { addressOption!= null && cartMap != null && Object.keys(cartMap).length > 0 &&  (
                <div className="mb-8 mt-8 p-3">
                    <div className="mb-4 flex flex-wrap justify-between">
                        <h3 className="text-xl font-bold">Delivery Address</h3>
                        <Link to="/myaddresses?addAddress=true">
                            <p className="text-emerald-500 text-lg"><i className="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;New</p>
                        </Link>
                    </div>
                    <div>
                        <Dropdown options={addressOption} value={defaultAddressOption} onChange={setSelectedAddress} placeholder="Select an option" />
                    </div>
                </div>
            )}
            {cartMap != null && Object.keys(cartMap).length > 0 && (
                <div className="mb-8 mt-8 p-3">
                    <div className="mb-4 flex flex-wrap justify-between">
                        <h3 className="text-xl font-bold">Payment Method</h3>
                    </div>
                    <div>
                        <Dropdown options={getPaymentOptions()} value={selectedPaymentOption} onChange={setSelectedPaymentOption} placeholder="Select an option" />
                    </div>
                </div>
            )}


            <div className="mt-4 flex w-full justify-between">
                <div>
                    {totalAmount != null && totalAmount > 0 && (
                        <h1 className="text-lg ml-3 font-bold">
                            Cart Total : {Constants.currency + " " + totalAmount}
                        </h1>
                    )}
                    {totalQuantity != null && totalQuantity > 0 && (
                        <h1 className="text-md ml-3">
                            Total Items : {totalQuantity}
                        </h1>
                    )}
                </div>
                <div>
                    {totalQuantity != null && totalQuantity > 0 && (
                        <button
                            className="bg-primary text-white active:bg-lightBlue-600 font-bold uppercase text-md px-4 p-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={()=>checkout()}
                        >
                            Check Out &nbsp;&nbsp; <i className="fas fa-arrow-right"></i>
                        </button>
                    )}
                </div>

            </div>

            <div className="mt-4 flex w-full justify-center ">
                <Link to="/mywishlist">
                    <button
                        className="active:bg-lightBlue-600 font-bold uppercase text-sm px-4 p-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button"
                    >
                        Go To Wishlist
                    </button>
                </Link>
            </div>

            {totalSavings!=null && totalSavings > 0 && (
                <div className="mt-8 flex w-full justify-center ">
                    <p className="text-center">Congratulations 🥳, You are saving {Constants.currency + " " + totalSavings} with these cart items. Checkout Now ! </p>
                </div>
            )}


        </div>
    );
}