import { Carousel } from 'react-responsive-carousel';
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Link } from "react-router-dom";

export default function BannerCarousel(props) {
    return (
        <>
            <Carousel autoPlay="true" interval="2000" infiniteLoop="true" dynamicHeight="false" showThumbs={false}>
                { props.data != null && props.data.map((dataelement, index) => {
                    return  <Link to={dataelement.urlLink} key={dataelement.id}>
                                <div>
                                    <img src={dataelement.mediaLink} className="h-banner-carousel-image" />
                                    { dataelement.title && (
                                        <p className="legend">{dataelement.title}</p>
                                    )}
                                </div>
                            </Link>
                })}
            </Carousel>
        </>
    );
}