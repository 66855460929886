import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {sendGetRequestAll} from "../../helpers/httprequesthelper";
import IndexNavbar from "../../components/Navbars/IndexNavbar";
import Footer from "../../components/Footers/Footer";
import 'react-responsive-modal/styles.css';
import {HttpStatusCode} from "axios";
import UserWishlist from "../../components/Wishlist/UserWishlist";
import * as Constants from "constants/constants.js";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "helpers/firebase.js";
import Loader from "components/Loader/Loader.js"

export default function UserWishlistView(props) {
    const [user, loading, error] = useAuthState(auth);
    const [data, setData] = useState(null);
    const navigate = useHistory();

    const fetchData = async () => {
        try {
            var response = await sendGetRequestAll(Constants.backendUrl + "/mywishlist", true);
            if (response.status === HttpStatusCode.Ok) {
                setData(response.data);
            } else {
                setData(null);
            }
        } catch (err) {
            setData(null);
        }
    };

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate.push("/");
        fetchData();
    }, [user, loading]);

    return (
        <>
            <IndexNavbar />
             {!data && (
                   <Loader/>
                )}

            {data && (
            <>

            <section className="relative block h-400-px">
                <div
                    className="absolute top-0 w-full h-full bg-center bg-cover"
                    style={{
                       backgroundImage: "url(" + Constants.userWishlistViewBackgroundImage + ")",
                    }}
                >
            <span
                id="blackOverlay"
                className="w-full h-full absolute opacity-50 bg-black"
            ></span>
                </div>
                <div
                    className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                    style={{ transform: "translateZ(0)" }}
                >
                    <svg
                        className="absolute bottom-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="text-blueGray-200 fill-current"
                            points="2560 0 2560 100 0 100"
                        ></polygon>
                    </svg>
                </div>
            </section>


            <section className="relative py-16 bg-blueGray-200">
                <div className="container mx-auto px-2">
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-80">
                        <div className="p-2">

                            <div className="mb-4 flex flex-wrap justify-between w-full">
                                <div className="w-full p-3">
                                    <h3 className="text-4xl text-center uppercase font-semibold leading-normal mb-2 text-blueGray-700">
                                        My Wishlist
                                    </h3>

                                </div>
                                <div className="w-full">
                                    {data != null && (
                                        <UserWishlist data={data}/>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </>
            )}
            <Footer />

        </>
    );
}