import ReactPlayer from 'react-player'
import { Link } from "react-router-dom";

export default function VideoPlayer(props) {
    return (
        <>
            <>
                <div className="w-full flex flex-wrap pt-8">
                    <div className="w-full lg:w-3/12" >
                        <div
                            className="w-full mb-2 mt-6 p-2">
                            { props.title && (
                                <div className="w-full">
                                    <h1 className="font-semibold text-left text-2xl text-left">
                                        {props.title}
                                    </h1>
                                </div>
                            )}
                            { props.subtitle && (
                                <div className="w-full">
                                    <h1 className="text-left text-xl text-left">
                                        {props.subtitle}
                                    </h1>
                                </div>
                            )}
                            { props.description && (
                                <div className="w-full text-left mt-2">
                                    <p>{props.description}</p>
                                </div>
                            )}
                            { props.viewAllLink && (
                                <div className="w-full text-left">
                                    <Link to={props.viewAllLink}>
                                        <button
                                            className="bg-indigo-500 p-2 mt-3 text-white active:bg-indigo-600 text-xs font-bold uppercase px-6 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button">Have a Look</button>
                                    </Link>
                                </div>
                            )}

                        </div>
                    </div>
                    <div className="w-full lg:w-9/12 ">
                        <div
                            className="mb-2 p-3 w-full">
                            {props.data!=null && props.data[0] != null && (
                                <ReactPlayer width='100%' url={props.data[0].mediaLink} light={true} controls={true}/>
                            )}


                        </div>
                    </div>
                </div>
            </>
        </>
    );
}