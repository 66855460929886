import {React,useState, useEffect} from "react";
import {Modal} from "react-responsive-modal";
import * as Constants from "../constants/constants";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {sendPostRequestAll} from "../helpers/httprequesthelper";
import {HttpStatusCode} from "axios";

export default function ProfileUpdateComponent(props) {
    const [errorMessage, setErrorMessage] = useState(null);
    const [userGender, setUserGender] = useState(null);
    const [name, setName] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [area, setArea] = useState(null);
    const [age, setAge] = useState(null);
    const [phoneNumberVerified, setPhoneNumberVerified] = useState(false);
    const [nameMessage, setNameMessage] = useState(null);
    const [ageMessage, setAgeMessage] = useState(null);
    const [phoneNumberMessage, setPhoneNumberMessage] = useState(null);

    function hideErrorMessage() {
        setErrorMessage(null);
    }

   useEffect(() => {
       if (props.data) {
           if (props.data.userGender) {

               setUserGender({"value":props.data.userGender,"label":props.data.userGender});
           }
           if (props.data.area) {
               setArea({"value":props.data.area,"label":props.data.area});
           }
       }
   }, []);


    async function updateProfile(data) {
        if (data == null) {
            setErrorMessage("Data is null or empty");
            return;
        }
        try {
            var response = await sendPostRequestAll(Constants.backendUrl + "/user/myprofile", data, true);
            if (response.status == HttpStatusCode.Ok) {
                props.fetchUserProfile();
                props.onCloseUpdateProfileModal();
            }
        } catch (err) {
            console.log("Error in updateProfile : " + err.response.data.toString());
            setErrorMessage(err.response.data);
        }
    };

    async function handleSubmit(e) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        var formJson = Object.fromEntries(formData.entries());
        setErrorMessage(null);
        setNameMessage(null);
        setAgeMessage(null);
        setPhoneNumberMessage(null);
        console.log("formJson : " + JSON.stringify(formJson));
        if (!formJson.age || formJson.age < 0 || formJson.age > 100) {
            setAgeMessage("Age should be between 1-100");
            return;
        }
        if (!formJson.name) {
            setNameMessage("Name should can't be empty");
            return;
        }
        if (!formJson.phoneNumber) {
            setPhoneNumberMessage("Phone Number can't be empty");
            return;
        }
        if (!area) {
            setErrorMessage("Area is null");
            return;
        }
        if (!userGender) {
            setErrorMessage("User gender is null");
            return;
        }
        formJson.userGender = userGender.value;
        formJson.area = area.value;
        console.log("aaa : " + JSON.stringify(formJson))
        updateProfile(formJson);
    }

    var nameDefault = null;
    var phoneNumberDefault = null
    var ageDefault = null;

    if (props.data) {

        if (props.data.name) {
            nameDefault = props.data.name;
        }
        if (props.data.phoneNumber) {
            phoneNumberDefault = props.data.phoneNumber;
        }
        if (props.data.age) {
            ageDefault = props.data.age;
        }

    }

    return (
        <>
            <Modal open={props.openUpdateProfile} onClose={props.onCloseUpdateProfileModal} center>
                <h2 className="text-xl mb-4 font-bold border-b pb-2 pr-12 addAddressModal">Update Profile</h2>

                { errorMessage!= null && (
                    <div className="text-white px-6 py-3 border-0 rounded relative mb-4 bg-red-500">

                        <span className="inline-block align-middle mr-8">
                                     {errorMessage}
                                  </span>
                        <button className="absolute bg-transparent text-2xl font-semibold leading-none right-10 top-0 mt-4 mr-6 outline-none focus:outline-none" onClick={()=>hideErrorMessage()}>
                            <span>×</span>
                        </button>
                    </div>
                )}
                <form onSubmit={handleSubmit}>
                    <p className="mt-4 mb-2 text-sm">
                        Your Gender
                    </p>
                    <Dropdown options={Constants.userGender} onChange={setUserGender} value={userGender} placeholder="Select an option" />
                    <p className="mt-4 mb-2 text-sm">
                        Name
                    </p>
                    <input name="name" label='Name' className="field" defaultValue={nameDefault} onChange={e => setName(e.target.value)}
                                                                                              value={name}/>
                    { nameMessage!= null && (
                        <p className="text-red-500 mb-2 text-xs">
                            * {nameMessage}
                        </p>
                    )}
                    <p className="mt-4 mb-2 text-sm">
                        Your Area
                    </p>
                    <Dropdown options={Constants.userAreas} onChange={setArea} value={area} placeholder="Select an option"  />

                    <p className="mt-4 mb-2 text-sm">
                        Age
                    </p>
                    <input
                      type="number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      min="1"
                      max="98"
                      defaultValue={ageDefault}
                      onChange={e => setAge(e.target.value)}
                      name="age" value={age}/>

                    <hr/>
                    <p className="mt-4 mb-2 text-sm">
                        Phone Number (Only Indian Numbers)
                    </p>
                    <input
                      type="number"
                      min="1000000000"
                      max="9999999999"
                      defaultValue={phoneNumberDefault}
                      onChange={e => setPhoneNumber(e.target.value)}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      name="phoneNumber" value={phoneNumber}
                    />
                    { phoneNumberMessage!= null && (
                        <p className="text-red-500 mb-2 text-xs">
                            * {phoneNumberMessage}
                        </p>
                    )}



                    <div className="mt-3 mb-3 flex flex-wrap justify-between">

                        <button
                            type="submit"
                            className="bg-primary text-white font-bold uppercase text-sm px-4 p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        >
                            Update Profile
                        </button>

                    </div>

                    <hr/>

                </form>
            </Modal>
        </>
    );
}