import React, { useEffect, useState } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import { Link, useHistory } from "react-router-dom";
import * as Constants from "constants/constants.js";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, logout } from "helpers/firebase.js";
import * as FormatHelper from "helpers/formathelper.js";
import QRCode from "react-qr-code";
import {sendGetRequestAll} from "../helpers/httprequesthelper";
import {HttpStatusCode} from "axios";
import {getDateFromTimestamp} from "../helpers/formathelper";
import ProfileUpdateComponent from "../components/ProfileUpdateComponent";

import {
  query,
  getDocs,
  collection,
  where,
} from "firebase/firestore";
import {
  helpAndSupportImageUrl,
  myAddressImageUrl, myBachatPointImageUrl,
  myCartImageUrl, myNotificationImageUrl,
  myOrdersImageUrl, myReferImageUrl,
  myWishlistImageUrl
} from "../constants/userAccountViewConstant";

export default function Profile() {
        const [user, loading, error] = useAuthState(auth);
        const [name, setName] = useState("");
        const [email, setEmail] = useState("");
        const [phoneNumber, setPhoneNumber] = useState("");
        const [joiningDate, setJoiningDate] = useState("");
        const navigate = useHistory();
        const [userData, setUserData] = useState(null);
        const [openUpdateProfile, setOpenUpdateProfile] = useState(false);

        function onCloseUpdateProfileModal() {
            setOpenUpdateProfile(false);
        }

        const logoutUser = () => {
            logout();
            window.location.reload();
          }
        function onOpenUpdateProfileModal() {
            setOpenUpdateProfile(true);
        }

        const fetchUserProfile = async () => {
            try {
                var response = await sendGetRequestAll(Constants.backendUrl + "/user/myaccount", true);
                if (response.status == HttpStatusCode.Ok) {
                    setUserData(response.data);
                } else {
                    setUserData(null);
                }
            } catch (err) {
                setUserData(null);
            }
        };

useEffect(() => {
      if (loading) return;
      if (!user) return navigate.push("/");
      fetchUserProfile();
    }, [user, loading]);



  return (
    <>
      <IndexNavbar />
      <main className="profile-page">
        <section className="relative block h-500-px">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url('" + Constants.profileBackgroundImage + "')",
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </section>
        <section className="relative py-16 bg-blueGray-200">
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="px-2">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                    <div className="relative">
                    {userData!= null && userData.mediaLink != null && (
                      <img
                          alt="..."
                          src={userData.mediaLink}
                          className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-12 max-w-150-px"
                        />
                    )}

                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                    <div className="py-6 px-3 mt-32 sm:mt-0">
                      {user && (
                        <QRCode
                            size={256}
                            style={{ height: "200px", maxWidth: "100%", width: "100%" }}
                            value={user.uid}
                            viewBox={`0 0 256 256`}
                        />
                      )}
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-1">
                    <div className="flex justify-center py-4 lg:pt-4 pt-8">
                       { userData && userData.joiningDate && (
                      <div className="mr-4 p-3 text-center">

                        <span className="text-xl font-bold block tracking-wide text-blueGray-600">
                            {console.log(Date.now())}
                          {Math.ceil((Date.now() - userData.joiningDate)/86400000)}
                        </span>

                        <span className="text-sm text-blueGray-400">
                          Since Days
                        </span>

                      </div>
                      )}
                      { userData && userData.userMembershipType && (
                        <div className="lg:mr-4 p-3 text-center">
                        <span className="text-lg font-bold block tracking-wide text-blueGray-600">
                          {userData.userMembershipType}
                        </span>
                        <span className="text-sm text-blueGray-400">
                          Membership
                        </span>
                      </div>
                      )}

                    </div>
                  </div>
                </div>
                <div className="text-center mt-8">
                  {userData!= null && userData.name!=null && (
                    <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                        {userData.name}
                    </h3>
                  )}

                  {userData && userData.email && (
                      <h3 className="text-xl  leading-normal  font-serif mb-2 text-blueGray-500 mb-2">
                          {userData.email}
                      </h3>
                  )}
                  {userData && userData.phoneNumber && (
                      <span className="text-md mt-3 mb-3 font-semibold inline-block py-1 px-2 uppercase rounded text-emerald-600 bg-emerald-200 uppercase last:mr-0 mr-1">
                       {userData.phoneNumber}{"  "} <i className="fas fa-phone"></i>
                      </span>
                  )}

                  {userData && userData.pinCode && (
                      <div className="text-sm leading-normal mt-3 mb-2 text-blueGray-400 font-bold uppercase">
                        <i className="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>{" "}
                        {userData.pinCode} - {userData.area}
                      </div>
                  )}

                  {userData && !userData.phoneNumber && (
                    <button
                        className="bg-emerald-500 mt-4 text-white active:bg-emerald-400 font-bold uppercase text-md px-4 ml-3 p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button" onClick={onOpenUpdateProfileModal}
                    >
                        Update Phone Number
                    </button>
                  )}

                  {openUpdateProfile == true && (
                      <ProfileUpdateComponent openUpdateProfile={openUpdateProfile} onCloseUpdateProfileModal={onCloseUpdateProfileModal} fetchUserProfile={fetchUserProfile} data={userData}/>
                  )}
                </div>
                <div className="mt-10 py-10 border-t border-blueGray-200 text-center">
                  <div className="flex flex-wrap justify-center">
                    <div className="w-full p-2 flex flex-wrap">
                      <div className="lg:w-3/12 md:w-6/12 xs:w-6/12 p-1">
                        <Link to="/myorders">
                          <div className="card p-2 py-4">
                            <div className="flex flex-wrap justify-center">
                              <img src={myOrdersImageUrl} className="max-h-100-px" />
                            </div>
                            <p className="font-semibold text-sm mt-4">My Orders</p>
                          </div>
                        </Link>
                      </div>

                      <div className="lg:w-3/12 md:w-6/12 xs:w-6/12 p-1">
                        <Link to="/myaddresses">
                          <div className="card p-2 py-4">
                            <div className="flex flex-wrap justify-center">
                              <img src={myAddressImageUrl} className="max-h-100-px" />
                            </div>
                            <p className="font-semibold text-sm mt-4">My Addresses</p>
                          </div>
                        </Link>
                      </div>

                      <div className="lg:w-3/12 md:w-6/12 xs:w-6/12 p-1">
                        <Link to="/mywishlist">
                          <div className="card p-2 py-4">
                            <div className="flex flex-wrap justify-center">
                              <img src={myWishlistImageUrl} className="max-h-100-px" />
                            </div>
                            <p className="font-semibold text-sm mt-4">My Wishlist</p>
                          </div>
                        </Link>
                      </div>

                      <div className="lg:w-3/12 md:w-6/12 xs:w-6/12 p-1">
                        <Link to="/mycart">
                          <div className="card p-2 py-4">
                            <div className="flex flex-wrap justify-center">
                              <img src={myCartImageUrl} className="max-h-100-px" />
                            </div>
                            <p className="font-semibold text-sm mt-4">My Cart</p>
                          </div>
                        </Link>
                      </div>

                      <div className="lg:w-3/12 md:w-6/12 xs:w-6/12 p-1">
                        <Link to="/mynotifications">
                          <div className="card p-2 py-4">
                            <div className="flex flex-wrap justify-center">
                              <img src={myNotificationImageUrl} className="max-h-100-px" />
                            </div>
                            <p className="font-semibold text-sm mt-4">My Notifications</p>
                          </div>
                        </Link>
                      </div>

                      <div className="lg:w-3/12 md:w-6/12 xs:w-6/12 p-1">
                        <Link to="/comingsoon">
                          <div className="card p-2 py-4">
                            <div className="flex flex-wrap justify-center">
                              <img src={myBachatPointImageUrl} className="max-h-100-px" />
                            </div>
                            <p className="font-semibold text-sm mt-4">My Bachat Points</p>
                          </div>
                        </Link>
                      </div>

                      <div className="lg:w-3/12 md:w-6/12 xs:w-6/12 p-1">
                        <Link to="/comingsoon">
                          <div className="card p-2 py-4">
                            <div className="flex flex-wrap justify-center">
                              <img src={myReferImageUrl} className="max-h-100-px" />
                            </div>
                            <p className="font-semibold text-sm mt-4">Refer & Earn</p>
                          </div>
                        </Link>
                      </div>

                      <div className="lg:w-3/12 md:w-6/12 xs:w-6/12 p-1">
                        <Link to="/help">
                          <div className="card p-2 py-4">
                            <div className="flex flex-wrap justify-center">
                              <img src={helpAndSupportImageUrl} className="max-h-100-px" />
                            </div>
                            <p className="font-semibold text-sm mt-4">Help & Support</p>
                          </div>
                        </Link>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap justify-center mb-8">
                    <button
                        className="bg-blueGray-100 active:bg-lightBlue-600 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                        type="button"
                        onClick={logoutUser}
                      >
                          Sign Out &nbsp;<i className="fas fa-arrow-right"></i>
                      </button>
                </div>

                <hr/>
                {userData!=null && userData.roles != null && userData.roles.length > 0 && (
                    <div>
                    <h1 className="text-xl ml-3 mt-4 font-bold mb-4">Special Access</h1>
                        <div className="flex flex-wrap justify-center">
                        <div className="w-full p-2 flex flex-wrap">

                        {userData.roles.map(function(item, index) {
                        {console.log(item);}
                             return (<div className="lg:w-3/12 md:w-6/12 xs:w-6/12 p-1" key={index}>
                                 <a href={item.urlLink}>
                                   <div className="card p-2 py-4">
                                     <div className="flex flex-wrap justify-center">
                                       <img src={item.mediaLink} className="max-h-100-px" />
                                     </div>
                                     <p className="font-bold text-lg mt-4 text-center">{item.title}</p>
                                   </div>
                                 </a>
                               </div>
                               );
                         })}

                        </div>
                      </div>
                    </div>
                    )}
              </div>
            </div>
          </div>
        </section>

      </main>
      <Footer />
    </>
  );
}
