import {Link} from "react-router-dom";
import React, {useState, useEffect} from "react";
import * as Constants from "constants/constants.js";
import * as cartViewConstant from "../../constants/cartViewConstant";
import 'react-dropdown/style.css';
import {Modal} from "react-responsive-modal";
import * as FormatHelper from "helpers/formathelper.js";
import {placeOrderSupportNumber} from "constants/constants.js";
import {sendPostRequestAll} from "../../helpers/httprequesthelper";
import {HttpStatusCode} from "axios";

export default function UserCheckoutComponent(props) {
    const [cartMap, setCartMap] = useState(props.data.productsMap);
    const [errorMessage, setErrorMessage] = useState(null);
    const [openErrorModal, setOpenErrorModal] = useState(false);

    const [openPlaceOrderModal, setOpenPlaceOrderModal] = useState(false);
    const onClosePlaceOrderModal = () => setOpenPlaceOrderModal(false);
    const onOpenPlaceOrderModal = () => setOpenPlaceOrderModal(true);

    useEffect(() => {
    });

    function placeOrderConfirm() {
        onClosePlaceOrderModal();
        props.placeOrder();
    }
    function onCloseErrorModal() {
        setOpenErrorModal(false);
        setErrorMessage(null);
    }

    return (
        <div className="w-full" id="root">
            <Modal open={openErrorModal} onClose={onCloseErrorModal} center>
                <h2 className="text-xl mb-4 font-bold border-b pb-2">Error Occurred</h2>
                <p>{errorMessage}</p>
            </Modal>

            <Modal open={openPlaceOrderModal} onClose={onClosePlaceOrderModal} center>
                <h2 className="text-xl mb-4 font-bold border-b pb-2">Confirm Order</h2>
                <p>Do you want to place this order?</p>
                <div className="flex mt-3">
                    <button className="text-white bg-lightBlue-600 font-bold uppercase text-sm p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        onClick={onClosePlaceOrderModal}>No</button>
                    <button className="ml-2 text-white bg-lightBlue-600 font-bold uppercase text-sm p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        onClick={placeOrderConfirm}>Yes</button>
                 </div>
            </Modal>

            <div className="p-2 w-full">
                <div>
                    {cartMap != null && Object.keys(cartMap).map((key, index) => {
                        return (
                            <div key={cartMap[key].id}>
                                {cartMap[key].cartQuantity > 0 && (
                                    <div className="p-1 pb-4 border-b" key={cartMap[key].id}>
                                        <div className="w-full mt-2 flex justify-between">
                                            <div className="flex">
                                                <div>
                                                    <img
                                                        src={cartMap[key].mediaLink}
                                                        className="w-auto h-auto align-middle rounded-t-lg max-h-100-px max-w-100-px"/>
                                                    <p className="mt-2 card p-1 text-center">{cartMap[key].cartQuantity} pcs</p>
                                                </div>
                                                <div className="ml-4">
                                                    <h4 className="product-carousel-title font-bold">{cartMap[key].title}</h4>
                                                    <h4 className="product-carousel-title mt-2">{cartMap[key].subtitle}</h4>
                                                    <p className="mt-3"> <span className="mrp text-sm">{Constants.currency + " " + cartMap[key].mrp * cartMap[key].cartQuantity}</span> <span className="text-lg ml-3 font-bold">{Constants.currency + " " + cartMap[key].sellingPrice * cartMap[key].cartQuantity}</span><span className="text-2xs ml-3 font-semibold inline-block p-1 uppercase rounded text-emerald-600 bg-emerald-200 uppercase mr-1">
                                      {Math.trunc(cartMap[key].discountPercentage)} % off
                                    </span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>);
                    })}

                </div>
            </div>

            <div className="mt-6 w-full flex flex-wrap justify-end">
                <div className="md:w-full lg:w-7/12 p-3 w-full">
                    <div className="mt-4 mb-4 p-3 card">
                        <h3 className="font-bold text-lg uppercase mb-4 text-blueGray-500">
                            Delivery Address
                        </h3>
                        <p>{FormatHelper.getAddressLabelString(props.data.addressInfo)}</p>

                        {props.data.deliveryDate != null && (
                            <p className="mt-4 text-sm font-bold text-emerald-500">Expect delivery by {props.data.deliveryDate}</p>
                        )}

                    </div>
                    <p className="ml-2 text-blueGray-500 text-sm">To adjust the quantities or change address, Please <b><Link to="/mycart" className="text-lightBlue-600">Click here</Link></b></p>
                </div>
                <div className="md:w-full lg:w-5/12 w-full p-3 mt-2">
                    {props.data.totalMrp != null && props.data.totalMrp > 0 && (
                        <div className="flex justify-between mt-2">
                            <h1 className="ml-3">MRP ({props.data.totalQuantity + " items"})</h1>
                            <p>{Constants.currency + " " + props.data.totalMrp}</p>
                        </div>

                    )}
                    {props.data.totalSavings != null && props.data.totalSavings > 0 && (
                        <div className="flex justify-between mt-2">
                            <h1 className="text-md ml-3 font-green">
                                Total Discount
                            </h1>
                            <p className="font-green">{" - " + Constants.currency + " " + props.data.totalSavings}</p>
                        </div>
                    )}
                    {props.data.totalAmount != null && props.data.totalAmount > 0 && (
                        <div className="flex justify-between mt-2">
                            <h1 className="ml-3 font-bold">Total Price</h1>
                            <p className="font-bold">{Constants.currency + " " + props.data.totalAmount}</p>

                        </div>

                    )}

                    {props.data.totalQuantity != null && props.data.totalQuantity > 0 && (
                        <div className="mt-4 mb-4 flex justify-end">
                            <button
                                className="bg-primary text-white active:bg-lightBlue-600 font-bold uppercase text-md px-4 p-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                                type="submit"
                                onClick={onOpenPlaceOrderModal}
                            >
                                <i className="fa fa-plus-circle" aria-hidden="true"></i> &nbsp;&nbsp; Place Order
                            </button>
                        </div>
                    )}
                </div>

            </div>

            <hr/>
            {props.data.totalSavings !=null && props.data.totalSavings > 0 && (
                <div className="mt-8 flex w-full justify-center mb-3">
                    <p className="text-center">Congratulations 🥳, You are saving {Constants.currency + " " + props.data.totalSavings} with this order </p>
                </div>
            )}


            <div className="mt-8 mb-3 flex w-full justify-center">
                <div className="p-4 card pb-6 xs:w-10/12 lg:w-9/12 ">
                    <div>
                        <p className="text-md text-center">
                            Need Help in placing the order ?
                        </p>
                    </div>
                    <div className="flex flex-wrap justify-center">
                        <div className="sm:block mt-10">
                          <a
                            href={"tel:+91" + Constants.placeOrderSupportNumber}
                            target="_blank"
                            className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                          >
                            Call Us &nbsp;&nbsp; <i className="fas fa-phone"></i>
                          </a>
                          <a
                            href={"https://wa.me/" + Constants.placeOrderSupportNumber}
                            target="_blank"
                            className="github-star sm:ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-teal-500  active:bg-blueGray-200 uppercase text-sm shadow hover:shadow-lg"
                          >
                            Whatsapp&nbsp;&nbsp; <i className="fab fa-whatsapp"></i>
                          </a>
                        </div>
                    </div>
                </div>

            </div>







        </div>
    );
}