import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import React, {useEffect, useState, useMemo} from "react";
import {sendGetRequestAll, sendPostRequestAll} from "../../helpers/httprequesthelper";
import { Link, useHistory } from "react-router-dom";
import Carousel from "react-multi-carousel";
import Rating from 'react-rating';
import { MaterialReactTable } from 'material-react-table';
import * as productViewImagesUrl from "constants/productViewGeneralImagesConstant.js";
import ReactPlayer from "react-player";
import moment from 'moment';
import SideCarousel from "../../components/Carousel/SideCarousel";
import HeadCarousel from "../../components/Carousel/HeadCarousel";
import * as Constants from "../../constants/constants";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "helpers/firebase.js";
import Loader from "components/Loader/Loader.js"

export default function ProductView(props) {
    const [data, setData] = useState(null);
    const [productData, setProductData] = useState([]);
    const [message, setMessage] = useState(null);
    const [addedToCart, setAddedToCart] = useState(false);
    const [user, loading, error] = useAuthState(auth);
    const [hideAddToCartButton, setHideAddToCartButton] = useState(false);
    const fetchData = async (requireAuth) => {
        try {
            var response = await sendGetRequestAll(Constants.backendUrl + "/product/"+props.match.params.pid, requireAuth);
            setData(response.data);
            if (!requireAuth) {
                setHideAddToCartButton(true);
            }
            createProductDetailsObject(response.data);
        } catch (err) {
            console.error(err);
            alert("An error occured while loading product.");
            // return navigate.push("/");
        }
    };

    function hideMessage() {
        setMessage(null);
    }

    async function addToCart (productId) {
        var data = {
            "productId" : productId,
            "quantity" : 1
        }
        try {
            var response = await sendPostRequestAll(Constants.backendUrl + "/mycart/product/add", data, true);
            if (response.data.message != null) {
                setMessage(response.data.message);
            }
            setAddedToCart(true);
            console.log("Here the response : ");
            console.log(response);
        } catch (err) {
            console.log("Error in adjustQuantity : ");
            console.error(err);
        }
    };

    const createProductDetailsObject = (data) => {
        if (data == null) {
            return;
        }
        var productData = [];
        if (data.brandInfo!=null && data.brandInfo.title != null) {
            productData.push({"name" : "Brand", "value" : data.brandInfo.title});
        }
        if (data.merchantInfo != null && data.merchantInfo.name != null) {
            productData.push({"name" : "Seller", "value" : data.merchantInfo.name});
        }
        if (data.categoryInfo.title != null) {
            productData.push({"name" : "Category", "value" : data.categoryInfo.title});
        }
        if (data.categoryInfo.categoryType != null) {
            productData.push({"name" : "Category Type", "value" : data.categoryInfo.categoryType});
        }
        if (data.tags != null) {
            for (const [key, value] of Object.entries(data.tags)) {
                if (key && value) {
                    productData.push({"name" : key, "value" : value});
                }
            }
        }
        try {
            if (data.creationDate != null) {
                var t = new Date(data.creationDate);
                var formatted = moment(t).format("DD - MM - yyyy");
                productData.push({"name": "Added on", "value": formatted});
            }
        } catch (err) {
            console.log("Error : " + err);
        }
        setProductData(productData);
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: 'name', //access nested data with dot notation
                size: 50
            },
            {
                accessorKey: 'value',
                size: 150
            }
        ],
        [],
    );

    const responsive = {
        mobile: {
            breakpoint: { max: 4000, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    useEffect(() => {
        if (loading) return;
        if (!user) return fetchData(false);
        fetchData(true);
    }, [loading, user]);

    return (
        <>
            <IndexNavbar fixed />
            {!data && (
                       <Loader data={data}/>
                        )}

            {data && (
            <>
            <section className="relative block h-400-px">
                <div
                    className="absolute top-0 w-full h-full bg-center bg-cover"
                    style={{
                        backgroundImage: "url(" + Constants.productViewBackgroundImage + ")",
                    }}
                >
            <span
                id="blackOverlay"
                className="w-full h-full absolute opacity-25 bg-black"
            ></span>
                </div>
                <div
                    className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                    style={{ transform: "translateZ(0)" }}
                >
                    <svg
                        className="absolute bottom-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="text-blueGray-200 fill-current"
                            points="2560 0 2560 100 0 100"
                        ></polygon>
                    </svg>
                </div>
            </section>
            <section className="relative py-16 bg-blueGray-200">
            <div className="container mx-auto px-2">
                <div className="relative p-2 flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-68">
                <section className="flex flex-wrap">
                    <div className="w-full">
                        { message!= null && (
                            <div className="text-white px-6 py-3 border-0 rounded relative mb-4 bg-emerald-500">
                                  <span className="text-xl inline-block mr-5 align-middle">
                                    <i className="fas fa-bell"></i>
                                  </span>
                                                <span className="inline-block align-middle mr-8">
                                     {message}
                                  </span>
                                  <button className="absolute bg-transparent text-2xl font-semibold leading-none right-10 top-0 mt-4 mr-6 outline-none focus:outline-none" onClick={()=>hideMessage()}>
                                      <span>×</span>
                                  </button>
                            </div>
                        )}
                        <div className="xl:w-5/12 w-full float-left p-2">
                            <Carousel autoPlay="true" interval="2000" swipeable={true}
                                      draggable={true} responsive={responsive} infinite={true} showThumbs={false} showDots={true} focusOnSelect={true}>
                                {data && data.videoLinks!= null && data.videoLinks.map(function(videoLink, index){
                                    return (
                                        <div>
                                            <ReactPlayer width='100%' height='300px' url={videoLink} light={true} controls={true}/>
                                        </div>
                                    );
                                })}
                                {data && data.mediaLinks!= null && data.mediaLinks.map(function(mediaLink, index){
                                    return (
                                        <div className="p-1">
                                            <img
                                                src={mediaLink}
                                                className="w-full align-middle rounded-t-lg max-h-400-px"/>
                                        </div>
                                    );
                                })}

                            </Carousel>
                        </div>
                        <div className="xl:w-7/12 w-full float-left p-2">
                            {data.title != null && (
                                <h4 className="product-view-title mt-2">{data.title}</h4>
                            )}
                            {data.title != null && (
                                <h4 className="product-view-subtitle mt-2">{data.subtitle}</h4>
                            )}

                            {data.starRating != null && (
                                <div className="w-full flex flex-wrap mt-4">
                                    <Rating initialRating={data.starRating} emptySymbol={<i className="fas fa-star rating-star-empty text-xl"></i>} fullSymbol={<i className="fas fa-star rating-star-filled text-xl"></i>} readonly={true}/>
                                    <div className="p-2"><p>&nbsp;&nbsp;&nbsp;&nbsp;{data.starRating} out of 5 star</p></div>
                                </div>
                            )}

                            <div className="w-full flex flex-wrap mt-4">
                                {data.sellingPrice != null && (
                                    <div className="float-left">
                                        <h4 className="product-view-selling-price mt-2">{Constants.currency + " " + data.sellingPrice}&nbsp;&nbsp;</h4>
                                    </div>
                                )}
                                {data.mrp != null && (
                                    <div className="float-left">
                                        <h4 className="product-view-mrp mrp mt-4">{Constants.currency + " " + data.mrp}</h4>
                                    </div>
                                )}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {data.discountPercentage != null && (
                                    <div className="float-left">
                                        <span className="text-xs mt-4 font-semibold inline-block py-1 px-2 uppercase rounded text-emerald-600 bg-emerald-200 uppercase last:mr-0 mr-1">
                                          {Math.trunc(data.discountPercentage)} % off
                                        </span>
                                    </div>
                                )}
                            </div>

                            <div className="w-full flex flex-wrap mt-4">
                                <div className="md:w-6/12 xs:w-6/12 w-full float-left p-2">
                                    {data!= null && data.isAvailable && !hideAddToCartButton && (
                                        <Link
                                            to={"/checkout?productId=" + data.id}
                                        >
                                            <button
                                                className="bg-primary w-full text-white active:bg-lightBlue-600 font-bold uppercase text-md p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                type="button"
                                            >
                                                <i className="fa fa-plus-circle" aria-hidden="true"></i> &nbsp;&nbsp; Buy Now
                                            </button>
                                        </Link>
                                    )}

                                    {hideAddToCartButton && (
                                        <a
                                            href="/auth/login"
                                        >
                                            <button
                                                className="bg-primary w-full text-white active:bg-lightBlue-600 font-bold uppercase text-md p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                type="button"
                                            >
                                                <i className="fas fa-users" aria-hidden="true"></i> &nbsp;&nbsp; Login to Buy
                                            </button>
                                        </a>
                                    )}


                                </div>
                                <div className="md:w-6/12 xs:w-6/12 w-full float-left p-2">
                                    {!addedToCart && data.isAvailable && !hideAddToCartButton && (
                                        <button
                                            className="bg-orange-500 w-full text-white active:bg-lightBlue-600 font-bold uppercase text-md p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => addToCart(data.id)}
                                        >
                                            <i className="fa fa-shopping-cart" aria-hidden="true"></i>&nbsp;&nbsp; Add To Cart
                                        </button>
                                    )}

                                    {addedToCart && !hideAddToCartButton && (
                                        <Link
                                            to="/mycart"
                                        >
                                            <button
                                                className="bg-orange-500 w-full text-white active:bg-lightBlue-600 font-bold uppercase text-md p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                type="button"
                                            >
                                                <i className="fa fa-shopping-cart" aria-hidden="true"></i>&nbsp;&nbsp; Go To Cart
                                            </button>
                                        </Link>
                                    )}



                                </div>
                            </div>

                            {data && data.comingSoon && (
                                <p className="text-lg font-bold">Coming Soon</p>
                            )}

                            {data && !data.isAvailable && (
                                <p className="text-lg font-bold">Product Unavailable</p>
                            )}

                            <div className="w-full flex flex-wrap mt-4">
                                { data.freeDeliveryEligible != null && data.freeDeliveryEligible == true && (
                                    <div className="ml-3">
                                        <img src={productViewImagesUrl.freedeliveryImageUrl} className="product-view-additional-image-highlight" />
                                    </div>
                                )}
                                { data.brandInfo != null && data.brandInfo.starRating != null && data.brandInfo.starRating > 4 && (
                                    <div className="ml-3">
                                        <img src={productViewImagesUrl.topbrandImageUrl} className="product-view-additional-image-highlight" />
                                    </div>
                                )}
                                { data.original != null && data.original == true && (
                                    <div className="ml-3">
                                        <img src={productViewImagesUrl.originalImageUrl} className="product-view-additional-image-highlight" />
                                    </div>
                                )}
                            </div>

                            {data != null && data.merchantInfo!= null && (
                                <div className="w-full card p-3  mt-4">
                                    <div className="w-full flex flex-wrap">
                                        <div className="w-3/12">
                                            {data.merchantInfo.logoUrl != null && (
                                                <img src={data.merchantInfo.logoUrl} className="max-h-150-px" />
                                            )}
                                        </div>
                                        <div className="w-9/12">
                                            <p className="mb-2">This Product is Sold By</p>
                                            {data.merchantInfo.name != null && (
                                                <h4 className="product-view-merchant-name uppercase">
                                                    {data.merchantInfo.name}
                                                </h4>
                                            )}
                                            {data.merchantInfo.address != null && (
                                                <h4 className="product-view-merchant-address mt-2">
                                                    <i className="fas fa-location-arrow"></i>&nbsp;&nbsp; {data.merchantInfo.address}
                                                </h4>
                                            )}
                                            {data.merchantInfo.merchantUrl != null && (
                                                <Link to={data.merchantInfo.merchantUrl}>
                                                    <button
                                                        className="mt-3 bg-emerald-400 text-white active:bg-emerald-500 uppercase text-sm px-4 p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                        type="button"
                                                    >
                                                         View Seller &nbsp;&nbsp; <i className="fas fa-arrow-right" aria-hidden="true"></i>
                                                    </button>
                                                </Link>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {data != null && (data.bulletPoints!= null && data.bulletPoints.length > 0) && (

                                <div className="w-full mt-6">
                                    <h4 className="product-view-highlight-title mt-4">Product Highlights</h4>
                                    <div className="mt-2">
                                        {data.bulletPoints.map(point => (
                                            <li className="mt-2">
                                                {point}
                                            </li>
                                        ))}
                                    </div>
                                </div>

                            )}
                        </div>
                        <div className="p-1">
                            { productData!= null && productData.length > 0 && (

                                <div className="w-full mt-8">
                                    <h4 className="product-view-highlight-title ">Product Details</h4>
                                    <div className="mt-2">
                                        <MaterialReactTable columns={columns} data={productData}
                                                            enableColumnActions={false}
                                                            enableColumnFilters={false}
                                                            enablePagination={false}
                                                            enableSorting={false}
                                                            enableBottomToolbar={false}
                                                            enableTopToolbar={false}
                                                            muiTableBodyRowProps={{ hover: false }}/>
                                    </div>
                                </div>

                            )}


                            { data!= null && (

                                <div className="w-full mt-8">
                                    <h4 className="product-view-highlight-title ">Product Description</h4>
                                    <div className="mt-2">
                                        <p>{data.description}</p>
                                    </div>
                                </div>

                            )}

                            {data != null && data.brandInfo!= null && (
                                <div className="mt-4">
                                    <HeadCarousel
                                        data={data.brandProducts}
                                        title={data.brandInfo.title}
                                        mediaLink={data.brandInfo.mediaLink}
                                        starRating={data.brandInfo.starRating}
                                        description="More from Brand!!"
                                        viewAllLink={data.brandInfo.urlLink}/>
                                </div>
                            )}

                            {data != null && data.categoryInfo!= null && (
                                <div className="mt-4">
                                    <HeadCarousel
                                        data={data.categoryProducts}
                                        title={data.categoryInfo.title}
                                        starRating={data.categoryInfo.starRating}
                                        mediaLink={data.categoryInfo.mediaLink}
                                        description="Explore Extra!!"
                                        viewAllLink={data.categoryInfo.urlLink}/>
                                </div>
                            )}

                        </div>
                    </div>

                </section>
                </div>
            </div>
            </section>

            </>
            )}
<Footer />
        </>
    );
}