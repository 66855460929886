import React from "react";
import { Link } from "react-router-dom";

// components
import IndexNavbar from "components/Navbars/IndexNavbar";
import Footer from "components/Footers/Footer.js";
import * as Constants from "constants/constants";

export default function Contact() {
  return (
    <>
      <IndexNavbar />
      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url('" + Constants.contactUsBackgroundImage + "')",
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-25 bg-black"
            ></span>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="pr-12">
                  <h1 className="text-white font-semibold text-5xl">
                    Contact Us
                  </h1>
                  <p className="mt-4 text-lg text-blueGray-200">
                    {Constants.contactUsSubtitle}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        <section className="pb-20 bg-blueGray-200 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i className="fas fa-award"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Store Phone Number</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      Call us for placing order on phone or any store related query</p>
                      <a href={Constants.adarshNagarStoreTelUrl}><button className="btn-outline btn-default-outline text-sm mt-2">Call Store</button></a>

                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400">
                      <i className="fas fa-retweet"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Visit us Offline!</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                        Address : D-41, Street No 6, Mandir road, Adarsh Nagar Main Market, Delhi - 110033 </p>
                                              <a href={Constants.rangMartGoogleMapsLocation}><button className="btn-outline btn-default-outline text-sm mt-2">View On Maps</button></a>


                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400">
                      <i className="fas fa-fingerprint"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Whatsapp Us</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      Message us on whatsapp by clicking below and we will be there to help you</p>
                      <a href={Constants.adarshNagarStoreWhatsappUrl}><button className="btn-outline btn-default-outline text-sm mt-2">Whatsapp</button></a>

                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap items-center mt-32">
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                  <i className="fas fa-user-friends text-xl"></i>
                </div>
                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                  Want to advertise with us ?
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                  We can bring visibility to your business in Adarsh Nagar.
                </p>
                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                    Feel free to contact our advertisement team on whatsapp below. We will get back to you as soon as possible.
                </p>
                <a href={Constants.advertiseWhatsappUrl} className="font-bold text-blueGray-700 mt-8 mb-4">
                  <i className="fab fa-whatsapp"></i>  &nbsp; &nbsp; Whatsapp Us
                </a>
              </div>

              <div className="w-full md:w-4/12 mt-4 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-primary">
                  <img
                    alt="..."
                    src={Constants.contactUsAdvertiseImage}
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative p-8 mb-4">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 583 95"
                      className="absolute left-0 w-full block h-95-px -top-94-px"
                    >
                      <polygon
                        points="-30,95 583,95 583,65"
                        className="text-lightBlue-500 fill-current"
                      ></polygon>
                    </svg>
                    <h4 className="text-xl font-bold text-white">
                      Experienced Team
                    </h4>
                    <p className="text-md font-light mt-2 text-white">
                       Our marketing and advertisement team work is highly experienced to bring more customers for your business. Reach new heights with us.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </section>



        <section className="pt-20 pb-48">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center text-center mb-24">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold">Here are our stores</h2>
                <p className="text-lg leading-relaxed m-4 text-blueGray-500">
                  We have below stores open to serve you for your needs. We would be bringing more innovative stores soon.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full md:w-6/12 lg:w-6/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={Constants.adarshNagarStoreImage}
                    className="shadow-lg rounded-full mx-auto max-w-120-px"
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Adarsh Nagar Store (Online)</h5>
                    <a href="/store">
                        <p className="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                          www.myadarshnagar.com/store
                        </p>
                    </a>
                    <div className="mt-6">
                      <button
                        className="bg-emerald-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                      >
                        <i className="fab fa-whatsapp"></i>
                      </button>
                      <button
                        className="bg-lightBlue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </button>
                      <button
                      className="bg-blueGray-700 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i className="fab fa-instagram"></i>
                    </button>

                    </div>
                    <button
                      className="bg-primary rounded mt-3 p-2 text-white outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      Visit Store &nbsp;<i className="fas fa-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-6/12 lg:w-6/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={Constants.rangMartImage}
                    className="shadow-lg rounded-full mx-auto max-w-120-px"
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Rang Mart Store (Offline)</h5>
                    <p className="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                      D-41, Street No 6, Main market mandir road, Adarsh Nagar
                    </p>
                    <div className="mt-6">
                      <button
                        className="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                      >
                        <i className="fas fa-map-marked"></i>
                      </button>
                      <button
                        className="bg-lightBlue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </button>
                      <a href="https://wa.me/+919718199993">
                      <button
                         className="bg-emerald-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                         type="button"
                       >
                         <i className="fab fa-whatsapp"></i>
                      </button>
                      </a>
                      <a href="https://www.instagram.com/rang_mart/">
                      <button
                          className="bg-blueGray-700 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                          type="button"
                        >
                          <i className="fab fa-instagram"></i>
                      </button>
                      </a>
                      <a href={"tel:+919718199993"}>
                        <button
                            className="bg-black text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                          >
                            <i className="fas fa-phone"></i>
                        </button>
                      </a>
                    </div>
                        <button
                          className="bg-primary rounded mt-3 p-2 text-white outline-none focus:outline-none mr-1 mb-1"
                          type="button"
                        >
                          Visit Store &nbsp;<i className="fas fa-arrow-right"></i>
                        </button>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </section>

      </main>
      <Footer />
    </>
  );
}
