import React, {useState } from "react";
import {Link} from "react-router-dom";
import * as Constants from "../../constants/constants";
import {sendPostRequestAll} from "../../helpers/httprequesthelper";
import {HttpStatusCode} from "axios";
import * as wishlistViewConstant from "../../constants/wishlistViewConstant";

export default function UserWishlist(props) {
    const [wishlistMap, setWishlistMap] = useState(props.data.productsMap);
    const [message, setMessage] = useState(null);

    function hideMessage() {
        setMessage(null);
    }

    async function deleteFromWishlist(productId) {
        var data = {
            "productId" : productId,
            "quantity" : 0
        }
        try {
            var response = await sendPostRequestAll(Constants.backendUrl + "/mywishlist/product/remove", data, true);
            if (response.status == HttpStatusCode.Ok) {
                if (response.data.quantity <= 0) {
                    delete wishlistMap[response.data.productId];
                }
                if (response.data.message != null) {
                    setMessage(response.data.message);
                    setTimeout(hideMessage, 3000);
                }
            }
            console.log("Here the response : ");
            console.log(response);
        } catch (err) {
            console.log("Error in deleteFromWishlist : ");
            console.error(err);
        }
    }

    async function moveToCart (productId) {
        var data = {
            "productId" : productId,
            "quantity" : 1
        }
        try {
            var response = await sendPostRequestAll(Constants.backendUrl + "/mywishlist/movetocart", data, true);

            if (response.status == HttpStatusCode.Ok) {
                delete wishlistMap[response.data.productId];
                if (response.data.message != null) {
                    setMessage(response.data.message);
                    setTimeout(hideMessage, 3000);
                }
            }
            console.log("Here the response : ");
            console.log(response);
        } catch (err) {
            console.log("Error in adjustQuantity : ");
            console.error(err);
        }
    };

    return (
        <div className="w-full">
            { message!= null && (
                <div className="text-white px-6 py-3 border-0 rounded relative mb-4 bg-emerald-500">
                  <span className="text-xl inline-block mr-5 align-middle">
                    <i className="fas fa-bell"></i>
                  </span>
                    <span className="inline-block align-middle mr-8">
                     {message}
                  </span>
                    <button className="absolute bg-transparent text-2xl font-semibold leading-none right-10 top-0 mt-4 mr-6 outline-none focus:outline-none" onClick={()=>hideMessage()}>
                        <span>×</span>
                    </button>
                </div>
            )}


            <div className="p-2 w-full">
                <div>
                    {wishlistMap != null && Object.keys(wishlistMap).map((key, index) => {
                        return (
                            <div className="p-2 border-b">
                                <div className="w-full mt-2 flex justify-between">
                                    <div className="flex">
                                        <div>
                                            <Link to={wishlistMap[key].urlLink}>
                                                <img
                                                    src={wishlistMap[key].mediaLink}
                                                    className="w-auto h-auto align-middle rounded-t-lg max-h-100-px max-w-100-px"/>
                                            </Link>
                                        </div>
                                        <div className="ml-4">
                                            <h4 className="product-carousel-title font-bold mt-2">{wishlistMap[key].title}</h4>
                                            <h4 className="product-carousel-title mt-2">{wishlistMap[key].subtitle}</h4>
                                            <p className="mt-3"> <span className="mrp text-sm">{Constants.currency + " " + wishlistMap[key].mrp}</span> <span className="text-lg ml-3 font-bold">{Constants.currency + " " + wishlistMap[key].sellingPrice}</span><span className="text-2xs ml-3 font-semibold inline-block p-1 uppercase rounded text-emerald-600 bg-emerald-200 uppercase mr-1">
                                      {Math.trunc(wishlistMap[key].discountPercentage)} % off
                                    </span></p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="flex mt-3">
                                        <div>

                                            <button
                                                className="cursor-pointer font-bold text-md mt-2 px-2 py-0 text-md ml-3"
                                                type="button"
                                                onClick={() => deleteFromWishlist(key)}
                                            >
                                                Remove
                                            </button>
                                            <button
                                                className="cursor-pointer font-bold text-md mt-2 ml-2 px-2 py-0 text-md "
                                                type="button"
                                                onClick={() => moveToCart(key)}
                                            >
                                                Move to Cart
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                </div>
                {(!wishlistMap|| Object.keys(wishlistMap).length == 0) && (
                    <div className="w-full ">
                        <div className="flex flex-wrap justify-center">
                            <img src={wishlistViewConstant.emptyWishlistImageUrl} className="cart-view-empty-cart" />
                        </div>
                        <div>
                            <p className="text-center font-bold text-xl">Your Wishlist is empty !!</p>
                            <p className="text-center text-lg">Add your stuff here to save for later.</p>
                        </div>
                        <div className="flex flex-wrap justify-center mt-6">
                            <Link to="/store">
                                <button
                                    className="bg-primary text-white active:bg-lightBlue-600 font-bold uppercase text-md px-4 p-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                    type="button"
                                >
                                    <i className="fa-solid fa-store"></i> &nbsp;&nbsp; Visit Store
                                </button>
                            </Link>
                        </div>

                    </div>
                )}
            </div>
            <div className="mt-4 flex w-full justify-center ">
                <Link to="/mycart">
                    <button
                        className="active:bg-lightBlue-600 font-bold uppercase text-sm px-4 p-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button"
                    >
                        Go To Cart
                    </button>
                </Link>
            </div>


        </div>
    );
}