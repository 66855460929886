import {React,useState,useEffect} from "react";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {sendGetRequestAll, sendPostRequestAll, sendDeleteRequestAll} from "../../helpers/httprequesthelper";
import * as Constants from "constants/constants.js";
import {Modal} from "react-responsive-modal";
import {HttpStatusCode} from "axios";
import Multiselect from 'multiselect-react-dropdown';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "helpers/firebase.js";
import {useHistory} from "react-router-dom";

export default function UpdateBrand() {
    const [showForm, setShowForm] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [title, setTitle] = useState(null);
    const [selectedPlacardIds, setSelectedPlacardIds] = useState(null);
    const [id, setId] = useState(null);
    const [data, setData] = useState(null);
    const [searchId, setSearchId] = useState(null);
    const [description, setDescription] = useState(null);
    const [placardDataOptions, setPlacardDataOptions] = useState(null);
    const [mediaLink, setMediaLink] = useState(null);
    const [shouldShowChecked, setShouldShowChecked] = useState(true);
    const [openDeleteBrandModal, setOpenDeleteBrandModal] = useState(false);
    const [starRating, setStarRating] = useState(null);
    const [user, loading, error] = useAuthState(auth);

    const navigate = useHistory();

    async function deleteBrand() {
        try {
            console.log("id ==== "  + id);
            var response = await sendDeleteRequestAll(Constants.backendUrl + "/admin/brand/delete/" + id, {}, true);

            if (response.status == HttpStatusCode.Ok) {
                if (response.data != null) {
                    setOpenDeleteBrandModal(false);
                    setSuccessMessage("Delete successful");
                    setOpenSuccessModal(true);
                    console.log(response.data);
                    setData(null);
                    setShowForm(false);
                }
            } else {
                setErrorMessage(response.data);
                setOpenErrorModal(true);
            }
        } catch (err) {
            console.log("Error in deleting the brand data : ");
            console.error(err);
            setOpenErrorModal(true);
            setErrorMessage(err.response.data);
        }
    }

    function onCloseErrorModal() {
        setOpenErrorModal(false);
        setErrorMessage(null);
    }

    function onCloseSuccessModal() {
        setOpenSuccessModal(false);
        setSuccessMessage(null);
    }

    const onOpenDeleteBrandModal = () => setOpenDeleteBrandModal(true);

    async function handleSearch(e) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        var formJson = Object.fromEntries(formData.entries());
        if(!formJson.searchId || !formJson.searchId.trim()) {
            setErrorMessage("Search Id is empty");
            setOpenErrorModal(true);
            return;
        }
        console.log("formJson");
        console.log(formJson)
        await fetchBrandDataById(formJson.searchId);
    }

    function onCloseDeleteBrandModal() {
        setOpenDeleteBrandModal(false);
    }

    async function fetchBrandDataById(searchId) {
        try {
            var response = await sendGetRequestAll(Constants.backendUrl + "/admin/brand/" + searchId, true);

            if (response.status == HttpStatusCode.Ok) {
                if (response.data) {
                    console.log("Hello : " + JSON.stringify(response.data));
                    await updateFormData(response.data);
                } else {
                    setShowForm(false);
                }
            } else {
                setErrorMessage(response.data);
                setOpenErrorModal(true);
            }
            console.log("Here the response : ");
            console.log(response);
        } catch (err) {
            console.log("Error in getting the product data : ");
            console.error(err);
            setOpenErrorModal(true);
            setErrorMessage(err.response.data);
        }
    }

    async function updateFormData(data) {
        console.log("updateFormData : " + JSON.stringify(data));
        setId(data.id);
        setTitle(data.title);
        setDescription(data.description);
        setShouldShowChecked(data.shouldShow);
        setMediaLink(data.mediaLink);
        setStarRating(data.starRating);
        if (data.plaCardIds) {
            setSelectedPlacardIds(getMultiBrandsOptionFromArray(data.plaCardIds));
        }
        setShowForm(true);
        setData(data);
    }

    function getArrayFromSelectedMultipleOptions(data) {
        var arr = [];
        if (!data) {
            return null;
        }
        data.forEach(function(item, index) {
            arr.push(item.id);
        })
        return arr;
    }

    function selectPlacardIds(selectedList, selectedItem) {
        setSelectedPlacardIds(selectedList);
    }

    async function fetchPlacardData() {
        try {
            var response = await sendGetRequestAll(Constants.backendUrl + "/admin/placard", true);
            if (response.data != null) {
                setPlacardDataOptions(getMultiPlacardOption(response.data));
            }
        } catch (err) {
            console.error(err);
            console.error("Error Occurred while fetchPlacardData");
        }
    };

    function getMultiPlacardOption(data) {
        var placardOptions = [];
        if (data != null) {
            data.forEach(function (item, index) {
              placardOptions.push({name : item.id, id : item.id})
            });
        }
        return placardOptions;
    }

     function getMultiBrandsOptionFromArray(data) {
        var categoriesOption = [];
        if (data != null) {
            data.forEach(function (id, index) {
              categoriesOption.push({name : id, id : id})
            });
        }
        return categoriesOption;
    }

    function getMultiPlacardOption(data) {
        var placardOptions = [];
        if (data != null) {
            data.forEach(function (item, index) {
              placardOptions.push({name : item.id, id : item.id})
            });
        }
        return placardOptions;
    }

    async function handleSubmit(e) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        var formJson = Object.fromEntries(formData.entries());
        if(!formJson.id || !formJson.id.trim()) {
            setErrorMessage("Id is empty");
            setOpenErrorModal(true);
            return;
        }
        if(!formJson.title || !formJson.title.trim()) {
            setErrorMessage("Title is empty");
            setOpenErrorModal(true);
            return;
        }
        if(!formJson.mediaLink || !formJson.mediaLink.trim()) {
            setErrorMessage("Media Link is empty");
            setOpenErrorModal(true);
            return;
        }
        if (selectedPlacardIds != null) {
            formJson.plaCardIds = getArrayFromSelectedMultipleOptions(selectedPlacardIds);
        }
        if(!formJson.description || !formJson.description.trim()) {
            setErrorMessage("Description is empty");
            setOpenErrorModal(true);
            return;
        }
        formJson.shouldShow = shouldShowChecked;
        console.log(formJson)

        try {
            var response = await sendPostRequestAll(Constants.backendUrl + "/admin/brand/update", formJson, true);

            if (response.status == HttpStatusCode.Ok) {
                if (response.data != null) {
                    setSuccessMessage("Brand Updated successfully");
                    setOpenSuccessModal(true);
                    console.log(response.data);
                }
            } else {
                setErrorMessage(response.data);
                setOpenErrorModal(true);
            }
            console.log("Here the response : ");
            console.log(response);
        } catch (err) {
            setOpenErrorModal(true);
            setErrorMessage(err.response.data);
            console.log("Error in submitting the brand data : ");
            console.error(err);
        }
    }

    const addBulletPoint = () => {
        document.getElementById("bullet_point_div")
        .insertAdjacentHTML("beforeend",
            `<input
               type="text"
               className="border-0 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               name="bulletpoints"
            />`);
    }

    const addImageUrls = () => {
        document.getElementById("image_urls_div")
        .insertAdjacentHTML("beforeend",
            `<input
               type="text"
               className="border-0 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               name="imageUrls"
            />`);
    }

    const addVideoUrls = () => {
        document.getElementById("video_urls_div")
        .insertAdjacentHTML("beforeend",
            `<input
               type="text"
               className="border-0 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               name="videoUrls"
            />`);
    }

    const levelOptions = [
      0,1,2
    ];


     useEffect(() => {
        if (loading) return;
        if (!user) return navigate.push("/");
        fetchPlacardData();
     }, [user, loading]);

    useEffect(async() => {
        const queryParams = new URLSearchParams(window.location.search);
        const value = queryParams.get('id');
        if (value && placardDataOptions && placardDataOptions.length > 0) {
            console.log("value : " + value);
            await fetchBrandDataById(value);
        }
    }, [placardDataOptions])


    return (
        <>
            <div className="flex-auto px-4 pt-0">
            <Modal open={openErrorModal} onClose={onCloseErrorModal} center>
                <h2 className="text-xl mb-4 font-bold border-b pb-2">Oops, An Error Occurred ! &nbsp; &nbsp; &nbsp; &nbsp;</h2>
                <p>{errorMessage}</p>
            </Modal>
            <Modal open={openSuccessModal} onClose={onCloseSuccessModal} center>
                <h2 className="text-xl mb-4 font-bold border-b pb-2">Operation successful &nbsp; &nbsp; &nbsp; &nbsp;</h2>
                <p>{successMessage}</p>
            </Modal>

            <Modal open={openDeleteBrandModal} onClose={onCloseDeleteBrandModal} center>
                <h2 className="text-xl mb-4 font-bold border-b pb-2">Confirm Delete this brand?</h2>
                <p>Do you want to delete this brand?</p>
                <p className="text-sm mb-2">(This is irreversible process)</p>
                <div className="flex mt-3">
                    <button className="text-white bg-lightBlue-600 font-bold uppercase text-sm p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        onClick={onCloseDeleteBrandModal}>No</button>
                    <button className="ml-2 text-white bg-lightBlue-600 font-bold uppercase text-sm p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        onClick={deleteBrand}>Yes</button>
                 </div>
            </Modal>
                <h6 className="text-blueGray-700 text-md mt-3 mb-6 font-bold uppercase">
                 Update Brand        &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;
                   {id !=null && (<a href={"/brand/" + id}><button
                                                   className="bg-indigo-500 mt-2 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                   type="button"

                                                 >
                                                   View Brand
                                                 </button></a>)}
                </h6>
              {showForm && (
              <form onSubmit={handleSubmit}>
                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Brand Details
                </h6>

                <div className="flex flex-wrap">
                   <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Brand Id (Unique *)
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        readOnly = {true}
                        name="id" value={id}
                      />
                    </div>
                  </div>

                  <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Brand Title (*)
                      </label>
                      <input
                        type="text"
                        onChange={e => setTitle(e.target.value)}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        name="title" value={title}
                      />
                    </div>
                  </div>

                    {placardDataOptions != null && placardDataOptions.length > 0 && (

                        <div className="w-full px-4">
                          <div className="relative w-full mb-3 mt-4">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              Placard Ids
                            </label>
                            <Multiselect
                              selectedValues={selectedPlacardIds}
                              options={placardDataOptions}
                              onSelect={selectPlacardIds}
                              onRemove={selectPlacardIds}
                              displayValue="name"
                              />
                          </div>
                        </div>

                      )}

                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Star Rating (Out of 5 in Decimal *)
                          </label>
                          <input
                            type="number"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            max="5.0"
                            name="starRating" step="0.1" value={starRating} onChange={e => setStarRating(e.target.value)}
                          />
                        </div>
                      </div>

                  <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Description (*)
                    </label>
                    <textarea
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      rows="4"
                      onChange={e => setDescription(e.target.value)}
                      name="description" value={description}
                    ></textarea>
                  </div>
                </div>
                </div>

                <hr className="mt-6 border-b-1 border-blueGray-300" />

                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Images
                </h6>
                <div className="relative w-full mb-3 mt-4 px-4">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Media Link (*)
                  </label>
                  <div>
                      <input
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          name="mediaLink"
                          onChange={e => setMediaLink(e.target.value)}
                          value={mediaLink}
                       />
                   </div>
                   {mediaLink != null && (
                      <div className="mt-4">
                           <p className="mb-2 text-sm">Media Link Preview</p>
                           <img src={mediaLink} className="max-h-150-px" />
                      </div>
                   )}

                </div>

                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                  Other Attributes (Optional)
                                </h6>
                 <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Should Show &nbsp;&nbsp; <input type="checkbox" id="shouldShow" name="shouldShow" checked={shouldShowChecked} onChange={e => setShouldShowChecked(e.target.checked)} />
                      </label>

                    </div>
                 </div>

                <hr className="mt-6 border-b-1 border-blueGray-300" />


                <div className="w-full mt-4 mb-3">
                    <button
                        className="bg-primary text-white font-bold uppercase text-sm px-4 p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="submit"
                    >
                        Update Brand
                    </button>
                    <button
                        className="bg-red-500 text-white font-bold uppercase text-sm px-4 p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={onOpenDeleteBrandModal}
                    >
                        Delete Brand
                    </button>
                </div>

              </form>
              )}

              <hr className="mt-6 border-b-1 border-blueGray-300" />
                <div className="card p-4 mt-4 bg-white">
                <h2 className="text-xl mb-4 font-bold pb-2">Search Brand by ID</h2>
                <form onSubmit={handleSearch} className="mt-3">
                    <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        name="searchId" value={searchId}
                      />
                    <button
                        className="bg-primary mt-4 text-white font-bold uppercase text-sm px-4 p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="submit"
                    >
                        <i className="fa fa-search" aria-hidden="true"></i> &nbsp; &nbsp; Search Brand
                    </button>
                </form>
                </div>
            </div>
        </>
    )
}