import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import './helpers/firebase.js';
// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

// views without layouts

import About from "views/About.js";
import Contact from "views/Contact.js"
import Profile from "views/Profile.js";
import Index from "views/Index.js";
import MerchantOnboardInfo from "views/onboardinfo/merchant.js";
import ProductView from "views/pages/ProductView.js";
import MerchantView from "views/pages/MerchantView.js"
import BrandView from "views/pages/BrandView.js"
import CategoryView from "views/pages/CategoryView.js"
import UserCartView from "views/pages/UserCartView.js"
import UserWishlistView from "./views/pages/UserWishlistView";
import UserAddressView from "./views/pages/UserAddressView";
import UserCheckoutView from "./views/pages/UserCheckoutView";
import UserOrderDetailView from "./views/pages/UserOrderDetailView";
import UserOrderPlacedView from "./views/pages/UserOrderPlacedView";
import UserOrdersView from "./views/pages/UserOrdersView.js"
import ComingSoonView from "./views/pages/ComingSoonView.js"
import PageView from "./views/pages/PageView.js"

ReactDOM.render(
  <BrowserRouter>
    <Switch>
        {/* add routes with layouts */}
        <Route path="/admin" component={Admin} />
        <Route path="/auth" component={Auth} />

        {/* add routes without layouts */}
        <Route path="/about" exact component={About} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/manage/account" exact component={Profile} />
        <Route path="/joinus/merchant" exact component={MerchantOnboardInfo} />
        <Route path="/product/:pid" exact component={ProductView}/>
        <Route path="/p/:pid" exact component={PageView}/>
        <Route path="/merchant/:mid" exact component={MerchantView}/>
        <Route path="/brand/:bid" exact component={BrandView}/>
        <Route path="/category/:cid" exact component={CategoryView}/>
        <Route path="/mycart" exact component={UserCartView}/>
        <Route path="/myaddresses" exact component={UserAddressView}/>
        <Route path="/mywishlist" exact component={UserWishlistView}/>
        <Route path="/checkout" exact component={UserCheckoutView}/>
        <Route path="/orderplaced/:orderId" component={UserOrderPlacedView} / >
        <Route path="/myorders/:orderId" component={UserOrderDetailView} / >
        <Route path="/myorders" exact component={UserOrdersView} / >
        <Route path="/comingsoon" exact component={ComingSoonView} / >
        <Route path="/store" exact render={(props) => (
                                       <PageView store={true}/>
                                   )} />
        <Route path="/" exact component={Index} />
        {/* add redirect for first page */}
        <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
