import {React,useState,useEffect,useMemo} from "react";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {sendGetRequestAll, sendPostRequestAll} from "../../helpers/httprequesthelper";
import * as Constants from "constants/constants.js";
import {Modal} from "react-responsive-modal";
import {HttpStatusCode} from "axios";
import Multiselect from 'multiselect-react-dropdown';
import {useHistory} from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "helpers/firebase.js";
import { MaterialReactTable } from 'material-react-table';

export default function AllBrand() {

    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [data, setData] = useState(null);
    const [user, loading, error] = useAuthState(auth);
    const navigate = useHistory();
    const [brandData, setBrandData] = useState([]);

    const createBrandDataObject = (data) => {
        if (data == null) {
            return;
        }
        var brandData = [];
        data.forEach(function (item, index) {
            brandData.push({
                          "id" : item.id,
                          "title" : item.title,
                          "image" : item.mediaLink,
                          "shouldshow" : item.shouldShow == null ? "Null" : item.shouldShow.toString(),
                          "stars" : item.starRating,
                          "viewUrl" : item.urlLink,
                          "editUrl" : "/admin/brands/update?id=" + item.id })
        });
        console.log(brandData);
        setBrandData(brandData);
    }


    const columns = useMemo(
        () => [

            {
                header: "Id",
                accessorKey: 'id', //access nested data with dot notation
                size: 6
            },
             {
                            header: "Title",
                            accessorKey: 'title', //access nested data with dot notation
                            size: 6
                        },
            {
                header: "Brand Image",
                accessorKey: 'image', //access nested data with dot notation
                Cell: ({ cell }) => (
                    <img
                      alt="avatar"
                      height={10}
                      src={cell.getValue()}
                      loading="lazy"
                      className="w-auto h-auto align-middle max-h-120-px max-w-120-px"
                    />
                ),
                size: 30
            },
            {
                header: "Stars (Out of 5)",
                accessorKey: 'stars', //access nested data with dot notation
                size: 6
            },
//            {
//                header: "Description",
//                accessorKey: 'description', //access nested data with dot notation
//                Cell: ({ cell }) => (
//                    <>
//                        <p className="font-bold">
//                            {cell.getValue().title}
//                        </p>
//                        <p className="text-sm">
//                            {cell.getValue().subtitle}
//                        </p>
//                    </>
//                ),
//                size: 300
//            },
            {
                header: "ShouldShow",
                accessorKey: 'shouldshow', //access nested data with dot notation
                size: 10
            },
            {
                header: "View",
                accessorKey: 'viewUrl', //access nested
                Cell: ({ cell }) => (
                    <>
                        <a href={cell.getValue()} className="font-bold">
                            <i class="fa fa-eye" aria-hidden="true"></i>
                        </a>
                    </>
                ),
                size: 10
            },
            {
                header: "Edit",
                accessorKey: 'editUrl', //access nested
                Cell: ({ cell }) => (
                    <>
                        <a href={cell.getValue()} className="font-bold">
                            <i class="fa fa-edit" aria-hidden="true"></i>
                        </a>
                    </>
                ),
                size: 10
            }
        ],
        [],
    );
    function onCloseErrorModal() {
        setOpenErrorModal(false);
        setErrorMessage(null);
    }

    function onCloseSuccessModal() {
        setOpenSuccessModal(false);
        setSuccessMessage(null);
    }

    async function fetchAllBrandData() {
        try {
            var response = await sendGetRequestAll(Constants.backendUrl + "/admin/brand", true);
            if (response.data != null) {
                setData(response.data);
                createBrandDataObject(response.data);
            }
        } catch (err) {
            console.error(err);
            console.error(err);
            if (err && err.response && err.response.data) {
                alert("An error occured while loading brand data : " + err.response.data);
            } else {
                alert("An error occured while loading brand data");
            }
        }
    };

     useEffect(() => {
        if (loading) return;
        if (!user) return navigate.push("/auth/login");
        fetchAllBrandData();
     }, [user, loading]);

    return (
        <>
            <div className="flex-auto px-4 pt-0">
            <Modal open={openErrorModal} onClose={onCloseErrorModal} center>
                <h2 className="text-xl mb-4 font-bold border-b pb-2">Oops, An Error Occurred ! &nbsp; &nbsp; &nbsp; &nbsp;</h2>
                <p>{errorMessage}</p>
            </Modal>
            <Modal open={openSuccessModal} onClose={onCloseSuccessModal} center>
                <h2 className="text-xl mb-4 font-bold border-b pb-2">Operation successful &nbsp; &nbsp; &nbsp; &nbsp;</h2>
                <p>{successMessage}</p>
            </Modal>
                <h6 className="text-blueGray-700 text-md mt-3 mb-2 font-bold uppercase">
                 All Brand Data
                </h6>
                {data!=null && data.length > 0 && (
                    <p className="mb-4">Total {data.length} Brands</p>
                )}


                <div className="flex flex-wrap">
                   <div className="w-full px-4">
                        { data!= null && data.length > 0 && (
                            <div className="mt-2">
                                <MaterialReactTable columns={columns} data={brandData}
                                    enableColumnActions={true}
                                    enableColumnFilters={true}
                                    enablePagination={false}
                                    enableSorting={true}
                                    enableBottomToolbar={false}
                                    enableTopToolbar={false}
                                    muiTableBodyRowProps={{ hover: false }}/>
                            </div>
                        )}
                    </div>
                  </div>







            </div>
        </>
    )
}