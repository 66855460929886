import {React,useState} from "react";
import {Modal} from "react-responsive-modal";
import * as Constants from "../constants/constants";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {sendPostRequestAll} from "../helpers/httprequesthelper";
import {HttpStatusCode} from "axios";

export default function AddressAddOrUpdateComponent(props) {
    const [firstLineMessage, setFirstLineMessage] = useState(null);
    const [secondLineMessage, setSecondLineMessage] = useState(null);
    const [landmarkMessage, setLandmarkMessage] = useState(null);
    const [selectedArea, setSelectedArea] = useState({value : Constants.areas[0]});
    const [errorMessage, setErrorMessage] = useState(null);

    function hideErrorMessage() {
        setErrorMessage(null);
    }

    async function addOrUpdateAddress(data) {
        if (data == null) {
            setErrorMessage("Data is null or empty");
            return;
        }
        try {
            var response = await sendPostRequestAll(Constants.backendUrl + "/myaddresses", data, true);
            if (response.status == HttpStatusCode.Ok) {
                props.fetchData();
                props.onCloseAddAddressModal();
            }
        } catch (err) {
            console.log("Error in addOrUpdateAddress : " + err.response.data.toString());
            setErrorMessage(err.response.data);
        }
    };

    const getCoords = async () => {
        const pos = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        return {
            long: pos.coords.longitude,
            lat: pos.coords.latitude,
        };
    };

    async function handleSubmit(e) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        var formJson = Object.fromEntries(formData.entries());
        var shouldProceed = true;
        if (formJson.addressLine1.length == 0) {
            setFirstLineMessage("First Line of Address can't be empty");
            shouldProceed = false;
        } else {
            setFirstLineMessage(null);
        }
        if (formJson.addressLine2.length == 0) {
            setSecondLineMessage("Second Line of Address can't be empty");
            shouldProceed = false;
        } else {
            setSecondLineMessage(null);
        }
        if (formJson.landmark.length == 0) {
            setLandmarkMessage("Landmark can't be empty");
            shouldProceed = false;
        } else {
            setLandmarkMessage(null);
        }

        if (shouldProceed == false) {
            return;
        } else {
            try {
                const coords = await getCoords();
                formJson.latitude = coords.lat;
                formJson.longitude = coords.long;
            } catch (err) {
                console.log("Error while getting coords : " + err);
            }
            formJson.area = selectedArea.value;
            var defaultAddressCheckbox = document.getElementById("defaultAddress");
            if (defaultAddressCheckbox.checked == true){
                formJson.isDefault = true;
            } else {
                formJson.isDefault = false;
            }
            addOrUpdateAddress(formJson);
        }

    }

    var defaultOption = Constants.areas[0];
    var name = null;
    var firstLineValue = null;
    var secondLineValue = null;
    var landmark = null;
    var isDefault = false;
    var id = null;
    if (props.data != null) {
        if (props.data.area!=null) {
            defaultOption = props.data.area;
        }
        if (props.data.isDefault) {
            isDefault = true;
        }
        if (props.data.addressLine1 != null) {
            firstLineValue = props.data.addressLine1;
        }
        if (props.data.name != null) {
            name = props.data.name;
        }
        if (props.data.addressLine2 != null) {
            secondLineValue = props.data.addressLine2;
        }
        if (props.data.landmark != null) {
            landmark = props.data.landmark;
        }
        if (props.data.id != null && props.data.id != undefined) {
            id = props.data.id;
        }
    }

    return (
        <>
            <Modal open={props.openAddAddress} onClose={props.onCloseAddAddressModal} center>
                {props.addAddress && (
                    <h2 className="text-xl mb-4 font-bold border-b pb-2 pr-12 addAddressModal">Add New Address</h2>
                )}
                {!props.addAddress && (
                    <h2 className="text-xl mb-4 font-bold border-b pb-2 pr-12 addAddressModal">Edit Address</h2>
                )}
                { errorMessage!= null && (
                    <div className="text-white px-6 py-3 border-0 rounded relative mb-4 bg-red-500">

                        <span className="inline-block align-middle mr-8">
                                     {errorMessage}
                                  </span>
                        <button className="absolute bg-transparent text-2xl font-semibold leading-none right-10 top-0 mt-4 mr-6 outline-none focus:outline-none" onClick={()=>hideErrorMessage()}>
                            <span>×</span>
                        </button>
                    </div>
                )}
                <form onSubmit={handleSubmit}>
                    <p className="mt-4 mb-2 text-sm">
                        Select an area
                    </p>
                    <Dropdown options={Constants.areas} onChange={setSelectedArea} value={defaultOption} placeholder="Select an option" />
                    <input name="id" label='id' className="field" readOnly={true} value={id} hidden={true}/>
                    <p className="mt-4 mb-2 text-sm">
                        First Line
                    </p>
                    <input name="addressLine1" label='First Line' className="field" defaultValue={firstLineValue}/>
                    { firstLineMessage!= null && (
                        <p className="text-red-500 mb-2 text-xs">
                            * {firstLineMessage}
                        </p>
                    )}
                    <p className="mt-4 mb-2 text-sm">
                        Second Line
                    </p>
                    <input name="addressLine2" label='Second Line' className="field" defaultValue={secondLineValue}/>
                    { secondLineMessage!= null && (
                        <p className="text-red-500 mb-2 text-xs">
                            * {secondLineMessage}
                        </p>
                    )}
                    <p className="mt-4 mb-2 text-sm">
                        Landmark (Any Nearby famous spot)
                    </p>
                    <input name="landmark" label='Landmark' className="field" defaultValue={landmark}/>
                    { landmarkMessage!= null && (
                        <p className="text-red-500 mb-2 text-xs">
                            * {landmarkMessage}
                        </p>
                    )}

                    <p className="mt-4 mb-2 text-sm">
                        Name (Optional)
                    </p>
                    <input name="name" label='name' className="field" defaultValue={name}/>

                    <p className="mt-4 mb-4">
                        Make as Default Address &nbsp; &nbsp; <input type="checkbox" id="defaultAddress" name="defaultAddress" defaultChecked={isDefault} />
                    </p>

                    <div className="mt-3 mb-3 flex flex-wrap justify-between">

                        {props.addAddress && (
                            <button
                                type="reset"
                                className="bg-teal-500 text-white font-bold uppercase text-sm px-4 p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            >

                                Reset form
                            </button>
                        )}
                        {props.addAddress && (
                            <button
                                type="submit"
                                className="bg-primary text-white font-bold uppercase text-sm px-4 p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            >
                                Add Address
                            </button>
                        )}

                        {!props.addAddress && (
                            <button
                                type="submit"
                                className="bg-primary text-white font-bold uppercase text-sm px-4 p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            >
                                Update Address
                            </button>
                        )}

                    </div>

                    <hr/>
                    <p className="text-center text-xs mt-4 mb-3">
                        Your address will be saved with default pincode {Constants.pincode}. <br/>We only deliver to above mentioned areas. More areas would be added in coming days.
                    </p>
                </form>
            </Modal>
        </>
    );
}