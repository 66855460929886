import { Link } from "react-router-dom";
import React from "react";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import StandardPricingAndCartSection from "../ProductComponents/StandardPricingAndCartSection";
import Product from "../ProductComponents/Product";
import Rating from "react-rating";

export default function SideCarousel(props) {
    const responsive = {
        tv: {
            breakpoint: { max: 3000, min: 1401 },
            items: 4,
            partialVisibilityGutter: 30
        },
        desktop: {
            breakpoint: { max: 1400, min: 1024 },
            items: 3,
            slidesToSlide: 1,
            partialVisibilityGutter: 30
        },
        laptop: {
            breakpoint: { max: 1023, min: 860 },
            items: 2.1,
            slidesToSlide: 1,
            partialVisibilityGutter: 30
        },
        tablet: {
            breakpoint: { max: 860, min: 464 },
            items: 1,
            partialVisibilityGutter: 30
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            partialVisibilityGutter: 30
        }
    };

    return (
        <div className="card p-2 pb-4 mx-1">
            <div className="w-full flex mt-4">
                <div className="xs:w-6/12 sm:w-6/12 lg:w-3/12" >
                    <div
                        className="w-full mb-2 mt-2 p-2">
                        <div>
                            { props.mediaLink && (
                                <div className="w-full text-center">
                                    <img src={props.mediaLink} className="max-h-100-px" />
                                </div>
                            )}
                            { props.title && (
                                <div className="w-full">
                                    <h1 className="font-semibold text-left text-2xl text-left">
                                        {props.title}
                                    </h1>
                                </div>
                            )}
                            { props.subtitle && (
                                <div className="w-full">
                                    <h1 className="text-left text-xl text-left">
                                        {props.subtitle}
                                    </h1>
                                </div>
                            )}
                            { props.starRating && (
                                <div className="w-full mt-1">
                                    <Rating initialRating={props.starRating} emptySymbol={<i className="fas fa-star brand-rating-star-empty text-md"></i>} fullSymbol={<i className="fas fa-star brand-rating-star-filled text-md"></i>} readonly={true}/>
                                </div>
                            )}
                            { props.description && (
                                <div className="w-full">
                                    <h1 className="text-left">
                                        {props.description}
                                    </h1>
                                </div>
                            )}
                        </div>
                        { props.viewAllLink && (
                            <div className="w-full text-left">
                                <Link to={props.viewAllLink}>
                                    <button
                                        className="bg-indigo-500 p-2 mt-3 text-white active:bg-indigo-600 text-xs font-bold uppercase px-6 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button">View All</button>
                                </Link>
                            </div>
                        )}

                    </div>
                </div>
                <div className="xs:w-6/12 sm:w-6/12 lg:w-9/12 ">
                    <Carousel swipeable={true}
                              draggable={true} responsive={responsive} showThumbs={false} arrows={false} partialVisible={true}>
                        {props.data!=null && props.data.map(function(dataelement, index){
                            return   <div key={index}>
                                <Product dataelement={dataelement}/>
                            </div>
                        })}
                    </Carousel>
                </div>
            </div>
        </div>
    );
}