import React, { useEffect, useState } from "react";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import {sendGetRequestAll} from "../helpers/httprequesthelper";
import PlacardComponent from "../components/PlacardComponent";
import * as Constants from "constants/constants.js";
import Loader from "components/Loader/Loader.js"

export default function Index() {
  const [data, setData] = useState(null);

  const fetchData = async () => {
    try {
      var response = await sendGetRequestAll(Constants.backendUrl + "/index", false);
      setData(response.data);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching index data");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>

      <IndexNavbar fixed />
      {!data && (
         <Loader data={data}/>
      )}
      {data && (
            <div className="container mx-auto mt-80px">
              <PlacardComponent data={data}/>

            </div>
      )}

      <Footer />
    </>
  );
}
