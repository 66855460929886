import React, {useEffect, useState, useMemo} from "react";
import {sendGetRequestAll, sendPostRequestAll} from "../../helpers/httprequesthelper";
import IndexNavbar from "../../components/Navbars/IndexNavbar";
import Footer from "../../components/Footers/Footer";
import 'react-responsive-modal/styles.css';
import {HttpStatusCode} from "axios";
import AddressAddOrUpdateComponent from "../../components/AddressAddOrUpdateComponent";
import * as Constants from "constants/constants.js";
import {Link, useHistory} from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "helpers/firebase.js";
import Loader from "components/Loader/Loader.js"

export default function UserAddressView(props) {
    const [data, setData] = useState(null);
    var retryCount = 0;
    const [editAddressData, setEditAddressData] = useState(null);
    const [openEditAddress, setOpenEditAddress] = useState(false);
    const maxRetryCount = 5;
    const [message, setMessage] = useState(null);
    const [openAddAddress, setOpenAddAddress] = useState(false);

    const onOpenAddAddressModal = () => setOpenAddAddress(true);
    const onCloseAddAddressModal = () => setOpenAddAddress(false);

    const onCloseEditAddressModal = () => setOpenEditAddress(false);
    const [user, loading, error] = useAuthState(auth);
    const navigate = useHistory();

    function hideMessage() {
        setMessage(null);
    }

    function editAddress(data) {
        if (data != null) {
            setEditAddressData(data);
            setOpenEditAddress(true);
        }
        return;
    }

    async function setAsDefault (addressId) {
        var data = {
            "id" : addressId
        }
        try {
            var response = await sendPostRequestAll(Constants.backendUrl + "/myaddresses/setAsDefault", data, true);
            if (response.status == HttpStatusCode.Ok) {
                await fetchData();
            } else {
                setMessage("Some issue occurred while processing your request. Please try again.")
            }
        } catch (err) {
            setMessage(err.response.data)
            console.log("Error in setAsDefault : " + err.response.data);
        }
    }

    async function deleteAddress (addressId) {
        var data = {
            "id" : addressId
        }
        try {
            var response = await sendPostRequestAll(Constants.backendUrl + "/myaddresses/remove", data, true);
            if (response.status == HttpStatusCode.Ok) {
                await fetchData();
            } else {
                setMessage("Some issue occurred while processing your request. Please try again.")
            }
        } catch (err) {
            setMessage(err.response.data)
            console.log("Error in deleteAddress : " + err.response.data);
        }
    }

    const fetchData = async () => {
        try {
            var response = await sendGetRequestAll(Constants.backendUrl + "/myaddresses", true);
            if (response.status == HttpStatusCode.Ok) {
                setData(response.data);
            } else {
                setData(null);
            }
        } catch (err) {
            console.error(err);
            console.error("Error Occurred");
            setData(null);
            if (err instanceof TypeError && retryCount < maxRetryCount) {
                console.log("Retrying");
                retryCount = retryCount + 1;
                setTimeout(fetchData, 1000);
            } else {
                setMessage("Error while loading addresses. Please try again later or contact us.");
            }
        }

    };

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate.push("/");
        fetchData();
    }, [user, loading]);

    return (
        <>
            <IndexNavbar />
            {!data && (
               <Loader/>
            )}
            {data && (
            <>
            <section className="relative block h-400-px">
                <div
                    className="absolute top-0 w-full h-full bg-center bg-cover"
                    style={{
                        backgroundImage:
                            "url(" + Constants.userAddressViewBackgroundImage + ")",
                    }}
                >
            <span
                id="blackOverlay"
                className="w-full h-full absolute opacity-50 bg-black"
            ></span>
                </div>
                <div
                    className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                    style={{ transform: "translateZ(0)" }}
                >
                    <svg
                        className="absolute bottom-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="text-blueGray-200 fill-current"
                            points="2560 0 2560 100 0 100"
                        ></polygon>
                    </svg>
                </div>
            </section>


            <section className="relative py-16 bg-blueGray-200">
                <div className="container mx-auto px-2">
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-80">
                        <div className="p-2">

                            <div className="mb-4 w-full">
                                <div className="w-full p-3">
                                    <h3 className="text-4xl text-center uppercase font-semibold leading-normal mb-2 text-blueGray-700">
                                        My Address
                                    </h3>

                                </div>
                                {openAddAddress == true && (
                                    <AddressAddOrUpdateComponent openAddAddress={openAddAddress} onCloseAddAddressModal={onCloseAddAddressModal} fetchData={fetchData} addAddress={true}/>
                                )}

                                <div className="w-full flex flex-wrap">
                                    { message!= null && (
                                        <div className="text-white px-6 py-3 border-0 rounded relative mb-4 bg-emerald-500">
                                          <span className="text-xl inline-block mr-5 align-middle">
                                            <i className="fas fa-bell"></i>
                                          </span>
                                                                    <span className="inline-block align-middle mr-8">
                                             {message}
                                          </span>
                                            <button className="absolute bg-transparent text-2xl font-semibold leading-none right-10 top-0 mt-4 mr-6 outline-none focus:outline-none" onClick={()=>hideMessage()}>
                                                <span>×</span>
                                            </button>
                                        </div>
                                    )}
                                {data != null && data.map(function(dataelement, index){
                                    return (
                                            <div className="lg:w-4/12 md:w-6/12 sm:w-full xs:w-full p-2" key={dataelement.id}>
                                                <div className="w-full card p-3 min-h-300-px">
                                                    {dataelement.name && (
                                                        <p className="font-bold text-md">{dataelement.name}</p>
                                                    )}
                                                    {dataelement.addressLine1 && (
                                                        <p className="mt-3 text-sm">{dataelement.addressLine1}</p>
                                                    )}
                                                    {dataelement.addressLine2 && (
                                                        <p className="mt-1 text-sm">{dataelement.addressLine2}</p>
                                                    )}

                                                    <p className="mt-1 text-sm">
                                                        {dataelement.city && (
                                                        <span>{dataelement.city}, </span>
                                                        )}
                                                        {dataelement.state && (
                                                            <span>{dataelement.state}, </span>
                                                        )}
                                                        {dataelement.state && dataelement.pinCode && (
                                                            <span>{dataelement.state} - {dataelement.pinCode}, </span>
                                                        )}
                                                        {dataelement.country && (
                                                            <span>{dataelement.country}</span>
                                                        )}
                                                        </p>
                                                    <p className="mt-1 text-sm">Landmark : {dataelement.landmark}</p>
                                                    <div className="mt-2">
                                                        {dataelement.latitude != null && dataelement.longitude != null && (
                                                            <a href={"https://maps.google.com/?q=" + dataelement.latitude + "," + dataelement.longitude + "&z=8"}>
                                                                <button className="btn-outline btn-default-outline text-xs mr-2">
                                                                    Locate &nbsp;&nbsp;<i className="fas fa-location-arrow"></i>
                                                                </button>
                                                            </a>
                                                        )}
                                                        {dataelement.isDefault && (
                                                            <span className="text-sm font-semibold inline-block py-1 px-2 uppercase rounded text-emerald-600 bg-emerald-200 mr-2 uppercase last:mr-0 mr-1">Default</span>
                                                        )}
                                                    </div>

                                                    <div className="flex flex-wrap mt-3">
                                                        {!dataelement.isDefault && (
                                                            <button className="btn-outline btn-success-outline text-xs mr-2"
                                                            onClick={() => setAsDefault(dataelement.id)}>
                                                                Make as Default
                                                            </button>
                                                        )}

                                                        <button className="btn-outline btn-default-outline text-xs mr-2"
                                                                onClick={() => editAddress(dataelement)}>
                                                            <i className="fa fa-edit" aria-hidden="true"></i>
                                                        </button>

                                                        <button className="btn-outline btn-default-outline text-xs mr-2"
                                                                onClick={() => deleteAddress(dataelement.id)}>
                                                            <i className="fa fa-trash" aria-hidden="true"></i>
                                                        </button>

                                                    </div>

                                                </div>
                                            </div>
                                    )}
                                )}
                                    {editAddressData && (
                                        <AddressAddOrUpdateComponent openAddAddress={openEditAddress} onCloseAddAddressModal={onCloseEditAddressModal} fetchData={fetchData} data={editAddressData}/>
                                    )}

                                </div>
                                <div className="w-full mt-8 flex flex-wrap justify-center">
                                    <button
                                        className="bg-lightBlue-500 text-white active:bg-lightBlue-400 text-lg px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={onOpenAddAddressModal}
                                    >
                                        <i className="fa fa-plus-circle" aria-hidden="true"></i> &nbsp;&nbsp; Add Address
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </>
            )}
            <Footer />

        </>
    );
}