import moment from "moment";

export function formatAreasWithCommaAnd(areas) {
  if (areas.length > 1) {
      var areasToChange = Array.from(areas);
    var areasMinusOne = areasToChange.slice(0, -1);
    var str = areasMinusOne.join(", ");
    return str + " and " + areasToChange.pop()
  }
  return areasToChange;
}

export function daysLeft(epoch) {
    var days = null;
    if (epoch) {
        var timeleft = (new Date()).getTime() - (epoch * 1000);
        days = Math.ceil((((timeleft / 1000) / 60) / 60) / 24)
    }
    return days;
}

export function getDateAndTimeFromTimestamp(epoch) {
    var t = new Date(+epoch);
    var formatted = moment(t).utcOffset(330).format("MMM Do YYYY, h:mm A");
    return formatted;
}

export function getDateFromTimestamp(epoch) {
    var t = new Date(+epoch);
    var formatted = moment(t).utcOffset(330).format("MMM Do YYYY");
    return formatted;
}

export function getBlogBriefDescription(description) {
    var maxLength = 100;
    var minimumLengthOfDescription = Math.min(maxLength, description.length);
    let resultantString = description.substring(0, minimumLengthOfDescription);
    if (resultantString.length != description.length) {
        resultantString = resultantString.concat("...");
    }
    return resultantString;
}

export function getAddressLabelString (dataelement) {
    var labelString = "";
    const addressSeparator = ", ";
    if (dataelement.name != null) {
        labelString = labelString + dataelement.name + addressSeparator;
    }
    if (dataelement.addressLine1 != null) {
        labelString = labelString + dataelement.addressLine1 + addressSeparator;
    }
    if (dataelement.addressLine2 != null) {
        labelString = labelString + dataelement.addressLine2 + addressSeparator;
    }
    if (dataelement.landmark != null) {
        labelString = labelString + " near " +  dataelement.landmark + addressSeparator;
    }
    if (dataelement.area != null) {
        labelString = labelString +  dataelement.area + addressSeparator;
    }
    if (dataelement.city != null && dataelement.pinCode != null) {
        labelString = labelString +  dataelement.city + " - " + dataelement.pinCode;
    }
    return labelString;
}

export function truncateString (actualString, requiredLength) {
    if (actualString.length > requiredLength) {
        var truncatedString = actualString.slice(0, requiredLength) + '...';
        return truncatedString;
    }
    return actualString;
}