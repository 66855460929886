import Marquee from "react-fast-marquee";
import {Carousel} from "react-responsive-carousel";
import {Link} from "react-router-dom";

export default function TextMarquee(props) {
    return (
        <>
            {props.subtitle != null && (
                <>
                    <Marquee gradient={true} gradientWidth={100} className="mt-8 mb-8 text-xl">
                        {props.subtitle}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Marquee>

                </>
            )}
        </>
    );
}