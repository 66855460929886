import Marquee from "react-fast-marquee";
import {Carousel} from "react-responsive-carousel";
import {Link} from "react-router-dom";
import PlacardHeader from "../Headers/PlacardHeader";
import Product from "../ProductComponents/Product";

export default function MediumImageMarquee(props) {
    return (
        <div className="card p-2 pb-4 mx-1">
            <PlacardHeader title={props.title} subtitle={props.subtitle} description={props.description} viewAllLink={props.viewAllLink}/>
            <Marquee gradient={true} gradientWidth={50} pauseOnHover='true' autoFill='true' pauseOnHover='true' autoFill='true'>
                { props.data!=null && props.data.map(function(dataelement, index){
                    return  <div key={index}>
                        <Product dataelement={dataelement}/>
                    </div>
                })}
            </Marquee>
        </div>
    );
}