import Marquee from "react-fast-marquee";
import {Carousel} from "react-responsive-carousel";
import {Link} from "react-router-dom";
import ReactPlayer from "react-player";
import PlacardHeader from "../Headers/PlacardHeader";

export default function VideoMarquee(props) {
    return (
        <div className="card p-2 pb-4 mx-1">
            <PlacardHeader title={props.title} subtitle={props.subtitle} description={props.description} viewAllLink={props.viewAllLink}/>
            <Marquee gradient={true} gradientWidth={50} pauseOnHover='true' autoFill='true' pauseOnHover='true' autoFill='true'>
                {props.data != null && props.data.map(function(dataelement, index){
                    return  <div className="w-full" key={dataelement.id}>
                                <div className="flex flex-wrap w-full mb-2 p-3 ">
                                    <ReactPlayer width='350px' height='200px' url={dataelement.mediaLink} light={true} controls={true}/>
                                </div>
                            </div>
                })}
            </Marquee>
        </div>
    );
}