import { Link } from "react-router-dom";
import React from "react";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import StandardPricingAndCartSection from "../ProductComponents/StandardPricingAndCartSection";
import Product from "../ProductComponents/Product";
import Rating from "react-rating";

export default function HeadCarousel(props) {
    const responsive = {
        tv: {
            breakpoint: { max: 3000, min: 1401 },
            items: 5,
            partialVisibilityGutter: 30
        },
        desktop: {
            breakpoint: { max: 1400, min: 1024 },
            items: 4,
            slidesToSlide: 1,
            partialVisibilityGutter: 30
        },
        laptop: {
            breakpoint: { max: 1023, min: 860 },
            items: 3,
            slidesToSlide: 1,
            partialVisibilityGutter: 30
        },
        tablet: {
            breakpoint: { max: 860, min: 464 },
            items: 2,
            partialVisibilityGutter: 10
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
            partialVisibilityGutter: 10
        }
    };

    return (
        <div className="card p-2 pb-4 mx-1">
                <div className="w-full flex mb-2 mt-4 p-2" >
                    <div className="xs:w-4/12">
                        { props.mediaLink && (
                            <div className="text-center px-2">
                                <img src={props.mediaLink} className="max-h-100-px" />
                            </div>
                        )}

                    </div>
                    <div className="xs:w-8/12 sm:w-6/12 w-10/12 pl-4">
                        { props.title && (
                        <h1 className="font-semibold w-full text-left text-2xl text-left">
                            {props.title}
                        </h1>
                        )}
                        { props.subtitle && (
                            <h1 className="text-left w-full text-xl ml-3 text-left">
                                {props.subtitle}
                            </h1>
                        )}
                        { props.starRating && (
                            <div className="w-full mt-1">
                                <Rating initialRating={props.starRating} emptySymbol={<i className="fas fa-star brand-rating-star-empty text-xs"></i>} fullSymbol={<i className="fas fa-star brand-rating-star-filled text-xs"></i>} readonly={true}/>
                            </div>
                        )}
                        { props.description && (
                            <div className="w-full">
                                <h1 className="text-left">
                                    {props.description}
                                </h1>
                            </div>
                        )}
                        { props.viewAllLink && (
                            <div className="w-full text-left">
                                <Link to={props.viewAllLink}>
                                    <button className="bg-indigo-500 text-white p-2 active:bg-indigo-600 mt-2 text-xs font-bold uppercase px-2 rounded outline-none focus:outline-none ease-linear transition-all duration-150"
                                                            type="button">View All <i className="fas fa-arrow-right"></i></button>
                                </Link>
                            </div>
                        )}
                    </div>
                </div>



                <div className="w-full">
                    <Carousel swipeable={true}
                              draggable={true} responsive={responsive} showThumbs={false} arrows={false} partialVisible={true}>
                        {props.data!=null && props.data.map(function(dataelement, index){
                            return   <div key={index}>
                                <Product dataelement={dataelement}/>
                            </div>
                        })}
                    </Carousel>
                </div>

        </div>
    );
}