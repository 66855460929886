import { Link } from "react-router-dom";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import React from "react";
import {getBlogBriefDescription} from "../../helpers/formathelper";
import PlacardHeader from "../Headers/PlacardHeader";

export default function BlogCarousel(props) {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        laptop: {
            breakpoint: { max: 1023, min: 860 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 860, min: 464 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <div className="shadow-xl p-2 pb-4 mx-1">
            <PlacardHeader title={props.title} subtitle={props.subtitle} description={props.description} viewAllLink={props.viewAllLink}/>
            <Carousel autoPlay="true" interval="2000" swipeable={true}
                      draggable={true} responsive={responsive} infinite={true} showThumbs={false}>
                {props.data!=null && props.data.map(function(dataelement, index){
                    return  <Link to={dataelement.urlLink} key={dataelement.id}>
                        <div className="p-2 ">
                            <div className="card p-2 max-h-100-percent">
                                <img
                                    src={dataelement.mediaLink}
                                    className="w-full align-middle rounded-t-lg max-h-200-px"/>
                                { dataelement.title && (
                                    <h4 className="product-carousel-title font-bold mt-2">{dataelement.title}</h4>
                                )}
                                { dataelement.description && (
                                    <p className="text-md font-light mt-1">{getBlogBriefDescription(dataelement.description)}</p>
                                )}
                            </div>
                        </div>
                    </Link>
                })}
            </Carousel>
        </div>
    );
}