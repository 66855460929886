import React, {useEffect, useState} from "react";
import {sendGetRequestAll} from "../../helpers/httprequesthelper";
import IndexNavbar from "../../components/Navbars/IndexNavbar";
import Footer from "../../components/Footers/Footer";
import 'react-responsive-modal/styles.css';
import {HttpStatusCode} from "axios";
import * as FormatHelper from "../../helpers/formathelper";
import * as Constants from "../../constants/constants";
import { Link, useHistory } from "react-router-dom";
import {getDateAndTimeFromTimestamp} from "../../helpers/formathelper";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "helpers/firebase.js";
import Loader from "components/Loader/Loader.js"

export default function UserOrdersView() {
    const [orderData, setOrderData] = useState(null);
    const [page, setPage] = useState(0);
    const [shouldShowLoadMore, setShouldShowLoadMore] = useState(false);
    const [currentSelectedStatusFilter, setCurrentSelectedStatusFilter] = useState(null);
    var retryCount = 0;
    const maxRetryCount = 5;
    const [user, loading, error] = useAuthState(auth);
    const navigate = useHistory();

    const fetchData = async () => {
        try {
            var response = await sendGetRequestAll(Constants.backendUrl + "/order/all?page=0", true);
            if (response.status == HttpStatusCode.Ok) {
                setOrderData(response.data.ordersList);
                setPage(response.data.page);
                if (response.data.ordersList.length == 10) {
                    setShouldShowLoadMore(true);
                } else {
                    setShouldShowLoadMore(false);
                }
            } else {
                setOrderData(null);
                setShouldShowLoadMore(false);
            }
        } catch (err) {
            setOrderData(null);
            if (err instanceof TypeError && retryCount < maxRetryCount) {
                console.log("Retrying");
                retryCount = retryCount + 1;
                setTimeout(fetchData, 1000);
            }
        }
    };

    const fetchDataByStatus = async (status) => {
        try {
            setCurrentSelectedStatusFilter(status);
            var response = await sendGetRequestAll(Constants.backendUrl + "/order/all?page=0&userOrderStatus="+status, true);
            if (response.status == HttpStatusCode.Ok) {
                setOrderData(response.data.ordersList);
                if (response.data.ordersList.length < 10) {
                     setShouldShowLoadMore(false);
                     return;
                }
                setPage(response.data.page);
                setShouldShowLoadMore(true);
            } else {
                setOrderData(null);
                setShouldShowLoadMore(false);
            }
        } catch (err) {
            setOrderData(null);
            if (err instanceof TypeError && retryCount < maxRetryCount) {
                console.log("Retrying");
                retryCount = retryCount + 1;
                setTimeout(fetchData, 1000);
            }
        }
    }

    const fetchMoreData = async () => {
        try {
            var response = null;
            setPage(page + 1);
            if (currentSelectedStatusFilter == null) {
                response = await sendGetRequestAll(Constants.backendUrl + "/order/all?page=" + page, true);
            } else {
                response = await sendGetRequestAll(Constants.backendUrl + "/order/all?page=" + page + "&userOrderStatus=" + currentSelectedStatusFilter, true);
            }
            if (response.status == HttpStatusCode.Ok) {
                if (response.data.ordersList != null) {
                    if (response.data.ordersList.length < 10) {
                        setShouldShowLoadMore(false);
                    } else if (response.data.ordersList.length == 10) {
                        setShouldShowLoadMore(true);
                    }
                    var newData = response.data.ordersList;
                    newData = orderData.concat(newData);
                    setOrderData(newData);
                } else {
                    setShouldShowLoadMore(false);
                }
            } else {
                alert("Some error occurred while loading more orders");
            }
        } catch (err) {
            setOrderData(null);
            if (err instanceof TypeError && retryCount < maxRetryCount) {
                console.log("Retrying");
                retryCount = retryCount + 1;
                setTimeout(fetchData, 1000);
            }
        }
    }

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate.push("/");
        fetchData();
    }, [loading, user]);

    return (
        <>
            <IndexNavbar />
            {!orderData && (
               <Loader/>
            )}
            {orderData && (
            <>
            <section className="relative block h-400-px">
                <div
                    className="absolute top-0 w-full h-full bg-center bg-cover"
                    style={{
                       backgroundImage: "url(" + Constants.userOrdersViewBackgroundImage + ")",
                    }}
                >
            <span
                id="blackOverlay"
                className="w-full h-full absolute opacity-50 bg-black"
            ></span>
                </div>
                <div
                    className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                    style={{ transform: "translateZ(0)" }}
                >
                    <svg
                        className="absolute bottom-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="text-blueGray-200 fill-current"
                            points="2560 0 2560 100 0 100"
                        ></polygon>
                    </svg>
                </div>
            </section>


            <section className="relative py-16 bg-blueGray-200">
                <div className="container mx-auto px-2">
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-80">
                        <div className="p-2">

                            <div className="mb-4 flex flex-wrap justify-between w-full">
                                <div className="w-full p-3">
                                    <h3 className="text-3xl text-center uppercase font-semibold leading-normal mb-2 text-blueGray-700">
                                        My Orders
                                    </h3>
                                </div>

                                {orderData != null && orderData.size != 0 && (
                                    <>
                                        <div className="mt-8 flex flex-wrap text-center justify-center">
                                            <button
                                                className="btn-outline btn-info-outline text-sm rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                type="button"
                                                onClick={() => fetchData()}
                                            >
                                                All Orders
                                            </button>
                                            <button
                                                className="btn-outline btn-info-outline text-sm rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                type="button"
                                                onClick={() => fetchDataByStatus("PLACED")}
                                            >
                                                Placed
                                            </button>
                                            <button
                                                className="btn-outline btn-info-outline text-sm rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                type="button"
                                                onClick={() => fetchDataByStatus("CANCELLED")}
                                            >
                                               Cancelled
                                            </button>
                                            <button
                                                className="btn-outline btn-info-outline text-sm rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                type="button"
                                                onClick={() => fetchDataByStatus("CONFIRMED")}
                                            >
                                               Confirmed
                                            </button>
                                        </div>
                                        <div className="w-full">
                                            { orderData!=null && orderData.map(function(order, index){
                                                return  <div className="card m-2 p-2 mt-4">
                                                    <p className="text-xs">Order  # {order.id}</p>
                                                    <p className="text-xs">Placed On : {getDateAndTimeFromTimestamp(order.createdAt)}</p>
                                                    <div className="flex flex-wrap justify-between mt-3 mb-2">
                                                        <div className="mt-1">
                                                            <p className="text-blueGray-500 font-bold">{order.userOrderStatus}</p>
                                                            <p className="text-sm">{order.statusMessage}</p>

                                                        </div>
                                                        <div>
                                                            <Link to={"/myorders/" + order.id}>
                                                                <button
                                                                    className="bg-primary text-white active:bg-lightBlue-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mt-2 ease-linear transition-all duration-150"
                                                                    type="button"
                                                                >
                                                                    View Details &nbsp;<i className="fas fa-arrow-right"></i>
                                                                </button>
                                                            </Link>

                                                        </div>
                                                    </div>
                                                    <div className="flex flex-wrap mb-3">
                                                        { order.productsMap!=null && Object.keys(order.productsMap).map((key, index) => {
                                                            return <div >
                                                                <img src={order.productsMap[key].mediaLink} className="max-h-75-px mr-3 mt-2" />
                                                            </div>
                                                        })}
                                                    </div>
                                                    <div className="flex flex-wrap justify-end">
                                                        <div className="md:w-6/12 lg:w-4/12 w-full flex flex-wrap justify-between">
                                                            <div>
                                                                <p className="text-blueGray-600 text-sm">
                                                                    Total Amount
                                                                </p>
                                                                <p className="font-green font-bold text-sm">
                                                                    Payment Received
                                                                </p>
                                                                {(order.totalAmount - order.paymentReceived) > 0 && (
                                                                    <p className="text-blueGray-600 font-bold text-sm">
                                                                        Pending Payment
                                                                    </p>
                                                                )}

                                                            </div>
                                                            <div>
                                                                <p className="text-blueGray-600 text-sm">{Constants.currency + " " + order.totalAmount}</p>
                                                                <p className="font-green text-sm">{Constants.currency + " " + order.paymentReceived}</p>
                                                                {(order.totalAmount - order.paymentReceived) > 0 && (
                                                                    <p className="text-blueGray-600 font-bold text-sm">
                                                                        {Constants.currency + " " + (order.totalAmount - order.paymentReceived)}
                                                                    </p>
                                                                )}
                                                            </div>
                                                            {(order.totalAmount - order.paymentReceived) > 0 && !order.isOrderCancelled && (
                                                                <p className="text-xs mt-3">Please pay pending amount to get your order processed instantly.</p>
                                                            )}
                                                        </div>
                                                    </div>


                                                </div>
                                            })}
                                            {shouldShowLoadMore && (
                                            <div className="mt-8 flex flex-wrap text-center justify-center">
                                                <button
                                                    className="bg-orange-500 text-white active:bg-lightBlue-600 font-bold uppercase text-md p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                    type="button"
                                                    onClick={() => fetchMoreData()}
                                                >
                                                    <i className="fa fa-shopping-cart" aria-hidden="true"></i>&nbsp;&nbsp; Load More Orders
                                                </button>
                                            </div>
                                            )}
                                            {!shouldShowLoadMore && (
                                                <div className="mt-8">
                                                    <p className="text-center">Thats All !!</p>
                                                </div>
                                            )}

                                        </div>

                                        <div className="w-full mt-8 flex flex-wrap justify-center">
                                            <div className="pt-6 border-t">
                                                <p className="text-center text-blueGray-400">
                                                    Products can be delivered only in pincode <b>{Constants.pincode}</b> covering below areas :
                                                </p>
                                                <p className="text-center mt-2 text-blueGray-500">
                                                    {FormatHelper.formatAreasWithCommaAnd(Constants.areas)}
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                )}


                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </>
            )}
            <Footer />

        </>
    );
}