import {React,useState,useEffect} from "react";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {sendGetRequestAll, sendPostRequestAll} from "../../helpers/httprequesthelper";
import * as Constants from "constants/constants.js";
import {Modal} from "react-responsive-modal";
import {HttpStatusCode} from "axios";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

export default function AddNewProduct() {
    var fetchBrandDataRetryCount = 0;
    var fetchCategoryDataRetryCount = 0;
    const maxRetryCount = 5;
    const [brandsData, setBrandsData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [title, setTitle] = useState(null);
    const [id, setId] = useState(null);
    const [mediaLink, setMediaLink] = useState(null);
    const [barcode, setBarcode] = useState(null);
    const [openCamera, setOpenCamera] = useState(false);
    const [file, setFile] = useState();
    const [imageUploadedUrl, setImageUploadedUrl] = useState(null);
    var subtitle = null;
    var description = null;
    var starRating = null;
    var quantity = null;
    var maxQuantityPerOrder = null;
    var mrp = null;
    var costPrice = null;
    var sellingPrice = null;
    var lowestSellingPrice = null;
    var wholesalePrice = null;

    const onFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const onFileUpload = async (general) => {

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + Constants.imgurClientId);

        var formdata = new FormData();
        formdata.append("image", file, file.name);
        formdata.append("type", "image");
        formdata.append("title", "Simple upload");
        formdata.append("description", "This is a simple image upload in Imgur");

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: formdata,
          redirect: 'follow'
        };
        fetch("https://api.imgur.com/3/upload", requestOptions)
          .then(response => response.text())
          .then(result => {
            console.log(result)
            console.log(result.success)
            if (result && result.success == true && result.data && result.data.link) {
                if (general) {
                    setImageUploadedUrl(result.data.link)
                } else {
                    setMediaLink(result.data.link)
                }
            } else {
              alert(result)
            }
          })
          .catch(error => console.log('error', error));

//        var response = await fetch("https://api.imgur.com/3/image", requestOptions)
//          .then(result => {
//                console.log(result.text())
//                console.log(result.data)
//                console.log(result.data.link)
//                if (result.data && result.data.link) {
//                    if (general) {
//                        setImageUploadedUrl(result.link)
//                    } else {
//                        setMediaLink(result.link)
//                    }
//                } else {
//                    alert(result)
//                }
//              })
//          .catch(error => {
//            console.log('error', error)
//            alert(error);
//          });
    };

    function onCloseErrorModal() {
        setOpenErrorModal(false);
        setErrorMessage(null);
    }

    function onCloseSuccessModal() {
        setOpenSuccessModal(false);
        setSuccessMessage(null);
    }

    const populateId = (e) => {
        var id = e.target.value.trim().replace(/ /g,"_").replace(/[^\w\s]/gi, '').toLowerCase();
        setId(id);
    }

    async function handleSubmit(e) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        var formJson = Object.fromEntries(formData.entries());
        if(!formJson.title || !formJson.title.trim()) {
            setErrorMessage("Title is empty");
            setOpenErrorModal(true);
            return;
        }
        if(!formJson.subtitle || !formJson.subtitle.trim()) {
            setErrorMessage("Subtitle is empty");
            setOpenErrorModal(true);
            return;
        }
        if(!formJson.description || !formJson.description.trim()) {
            setErrorMessage("Description is empty");
            setOpenErrorModal(true);
            return;
        }
        if(!formJson.starRating || !formJson.starRating.trim()) {
            setErrorMessage("Star Rating is empty");
            setOpenErrorModal(true);
            return;
        } else if (formJson.starRating <= 0 || formJson.starRating >=5) {
            setErrorMessage("Star Rating is not in range (0.1 - 5.0)");
            setOpenErrorModal(true);
            return;
        }
        if(!formJson.mediaLink || !formJson.mediaLink.trim()) {
            setErrorMessage("Media Link (First Image) is empty");
            setOpenErrorModal(true);
            return;
        }
        if(!formJson.quantity || !formJson.quantity.trim()) {
            setErrorMessage("Quantity is empty");
            setOpenErrorModal(true);
            return;
        } else if (formJson.quantity < 0) {
            setErrorMessage("Quantity can't be less than 0");
            setOpenErrorModal(true);
            return;
        }

        if(!formJson.mrp || !formJson.mrp.trim()) {
            setErrorMessage("Mrp is empty");
            setOpenErrorModal(true);
            return;
        } else if (formJson.mrp <= 0) {
            setErrorMessage("MRP is zero or less than zero");
            setOpenErrorModal(true);
            return;
        }

        if(!formJson.sellingPrice || !formJson.sellingPrice.trim()) {
            setErrorMessage("Selling Price is empty");
            setOpenErrorModal(true);
            return;
        } else if (formJson.sellingPrice <= 0) {
            setErrorMessage("Selling Price is zero or less than zero");
            setOpenErrorModal(true);
            return;
        }

        if(!formJson.maxQuantityPerOrder || !formJson.maxQuantityPerOrder.trim()) {
            formJson.maxQuantityPerOrder = null;
        }

        if(!formJson.costPrice || !formJson.costPrice.trim()) {
            setErrorMessage("Cost Price is empty");
            setOpenErrorModal(true);
            return;
        } else if (formJson.costPrice <= 0) {
            setErrorMessage("Cost Price is zero or less than zero");
            setOpenErrorModal(true);
            return;
        }

        if(!formJson.lowestSellingPrice || !formJson.lowestSellingPrice.trim()) {
            setErrorMessage("Lowest Selling Price is empty");
            setOpenErrorModal(true);
            return;
        } else if (formJson.lowestSellingPrice <= 0) {
            setErrorMessage("Lowest Selling Price is zero or less than zero");
            setOpenErrorModal(true);
            return;
        }



        if(!formJson.wholesalePrice || !formJson.wholesalePrice.trim()) {
            setErrorMessage("Wholesale Price is empty");
            setOpenErrorModal(true);
            return;
        } else if (formJson.wholesalePrice <= 0) {
            setErrorMessage("Wholesale Price is zero or less than zero");
            setOpenErrorModal(true);
            return;
        }

        var favouriteCheckbox = document.getElementById("isFavourite");
        if (favouriteCheckbox.checked == true){
            formJson.favourite = true;
        } else {
            formJson.favourite = false;
        }

        var originalCheckbox = document.getElementById("isOriginal");
        if (originalCheckbox.checked == true){
            formJson.isOriginal = true;
        } else {
            formJson.isOriginal = false;
        }

        var availableCheckbox = document.getElementById("isAvailable");
        if (availableCheckbox.checked == true){
            formJson.isAvailable = true;
        } else {
            formJson.isAvailable = false;
        }

        var freeDeliveryCheckbox = document.getElementById("isFreeDeliveryEligible");
        if (freeDeliveryCheckbox.checked == true){
            formJson.isFreeDeliveryEligible = true;
        } else {
            formJson.isFreeDeliveryEligible = false;
        }

        if (!selectedBrand) {
            setErrorMessage("Brand is not selected");
            setOpenErrorModal(true);
            return;
        } else {
            formJson.brandId = selectedBrand.value;
        }

        if (!selectedCategory) {
            setErrorMessage("Category is not selected");
            setOpenErrorModal(true);
            return;
        } else {
            formJson.categoryId = selectedCategory.value;
        }
        var bulletPoints = [];
        var bulletPointsElements = document.getElementsByName("bulletpoints")
        bulletPointsElements.forEach(function (element, index) {
            if (element.value && element.value.trim()) {
                bulletPoints.push(element.value);
            }
        });
        formJson.bulletPoints = bulletPoints;


        var imageUrls = [];
        var imageUrlsElements = document.getElementsByName("imageUrls")
        imageUrlsElements.forEach(function (element, index) {
            if (element.value && element.value.trim()) {
                imageUrls.push(element.value);
            }
        });
        formJson.imageUrls = imageUrls;

        var videoUrls = [];
        var videoUrlsElements = document.getElementsByName("videoUrls")
        videoUrlsElements.forEach(function (element, index) {
            if (element.value && element.value.trim()) {
                videoUrls.push(element.value);
            }
        });
        formJson.videoUrls = videoUrls;

        if(formJson.barcode && formJson.barcode.trim()) {
            formJson.barcode = barcode;
        }

        console.log(formJson)

        try {
            var response = await sendPostRequestAll(Constants.backendUrl + "/admin/product", formJson, true);

            if (response.status == HttpStatusCode.Ok) {
                if (response.data != null) {
                    setSuccessMessage("Product Added successfully");
                    setOpenSuccessModal(true);
                    console.log(response.data);
                }
            } else {
                setErrorMessage(response.data);
                setOpenErrorModal(true);
            }
            console.log("Here the response : ");
            console.log(response);
        } catch (err) {
            console.log("Error in submitting the product data : ");
            console.error(err);
        }
    }

    function onOpenCamera() {
        setOpenCamera(true);
    }

    function onCloseCamera() {
        setOpenCamera(false);
    }
    const addBulletPoint = () => {
        document.getElementById("bullet_point_div")
        .insertAdjacentHTML("beforeend",
            `<input
               type="text"
               class="border-0 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               name="bulletpoints"
            />`);
    }

    const addImageUrls = () => {
        document.getElementById("image_urls_div")
        .insertAdjacentHTML("beforeend",
            `<input
               type="text"
               class="border-0 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               name="imageUrls"
            />`);
    }

    const addVideoUrls = () => {
        document.getElementById("video_urls_div")
        .insertAdjacentHTML("beforeend",
            `<input
               type="text"
               class="border-0 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               name="videoUrls"
            />`);
    }

    const fetchBrandData = async () => {
        try {
            var response = await sendGetRequestAll(Constants.backendUrl + "/admin/brand", true);
            if (response.data != null) {
                setBrandsData(getBrandOptions(response.data))
            }
        } catch (err) {
            console.error(err);
            console.error("Error Occurred while fetchBrandData");
            setBrandsData([]);
            if (err instanceof TypeError && fetchBrandDataRetryCount < maxRetryCount) {
                console.log("Retrying");
                fetchBrandDataRetryCount = fetchBrandDataRetryCount + 1;
                setTimeout(fetchBrandData, 1000);
            }
        }
    };

    const fetchCategoryData = async () => {
        try {
            var response = await sendGetRequestAll(Constants.backendUrl + "/admin/category?level=2", true);
            if (response.data != null) {
                setCategoryData(getCategoriesOption(response.data))
            }
        } catch (err) {
            console.error(err);
            console.error("Error Occurred while fetchCategoryData");
            setCategoryData([]);
            if (err instanceof TypeError && fetchCategoryDataRetryCount < maxRetryCount) {
                console.log("Retrying");
                fetchCategoryDataRetryCount = fetchCategoryDataRetryCount + 1;
                setTimeout(fetchCategoryData, 1000);
            }
        }
    };

    function getBrandOptions(data) {
        var brandsOption = [];
        if (data != null) {
            data.forEach(function (brand, index) {
              brandsOption.push({value : brand.id, label : brand.title})
            });
        }
        return brandsOption;
    }

    function getCategoriesOption(data) {
        var categoriesOption = [];
        if (data != null) {
            data.forEach(function (category, index) {
              categoriesOption.push({value : category.id, label : category.title})
            });
        }
        return categoriesOption;
    }

     useEffect(() => {
            fetchBrandData();
            fetchCategoryData();
     }, []);



    return (
        <>
            <div className="flex-auto px-4 pt-0">
            <Modal open={openErrorModal} onClose={onCloseErrorModal} center>
                <h2 className="text-xl mb-4 font-bold border-b pb-2">Validation error &nbsp; &nbsp; &nbsp; &nbsp;</h2>
                <p>{errorMessage}</p>
            </Modal>
            <Modal open={openSuccessModal} onClose={onCloseSuccessModal} center>
                <h2 className="text-xl mb-4 font-bold border-b pb-2">Operation successful &nbsp; &nbsp; &nbsp; &nbsp;</h2>
                <p>{successMessage}</p>
            </Modal>
                <h6 className="text-blueGray-700 text-md mt-3 mb-6 font-bold uppercase">
                 Add new Product
                </h6>
              <form onSubmit={handleSubmit}>
                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Product Details
                </h6>
                <div className="flex flex-wrap">
                    <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Image Uploader
                          </label>
                          <input
                              name="file"
                              type="file"
                              onChange={onFileChange}
                          />
                          <button className="ml-2 mb-2 text-white bg-lightBlue-600 font-bold uppercase text-sm p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button" onClick={() => onFileUpload(true)}>Upload</button>
                          {imageUploadedUrl != null && (
                            <p className="mt-2 mb-8">{imageUploadedUrl}</p>
                          )}

                      </div>
                    </div>
                   <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Product Id (Unique *)
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        defaultValue=""
                        name="id" value={id}
                      />
                    </div>
                  </div>

                  <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Product Title (*)
                      </label>
                      <input
                        type="text"
                        onChange={(e) => {populateId(e)}}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        defaultValue=""
                        name="title" value={title}
                      />
                    </div>
                  </div>
                  <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Subtitle (*)
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        defaultValue=""
                        name="subtitle" value={subtitle}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Description (*)
                    </label>
                    <textarea
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      defaultValue=""
                      rows="4"
                      name="description" value={description}
                    ></textarea>
                  </div>
                </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Star Rating (Out of 5 in Decimal *)
                      </label>
                      <input
                        type="number"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        defaultValue={4.5}
                        max="5.0"
                        name="starRating" step="0.1" value={starRating}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Quantity (Zero or more *)
                      </label>
                      <input
                        type="number"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        defaultValue={1}
                        min="0" max="1000"
                        name="quantity" value={quantity}
                      />
                    </div>
                  </div>
                </div>

                <hr className="mt-6 border-b-1 border-blueGray-300" />
                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Pricing (Mandatory)
                </h6>
                <div className="flex flex-wrap">
                    <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            MRP (Number *)
                          </label>
                          <input
                            type="number"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            min="1"
                            name="mrp" value={mrp}
                          />
                        </div>
                    </div>
                    <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Cost Price (Number *)
                          </label>
                          <input
                            type="number"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            min="1"
                            name="costPrice" value={costPrice}
                          />
                        </div>
                    </div>
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Selling Price (Number *)
                        </label>
                        <input
                          type="number"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          min="1"
                          name="sellingPrice" value={sellingPrice}
                        />
                      </div>
                    </div>

                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Lowest Selling Price (Number *)
                        </label>
                        <input
                          type="number"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          min="1"
                          name="lowestSellingPrice" value={lowestSellingPrice}
                        />
                      </div>
                    </div>

                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Wholesale Price (Number *)
                        </label>
                        <input
                          type="number"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          min="1"
                          name="wholesalePrice" value={wholesalePrice}
                        />
                      </div>
                    </div>
                </div>

                <hr className="mt-6 border-b-1 border-blueGray-300" />
                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Bullet Points
                </h6>
                <div className="relative w-full mb-3 mt-4 px-4">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Bullet Points
                  </label>
                  <div id="bullet_point_div">
                      <input
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          name="bulletpoints"
                       />
                   </div>
                   <button className="btn-outline mt-3" type="button" onClick={addBulletPoint}>Add More Point</button>
                </div>

                <hr className="mt-6 border-b-1 border-blueGray-300" />

                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Images
                </h6>
                <div className="relative w-full mb-3 mt-4 px-4">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Media Link (First Image *)
                  </label>
                  <input
                            name="file"
                            type="file"
                            onChange={onFileChange}
                        />
                        <button className="ml-2 mb-4 text-white bg-lightBlue-600 font-bold uppercase text-sm p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button" onClick={() => onFileUpload(false)}>Upload</button>
                  <div>
                      <input
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          name="mediaLink"
                          value={mediaLink}
                          onChange={e => setMediaLink(e.target.value)}
                       />
                   </div>
                </div>

                <div className="relative w-full mb-3 mt-4 px-4">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    More Images Urls
                  </label>
                  <div id="image_urls_div">
                      <input
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          name="imageUrls"
                       />
                   </div>
                   <button className="btn-outline mt-3" type="button" onClick={addImageUrls}>Add More Images</button>
                </div>

                <hr className="mt-6 border-b-1 border-blueGray-300" />

                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Videos
                </h6>

                <div className="relative w-full mb-3 mt-4 px-4">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Video Urls
                  </label>
                  <div id="video_urls_div">
                      <input
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          name="videoUrls"
                       />
                   </div>
                   <button className="btn-outline mt-3" type="button" onClick={addVideoUrls}>Add More Videos</button>
                </div>

                <hr className="mt-6 border-b-1 border-blueGray-300" />



                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Brand, Category and Merchant details
                </h6>

                <div className="relative w-full mb-3 mt-4 px-4">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Brand
                  </label>
                  <Dropdown options={brandsData} onChange={setSelectedBrand} placeholder="Select an option" />
                </div>

                <div className="relative w-full mb-3 mt-4 px-4">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Category
                  </label>
                  <Dropdown options={categoryData} onChange={setSelectedCategory} placeholder="Select an option" />
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />

                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Other Attributes (Optional)
                </h6>
                 <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Mark as Favourite &nbsp;&nbsp; <input type="checkbox" id="isFavourite" name="isFavourite" defaultChecked={false} />
                      </label>

                    </div>
                    <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Is Original &nbsp;&nbsp; <input type="checkbox" id="isOriginal" name="isOriginal" defaultChecked={true} />
                        </label>

                      </div>
                      <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Is Available &nbsp;&nbsp; <input type="checkbox" id="isAvailable" name="isAvailable" defaultChecked={true} />
                          </label>

                        </div>
                      <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Is Free Delivery Eligible &nbsp;&nbsp; <input type="checkbox" id="isFreeDeliveryEligible" name="isFreeDeliveryEligible" defaultChecked={true} />
                          </label>

                        </div>

                        <div className="relative w-full mb-3 mt-4">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              Max Quantity Per Order
                            </label>
                            <input
                              type="number"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              min="0" max="1000"
                              name="maxQuantityPerOrder" value={maxQuantityPerOrder}
                            />
                        </div>

                        <div className="relative w-full mb-3 mt-4">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              Barcode
                            </label>
                            {openCamera && (
                            <BarcodeScannerComponent
                                width={400}
                                height={400}
                                onUpdate={(err, result) => {
                                  if (result) setBarcode(result.text);
                                  else setBarcode(null);
                                }}
                              />
                            )}
                             {!openCamera && (
                                <button
                                    className="bg-indigo-500 mt-2 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={onOpenCamera}
                                  >
                                    Open Camera
                                  </button>
                            )}
                            {openCamera && (
                                <button
                                  className="bg-indigo-500 mt-2 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                  type="button"
                                  onClick={onCloseCamera}
                                >
                                  Close Camera
                                </button>
                            )}
                            <input
                                type="text"
                                className="border-0 mt-3 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                value={barcode} onChange={e => setBarcode(e.target.value)}
                              />

                        </div>


                  </div>



                <hr className="mt-6 border-b-1 border-blueGray-300" />

<div className="w-full mt-4 mb-3">
                        <button
                            className="bg-primary text-white font-bold uppercase text-sm px-4 p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="submit"
                        >
                            Add Product
                        </button>
                    </div>

              </form>
            </div>
        </>
    )
}