import IndexNavbar from "../../components/Navbars/IndexNavbar";
import Footer from "../../components/Footers/Footer";
import { Link } from "react-router-dom";
import * as Constants from "../../constants/constants";

export default function ComingSoonView() {
    return (
        <>
            <IndexNavbar />
            <section className="relative block h-400-px">
                <div
                    className="absolute top-0 w-full h-full bg-center bg-cover"
                    style={{
                        backgroundImage:
                            "url(" + Constants.comingSoonViewBackgroundImage + ")",
                    }}
                >
            <span
                id="blackOverlay"
                className="w-full h-full absolute opacity-50 bg-black"
            ></span>
                </div>
                <div
                    className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                    style={{ transform: "translateZ(0)" }}
                >
                    <svg
                        className="absolute bottom-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="text-blueGray-200 fill-current"
                            points="2560 0 2560 100 0 100"
                        ></polygon>
                    </svg>
                </div>
            </section>


            <section className="relative py-16 bg-blueGray-200">
                <div className="container mx-auto px-2">
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-68">
                       <div className="px-2 mt-2">
                            <div className="flex flex-wrap justify-center">
                                <img src="https://cdn.dribbble.com/users/1384833/screenshots/4255610/company_teaser_email3.gif" className="coming-soon-gif-image" />
                            </div>
                       </div>
                       <div>
                       <h1 className="text-center text-3xl mt-2 font-bold">Coming Soon</h1>
                            <p className="text-center mt-2 mb-2">While we develop this space, please checkout our store !!</p>
                            <div className="flex flex-wrap justify-center mb-8 mt-6">
                                <Link to="/store">
                                    <button
                                        className="bg-primary text-white active:bg-lightBlue-600 font-bold uppercase text-md px-4 p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                        type="button"
                                    >
                                        <i className="fa fa-solid fa-store"></i> &nbsp;&nbsp; Visit Store
                                    </button>
                                </Link>

                                <Link to="/store">
                                    <button
                                        className="bg-emerald-500 text-white active:bg-emerald-400 font-bold uppercase text-md px-4 ml-3 p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                        type="button"
                                    >
                                        <i className="fa fa-home"></i> &nbsp;&nbsp; Go Home
                                    </button>
                                </Link>
                            </div>
                       </div>
                    </div>
                </div>
            </section>

             <Footer />
        </> );
}