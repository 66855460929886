import { Link } from "react-router-dom";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import React from "react";
import ReactPlayer from 'react-player'
import PlacardHeader from "../Headers/PlacardHeader";

export default function VideoCarousel(props) {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        laptop: {
            breakpoint: { max: 1023, min: 860 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 860, min: 464 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <div className="card p-2 pb-4 mx-1">
            <PlacardHeader title={props.title} subtitle={props.subtitle} description={props.description} viewAllLink={props.viewAllLink}/>
            <Carousel autoPlay="true" interval="2000" swipeable={true}
                      draggable={true} responsive={responsive} infinite={true} showThumbs={false}>
                {props.data!=null && props.data.map(function(dataelement, index){
                    return <div className="w-full" key={dataelement.id}>
                        <div
                            className="flex flex-wrap w-full mb-2 p-3 ">

                            <ReactPlayer url={dataelement.mediaLink} light={true} controls={true} />

                        </div>
                    </div>
                })}
            </Carousel>
        </div>
    );
}