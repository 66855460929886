import {React,useState,useEffect} from "react";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {sendGetRequestAll, sendPostRequestAll, sendDeleteRequestAll} from "../../helpers/httprequesthelper";
import {Modal} from "react-responsive-modal";
import {HttpStatusCode} from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import Multiselect from 'multiselect-react-dropdown';
import { auth, db } from "helpers/firebase.js";
import * as Constants from "constants/constants.js";
import {useHistory} from "react-router-dom";

export default function UpdateUser() {
    const [showForm, setShowForm] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [name, setName] = useState(null);
    const [id, setId] = useState(null);
    const [data, setData] = useState(null);
    const [searchId, setSearchId] = useState(null);
    const [userGender, setUserGender] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [area, setArea] = useState(null);
    const [roles, setRoles] = useState([]);
    const [pincode, setPincode] = useState(null);
    const [userMembershipType, setUserMembershipType] = useState(null);
    const [joiningDate, setJoiningDate] = useState(null);
    const [multiRoleOptions, setMultiRoleOptions] = useState(null);
    const [age, setAge] = useState(null);
    const [user, loading, error] = useAuthState(auth);
    const navigate = useHistory();

    function onCloseErrorModal() {
        setOpenErrorModal(false);
        setErrorMessage(null);
    }

    function onCloseSuccessModal() {
        setOpenSuccessModal(false);
        setSuccessMessage(null);
    }

    async function handleSearchById(e) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        var formJson = Object.fromEntries(formData.entries());
        if(!formJson.searchId || !formJson.searchId.trim()) {
            setErrorMessage("Search Id is empty");
            setOpenErrorModal(true);
            return;
        }
        console.log("formJson");
        console.log(formJson)
        await fetchUserDataById(formJson.searchId);
    }

    function getGenderOptions() {
        var options = [];
        options.push({value : "MALE", label : "MALE"})
        options.push({value : "FEMALE", label : "FEMALE"})
        options.push({value : "OTHER", label : "OTHER"})
        return options;
    }

    function getMultiMembershipTypeOption() {
        var options = [];
        Constants.userMembershipType.forEach(function (item, index) {
          options.push({value : item, label : item})
        });
        return options;
    }

    function getMultiAreasOption() {
        var options = [];
        Constants.userAreas.forEach(function (item, index) {
          options.push({value : item, label : item})
        });
        return options;
    }

    function getMultiRolesOption() {
        var options = [];
        Constants.roles.forEach(function (item, index) {
          options.push({name : item, id : item})
        });
        return options;
    }

    async function handleSearchByPhone(e) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        var formJson = Object.fromEntries(formData.entries());
        if(!formJson.searchId || !formJson.searchId.trim()) {
            setErrorMessage("Search Id is empty");
            setOpenErrorModal(true);
            return;
        }
        console.log("formJson");
        console.log(formJson)
        await fetchUserDataByPhoneNumber(formJson.searchId);
    }


    async function fetchUserDataByPhoneNumber(searchId) {
        try {
            var response = await sendGetRequestAll(Constants.backendUrl + "/admin/user/phonenumber/" + searchId, true);

            if (response.status == HttpStatusCode.Ok) {
                if (response.data) {
                    console.log("Hello : " + JSON.stringify(response.data));
                    await updateFormData(response.data);
                } else {
                    setShowForm(false);
                }
            } else {
                setErrorMessage(response.data);
                setOpenErrorModal(true);
                setShowForm(false);
            }
            console.log("Here the response : ");
            console.log(response);
        } catch (err) {
            console.log("Error in getting the user data : ");
            console.error(err);
            setShowForm(false);
            setOpenErrorModal(true);
            setErrorMessage(err.response.data);
        }
    }

    async function fetchUserDataById(searchId) {
        try {
            var response = await sendGetRequestAll(Constants.backendUrl + "/admin/user/" + searchId, true);

            if (response.status == HttpStatusCode.Ok) {
                if (response.data) {
                    console.log("Hello : " + JSON.stringify(response.data));
                    await updateFormData(response.data);
                } else {
                    setShowForm(false);
                }
            } else {
                setErrorMessage(response.data);
                setOpenErrorModal(true);
                setShowForm(false);
            }
            console.log("Here the response : ");
            console.log(response);
        } catch (err) {
            console.log("Error in getting the user data : ");
            console.error(err);
            setOpenErrorModal(true);
            setShowForm(false);
            setErrorMessage(err.response.data);
        }
    }

    async function updateFormData(data) {
        console.log("updateFormData : " + JSON.stringify(data));
        setId(data.id);
        setName(data.name);
        setPhoneNumber(data.phoneNumber);
        setUserGender({value : data.userGender, label : data.userGender});
        setUserMembershipType({value : data.userMembershipType, label : data.userMembershipType});
        setAge(data.age);
        if (data.roles) {
            setRoles(getMultiOptionFromArray(data.roles));
        }
        if (data.area) {
            setArea({value : data.area, label : data.area})
        }
        setShowForm(true);
        setData(data);
    }

    function getArrayFromSelectedMultipleOptions(data) {
        var arr = [];
        if (!data) {
            return null;
        }
        data.forEach(function(item, index) {
            arr.push(item.id);
        })
        return arr;
    }

     function getMultiOptionFromArray(data) {
        var options = [];
        if (data != null) {
            data.forEach(function (id, index) {
              options.push({name : id, id : id})
            });
        }
        return options;
    }

    async function handleSubmit(e) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        var formJson = Object.fromEntries(formData.entries());
        if(!formJson.id || !formJson.id.trim()) {
            setErrorMessage("Id is empty");
            setOpenErrorModal(true);
            return;
        }
        if(!formJson.name || !formJson.name.trim()) {
            setErrorMessage("Name is empty");
            setOpenErrorModal(true);
            return;
        }
        if(!formJson.age || !formJson.age.trim()) {
            setErrorMessage("Age is empty");
            setOpenErrorModal(true);
            return;
        }
        if(!formJson.phoneNumber || !formJson.phoneNumber.trim()) {
            setErrorMessage("Phone Number is empty");
            setOpenErrorModal(true);
            return;
        }
        if(!userGender) {
            setErrorMessage("Gender is empty");
            setOpenErrorModal(true);
            return;
        }
        if(!userMembershipType) {
            setErrorMessage("UserMembershipType is empty");
            setOpenErrorModal(true);
            return;
        }
        if (roles != null) {
            formJson.roles = getArrayFromSelectedMultipleOptions(roles);
        }
        formJson.userGender = userGender.value;
        formJson.userMembershipType = userMembershipType.value;
        formJson.area = area.value;

        console.log(formJson)

        try {
            var response = await sendPostRequestAll(Constants.backendUrl + "/admin/user/update", formJson, true);

            if (response.status == HttpStatusCode.Ok) {
                if (response.data != null) {
                    setSuccessMessage("User Updated successfully");
                    setOpenSuccessModal(true);
                    console.log(response.data);
                }
            } else {
                setErrorMessage(response.data);
                setOpenErrorModal(true);
            }
            console.log("Here the response : ");
            console.log(response);
        } catch (err) {
            console.log("Error in submitting the user data : ");
            console.error(err);
        }
    }

    const levelOptions = [
      0,1,2
    ];

     useEffect(async() => {
         if (loading) return;
         if (!user) return navigate.push("/");
         setMultiRoleOptions(getMultiRolesOption());
         const queryParams = new URLSearchParams(window.location.search);
         const phone = queryParams.get('phone');
         if (phone) {
             console.log("phone : " + phone);
             await fetchUserDataByPhoneNumber(phone.trim());
         }
         const uid = queryParams.get('id');
          if (uid) {
              console.log("uid : " + uid);
              await fetchUserDataById(uid);
          }
     }, [user, loading])

    return (
        <>
            <div className="flex-auto px-4 pt-0">
            <Modal open={openErrorModal} onClose={onCloseErrorModal} center>
                <h2 className="text-xl mb-4 font-bold border-b pb-2">Oops, An Error Occurred ! &nbsp; &nbsp; &nbsp; &nbsp;</h2>
                <p>{errorMessage}</p>
            </Modal>
            <Modal open={openSuccessModal} onClose={onCloseSuccessModal} center>
                <h2 className="text-xl mb-4 font-bold border-b pb-2">Operation successful &nbsp; &nbsp; &nbsp; &nbsp;</h2>
                <p>{successMessage}</p>
            </Modal>


                <h6 className="text-blueGray-700 text-md mt-3 mb-6 font-bold uppercase">
                 Update User
                </h6>
              {showForm && (
              <form onSubmit={handleSubmit}>
                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  User Details
                </h6>
                <div className="flex flex-wrap">
                   <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        User Id (Unique *)
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        readOnly = {true}
                        name="id" value={id}
                      />
                    </div>
                  </div>

                  <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Name (*)
                      </label>
                      <input
                        type="text"
                        onChange={e => setName(e.target.value)}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        name="name" value={name}
                      />
                    </div>
                  </div>

                  <div className="w-full px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Phone Number (*)
                        </label>
                        <input
                          type="text"
                          onChange={e => setPhoneNumber(e.target.value)}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          name="phoneNumber" value={phoneNumber}
                        />
                      </div>
                  </div>

                  <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Age (Number *)
                        </label>
                        <input
                          type="number"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          min="1"
                          max="98"
                          onChange={e => setAge(e.target.value)}
                          name="age" value={age}
                        />
                      </div>
                  </div>

                 <div className="relative w-full mb-3 mt-4 px-4">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Gender
                      </label>
                      <Dropdown options={getGenderOptions()} onChange={setUserGender} value={userGender} placeholder="Select an option" />
                    </div>



                    <div className="w-full px-4">
                      <div className="relative w-full mb-3 mt-4">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Roles
                        </label>
                        <Multiselect
                          selectedValues={roles}
                          options={multiRoleOptions}
                          onSelect={setRoles}
                          onRemove={setRoles}
                          displayValue="name"
                          />
                      </div>
                    </div>

                    <div className="relative w-full mb-3 mt-4 px-4">
                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        User Membership Type
                      </label>
                      <Dropdown options={getMultiMembershipTypeOption()} onChange={setUserMembershipType} value={userMembershipType} placeholder="Select an option" />
                    </div>

                    <div className="relative w-full mb-3 mt-4 px-4">
                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Area
                      </label>
                      <Dropdown options={getMultiAreasOption()} onChange={setArea} value={area} placeholder="Select an option" />
                    </div>




                </div>


                <hr className="mt-6 border-b-1 border-blueGray-300" />


                <div className="w-full mt-4 mb-3">
                    <button
                        className="bg-primary text-white font-bold uppercase text-sm px-4 p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="submit"
                    >
                        Update User
                    </button>
                </div>

              </form>
              )}

              <hr className="mt-6 border-b-1 border-blueGray-300" />
                <div className="card p-4 mt-4 bg-white">
                <h2 className="text-xl mb-4 font-bold pb-2">Search User by ID</h2>
                <form onSubmit={handleSearchById} className="mt-3">
                    <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        name="searchId" value={searchId}
                      />
                    <button
                        className="bg-primary mt-4 text-white font-bold uppercase text-sm px-4 p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="submit"
                    >
                        <i class="fa fa-search" aria-hidden="true"></i> &nbsp; &nbsp; Search User
                    </button>
                </form>
                </div>

                <hr className="mt-6 border-b-1 border-blueGray-300" />
                <div className="card p-4 mt-4 bg-white">
                <h2 className="text-xl mb-4 font-bold pb-2">Search User by Phone number</h2>
                <form onSubmit={handleSearchByPhone} className="mt-3">
                    <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        name="searchId" value={searchId}
                      />
                    <button
                        className="bg-primary mt-4 text-white font-bold uppercase text-sm px-4 p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="submit"
                    >
                        <i class="fa fa-search" aria-hidden="true"></i> &nbsp; &nbsp; Search User
                    </button>
                </form>
                </div>
            </div>
        </>
    )
}