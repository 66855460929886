import {React,useState,useEffect} from "react";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {sendGetRequestAll, sendPostRequestAll} from "../../helpers/httprequesthelper";
import * as Constants from "constants/constants.js";
import {Modal} from "react-responsive-modal";
import {HttpStatusCode} from "axios";
import Multiselect from 'multiselect-react-dropdown';

export default function AddNewCategory() {
    var fetchCategoryDataRetryCount = 0;
    var fetchPlacardDataRetryCount = 0;
    const maxRetryCount = 5;
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [title, setTitle] = useState(null);
    const [placardIds, setPlacardIds] = useState(null);
    const [id, setId] = useState(null);
    const [level, setLevel] = useState(null);
    const [showParentCategoryInput, setShowParentCategoryInput] = useState(false);
    const [parentCategoryDataOptions, setParentCategoryDataOptions] = useState(null);
    const [childCategoryDataOptions, setChildCategoryDataOptions] = useState(null);
    const [placardDataOptions, setPlacardDataOptions] = useState(null);
    const [selectedLevel, setSelectedLevel] = useState(0);
    const [selectedParentCategory, setSelectedParentCategory] = useState(null);
    const [selectedChildCategory, setSelectedChildCategory] = useState(null);
    const [shouldShowChecked, setShouldShowChecked] = useState(true);
    var description;

    function onCloseErrorModal() {
        setOpenErrorModal(false);
        setErrorMessage(null);
    }

    function onCloseSuccessModal() {
        setOpenSuccessModal(false);
        setSuccessMessage(null);
    }

    const populateId = (e) => {
        var id = e.target.value.trim().replace(/ /g,"_").replace(/[^\w\s]/gi, '').toLowerCase();
        setId(id);
    }

    function selectChildCategories(selectedList, selectedItem) {
        var childCategories = [];
        selectedList.forEach(function (item, index) {
          childCategories.push(item.id);
        });
        setSelectedChildCategory(childCategories);
        console.log("childCategories : " + JSON.stringify(childCategories));
    }

    function selectPlacardIds(selectedList, selectedItem) {
        var placardIds = [];
        selectedList.forEach(function (item, index) {
          placardIds.push(item.id);
        });
        setPlacardIds(placardIds);
        console.log("placardIds : " + JSON.stringify(placardIds));
    }

    async function fetchPlacardData() {
        try {
            var response = await sendGetRequestAll(Constants.backendUrl + "/admin/placard", true);
            if (response.data != null) {
                setPlacardDataOptions(getMultiPlacardOption(response.data));
            }
        } catch (err) {
            console.error(err);
            console.error("Error Occurred while fetchPlacardData");
            if (err instanceof TypeError && fetchPlacardDataRetryCount < maxRetryCount) {
                console.log("Retrying fetchPlacardData");
                fetchPlacardDataRetryCount = fetchPlacardDataRetryCount + 1;
                setTimeout(fetchPlacardData, 1000);
            }
        }
    };

    async function fetchCategoryData(level) {
        try {
            var response = await sendGetRequestAll(Constants.backendUrl + "/admin/category?level=" + level, true);
            if (response.data != null) {
                return response.data;
            }
        } catch (err) {
            console.error(err);
            console.error("Error Occurred while fetchCategoryData");
            if (err instanceof TypeError && fetchCategoryDataRetryCount < maxRetryCount) {
                console.log("Retrying");
                fetchCategoryDataRetryCount = fetchCategoryDataRetryCount + 1;
                setTimeout(fetchCategoryData, 1000);
            }
        }
    };

    function getCategoriesOption(data) {
        var categoriesOption = [];
        if (data != null) {
            data.forEach(function (category, index) {
              categoriesOption.push({value : category.id, label : category.id})
            });
        }
        return categoriesOption;
    }

    function getMultiCategoriesOption(data) {
        var categoriesOption = [];
        if (data != null) {
            data.forEach(function (category, index) {
              categoriesOption.push({name : category.id, id : category.id})
            });
        }
        return categoriesOption;
    }

    function getMultiPlacardOption(data) {
        var placardOptions = [];
        if (data != null) {
            data.forEach(function (item, index) {
              placardOptions.push({name : item.id, id : item.id})
            });
        }
        return placardOptions;
    }

    const fetchParentChildCategories = async(e) => {
        setSelectedLevel(e.value);
        setSelectedParentCategory(null);
        setParentCategoryDataOptions(null);
        setSelectedChildCategory(null);
        setChildCategoryDataOptions(null);
        if (e.value == 1 || e.value == 2) {
            fetchCategoryDataRetryCount = 0;
            var parentCategories = await fetchCategoryData(e.value - 1);
            setParentCategoryDataOptions(getCategoriesOption(parentCategories));
        } else {
            setSelectedParentCategory(null);
            setParentCategoryDataOptions(null);
        }
        if (e.value == 0 || e.value == 1) {
            fetchCategoryDataRetryCount = 0;
            var childCategories = await fetchCategoryData(e.value + 1)
            setChildCategoryDataOptions(getMultiCategoriesOption(childCategories));
        } else {
            setSelectedChildCategory(null);
            setChildCategoryDataOptions(null);
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        var formJson = Object.fromEntries(formData.entries());
        if(!formJson.id || !formJson.id.trim()) {
            setErrorMessage("Id is empty");
            setOpenErrorModal(true);
            return;
        }
        if(!formJson.title || !formJson.title.trim()) {
            setErrorMessage("Title is empty");
            setOpenErrorModal(true);
            return;
        }
        if(selectedLevel == null) {
            setErrorMessage("Level is not selected");
            setOpenErrorModal(true);
            return;
        }
        if(selectedLevel > 0 && !selectedParentCategory) {
            setErrorMessage("Parent Category is not selected when level is > 0");
            setOpenErrorModal(true);
            return;
        }
        if(!formJson.mediaLink || !formJson.mediaLink.trim()) {
            setErrorMessage("Media Link is empty");
            setOpenErrorModal(true);
            return;
        }
        if(!formJson.description || !formJson.description.trim()) {
            setErrorMessage("Description is empty");
            setOpenErrorModal(true);
            return;
        }
        formJson.shouldShow = shouldShowChecked;
        formJson.parentCategoryId = selectedParentCategory;
        formJson.childCategoryIds = selectedChildCategory;
        formJson.plaCardIds = placardIds;
        formJson.level = selectedLevel;

        console.log(formJson)

        try {
            var response = await sendPostRequestAll(Constants.backendUrl + "/admin/category", formJson, true);

            if (response.status == HttpStatusCode.Ok) {
                if (response.data != null) {
                    setSuccessMessage("Category Added successfully");
                    setOpenSuccessModal(true);
                    console.log(response.data);
                }
            } else {
                setErrorMessage(response.data);
                setOpenErrorModal(true);
            }
            console.log("Here the response : ");
            console.log(response);
        } catch (err) {
            console.log("Error in submitting the category data : ");
            console.error(err);
        }
    }

    const addBulletPoint = () => {
        document.getElementById("bullet_point_div")
        .insertAdjacentHTML("beforeend",
            `<input
               type="text"
               class="border-0 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               name="bulletpoints"
            />`);
    }

    const addImageUrls = () => {
        document.getElementById("image_urls_div")
        .insertAdjacentHTML("beforeend",
            `<input
               type="text"
               class="border-0 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               name="imageUrls"
            />`);
    }

    const addVideoUrls = () => {
        document.getElementById("video_urls_div")
        .insertAdjacentHTML("beforeend",
            `<input
               type="text"
               class="border-0 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               name="videoUrls"
            />`);
    }

    const levelOptions = [
      0,1,2
    ];


     useEffect(() => {
        fetchPlacardData();
     }, []);



    return (
        <>
            <div className="flex-auto px-4 pt-0">
            <Modal open={openErrorModal} onClose={onCloseErrorModal} center>
                <h2 className="text-xl mb-4 font-bold border-b pb-2">Oops, An Error Occurred ! &nbsp; &nbsp; &nbsp; &nbsp;</h2>
                <p>{errorMessage}</p>
            </Modal>
            <Modal open={openSuccessModal} onClose={onCloseSuccessModal} center>
                <h2 className="text-xl mb-4 font-bold border-b pb-2">Operation successful &nbsp; &nbsp; &nbsp; &nbsp;</h2>
                <p>{successMessage}</p>
            </Modal>
                <h6 className="text-blueGray-700 text-md mt-3 mb-6 font-bold uppercase">
                 Add new Category
                </h6>
              <form onSubmit={handleSubmit}>
                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Category Details
                </h6>
                <div className="flex flex-wrap">
                   <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Category Id (Unique *)
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        defaultValue=""
                        name="id" value={id}
                      />
                    </div>
                  </div>

                  <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Category Title (*)
                      </label>
                      <input
                        type="text"
                        onChange={(e) => {populateId(e)}}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        defaultValue=""
                        name="title" value={title}
                      />
                    </div>
                  </div>
                  <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Level
                      </label>
                      <Dropdown options={levelOptions} onChange={(e) => {fetchParentChildCategories(e)}} placeholder="Select an option" />
                    </div>
                  </div>
                  {parentCategoryDataOptions != null && parentCategoryDataOptions.length > 0 && (
                    <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Parent Category
                          </label>
                          <Dropdown options={parentCategoryDataOptions} onChange={(e) => {setSelectedParentCategory(e.value)}} placeholder="Select an option" />
                        </div>
                      </div>
                  )}

                  {childCategoryDataOptions != null && childCategoryDataOptions.length > 0 && (

                    <div className="w-full px-4">
                      <div className="relative w-full mb-3 mt-4">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Child Categories
                        </label>
                        <Multiselect
                          options={childCategoryDataOptions}
                          onSelect={selectChildCategories}
                          onRemove={selectChildCategories}
                          displayValue="name"
                          />
                      </div>
                    </div>

                  )}

                    {placardDataOptions != null && placardDataOptions.length > 0 && (

                        <div className="w-full px-4">
                          <div className="relative w-full mb-3 mt-4">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              Placard Ids
                            </label>
                            <Multiselect
                              options={placardDataOptions}
                              onSelect={selectPlacardIds}
                              onRemove={selectPlacardIds}
                              displayValue="name"
                              />
                          </div>
                        </div>

                      )}

                  <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Description (*)
                    </label>
                    <textarea
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      defaultValue=""
                      rows="4"
                      name="description" value={description}
                    ></textarea>
                  </div>
                </div>
                </div>

                <hr className="mt-6 border-b-1 border-blueGray-300" />

                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Images
                </h6>
                <div className="relative w-full mb-3 mt-4 px-4">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Media Link (*)
                  </label>
                  <div>
                      <input
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          name="mediaLink"
                       />
                   </div>
                </div>

                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                  Other Attributes (Optional)
                                </h6>
                 <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Should Show &nbsp;&nbsp; <input type="checkbox" id="shouldShow" name="shouldShow" checked={shouldShowChecked} onChange={e => setShouldShowChecked(e.target.checked)} />
                      </label>

                    </div>
                 </div>

                <hr className="mt-6 border-b-1 border-blueGray-300" />


                <div className="w-full mt-4 mb-3">
                    <button
                        className="bg-primary text-white font-bold uppercase text-sm px-4 p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="submit"
                    >
                        Add Category
                    </button>
                </div>

              </form>
            </div>
        </>
    )
}