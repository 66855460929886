import React, {useEffect, useState, useMemo} from "react";
import {useHistory} from "react-router-dom";
import {sendGetRequestAll} from "../../helpers/httprequesthelper";
import IndexNavbar from "../../components/Navbars/IndexNavbar";
import Footer from "../../components/Footers/Footer";
import QRCode from "react-qr-code";
import Carousel from "react-multi-carousel";
import * as Constants from "../../constants/constants";
import Rating from "react-rating";
import StarRating from 'react-star-rating';
import * as FormatHelper from "../../helpers/formathelper";
import PlacardComponent from "../../components/PlacardComponent";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import {daysLeft} from "../../helpers/formathelper";
import { Link } from "react-router-dom";
import StaticProducts from "../../components/ProductComponents/StaticProducts";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { MaterialReactTable } from 'material-react-table';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "helpers/firebase.js";
import {getDateFromTimestamp, getAddressLabelString, getDateAndTimeFromTimestamp} from "../../helpers/formathelper";
import Loader from "components/Loader/Loader.js"

export default function BrandView(props) {
    const [data, setData] = useState(null);
    const navigate = useHistory();
    const [productData, setProductData] = useState([]);
    const [user, loading, error] = useAuthState(auth);


    const responsive = {
        tv: {
            breakpoint: { max: 3000, min: 1401 },
            items: 4,
            slidesToSlide: 1 // optional, default to 1.
        },
        desktop: {
            breakpoint: { max: 1400, min: 1024 },
            items: 4,
            slidesToSlide: 1 // optional, default to 1.
        },
        laptop: {
            breakpoint: { max: 1023, min: 860 },
            items: 4,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 860, min: 464 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    const createProductDetailsObject = (data) => {
        if (data == null) {
            return;
        }
        var productData = [];
        data.products.forEach(function (product) {
            productData.push({
                          "image" : {"img" : product.mediaLink, "url" : product.urlLink},
                          "description" : {"title" : product.title, "subtitle" : product.subtitle},
                          "mrp" : product.mrp,
                          "splprice" : {"price" : product.sellingPrice, "discount" : product.discountPercentage}})
        });
        setProductData(productData);
    }

    const columns = useMemo(
        () => [
            {
                header: "Product",
                accessorKey: 'image', //access nested data with dot notation
                Cell: ({ cell }) => (
                    <a href={cell.getValue().url}>
                        <img
                          alt={cell.getValue().url}
                          linka={cell.getValue().url}
                          height={10}
                          src={cell.getValue().img}
                          loading="lazy"
                          className="w-auto h-auto align-middle max-h-120-px max-w-120-px"
                        />
                    </a>
                ),
                size: 30
            },
            {
                header: "Description",
                accessorKey: 'description', //access nested data with dot notation
                Cell: ({ cell }) => (
                    <>
                        <p className="font-bold">
                            {cell.getValue().title}
                        </p>
                        <br/>
                        <br/>
                        <p className="text-sm">
                            {cell.getValue().subtitle}
                        </p>
                    </>
                ),
                size: 300
            },
            {
                header: "MRP",
                accessorKey: 'mrp', //access nested data with dot notation
                size: 10
            },
            {
                header: "Spl Price",
                accessorKey: 'splprice', //access nested data with dot notation
                size: 10,
                Cell: ({ cell }) => (
                    <>
                        <p className="font-bold text-lg">
                            {cell.getValue().price}
                        </p>
                        <br/>
                        <br/>
                        <p className="text-sm">
                            ({cell.getValue().discount} % off)
                        </p>
                    </>
                ),
            }
        ],
        [],
    );

    const fetchData = async () => {
        try {
            var response = await sendGetRequestAll(Constants.backendUrl + "/brand/"+props.match.params.bid, false);
            createProductDetailsObject(response.data);
            setData(response.data);
        } catch (err) {
            console.error(err);
            if (err && err.response && err.response.data) {
                alert("An error occured while loading brand data : " + err.response.data);
            } else {
                alert("An error occured while loading brand data");
            }
            return navigate.push("/");
        }
    };

    function downloadPdf() {
        if (data != null && data.products != null && data.products.length > 0) {
            const doc = new jsPDF()
            doc.setFontSize(8);
            doc.text("Downloaded on " + getDateAndTimeFromTimestamp(Date.now()), 15, 5);
            doc.addImage(data.mediaLink, 10, 10, 40, 30);
            doc.setFont(undefined, 'bold')
            doc.setFontSize(15)
            doc.text(data.title, 50, 20);
            doc.setFont(undefined, 'italic')
            doc.setFontSize(10)
            var splitTitle = doc.splitTextToSize(data.description, 130);
            doc.text(50, 28, splitTitle);

            doc.setFont(undefined, 'normal')
            doc.setFontSize(13)
            doc.setTextColor(0,150,255);
            doc.text("Place the order : " + Constants.baseUrl + data.urlLink , 15, 52);
            doc.setTextColor(0,0,0);

            doc.autoTable({ html: '.MuiTable-root', rowPageBreak: 'avoid' , startY:60, theme: 'grid', font: 'helvetica', columnStyles: { 0: {halign: 'center', valign : 'middle', cellWidth: 35}, 1: {cellWidth: 110, cellPadding: 3 }, 2: {halign: 'center'}, 3: {halign: 'center',fontStyle: 'bold'} },
                bodyStyles: {minCellHeight: 38, fontSize: 9, cellPadding:1, valign:'middle'},
                headStyles: {fillColor: [0, 150, 255]},
                didDrawCell: function(data) {
                    try {
                      if (data.column.index === 0 && data.cell.section === 'body') {
                         var td = data.cell.raw;
                         var img = td.getElementsByTagName('img')[0];
                        console.log(img);
                        console.log(img.alt);
                        console.log(img.linka);
                         var dim = data.cell.height - data.cell.padding('vertical');
                         var textPos = data.cell;
                         doc.addImage(img.src, textPos.x + 3,  textPos.y + 3, 30, 30);
                         doc.setFontSize(7)
                         doc.text("Click here to order", textPos.x + 8, textPos.y + 35);
                         doc.link(textPos.x + 3, textPos.y + 3, 30, 30, {url: Constants.baseUrl + img.alt});
                      }
                    } catch (e) {
                        console.log(e);
                    }
                }
            })

            doc.save( data.id + '.pdf')
        }
    }

    useEffect(() => {
        if (loading) return;
        fetchData();
    }, [user, loading]);

    return (
        <>
            <IndexNavbar />
            {!data && (
               <Loader data={data}/>
            )}
            {data && (
            <>
            <section className="relative block h-400-px">
                <div
                    className="absolute top-0 w-full h-full bg-center bg-cover"
                    style={{
                        backgroundImage: "url(" + Constants.brandViewBackgroundImage + ")",
                    }}
                >
            <span
                id="blackOverlay"
                className="w-full h-full absolute opacity-50 bg-black"
            ></span>
                </div>
                <div
                    className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                    style={{ transform: "translateZ(0)" }}
                >
                    <svg
                        className="absolute bottom-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="text-blueGray-200 fill-current"
                            points="2560 0 2560 100 0 100"
                        ></polygon>
                    </svg>
                </div>
            </section>


            <section className="relative py-16 bg-blueGray-200">
                <div className="container mx-auto px-2">
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
                        <div className="p-2">

                            <div className="mb-4 flex flex-wrap justify-between w-full">
                                <div className="lg:w-4/12 w-full p-2 flex flex-wrap justify-center">
                                    <img
                                        alt="..."
                                        src={data.mediaLink}
                                        className="rounded-full max-h-200-px"
                                    />
                                </div>
                                <div className="lg:w-8/12 p-3">
                                    {data.title != null && (
                                        <h3 className="text-4xl uppercase font-semibold leading-normal mb-2 text-blueGray-700">
                                            {data.title}
                                        </h3>
                                    )}
                                    {data.description != null && (
                                        <div className="mt-3">
                                            <p className="text-md">{data.description}</p>
                                        </div>
                                    )}
                                    {data.starRating != null && (
                                        <div className="mt-4">
                                            <Rating initialRating={data.starRating} emptySymbol={<i className="fas fa-star brand-rating-star-empty text-md"></i>} fullSymbol={<i className="fas fa-star brand-rating-star-filled text-md"></i>} readonly={true}/>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div>
                                <PlacardComponent data={data.placardData}/>
                            </div>

                            { data != null && data.products != null && data.products.length > 0 && (
                                <div className="mt-6 p-2">
                                    <div className="mt-2 w-full flex flex-wrap justify-end">
                                        <button className="btn-outline btn-default-outline p-1 mt-2" onClick={downloadPdf}>Download Catalog <i className="fa fa-print" aria-hidden="true"></i></button>
                                    </div>
                                    <StaticProducts data={data.products} subtitle={"All Products by " + data.title}/>

                                </div>
                            )}

                            { data!= null && data.products != null && (
                                <div className="mt-4" style={{ display: "none" }}>
                                    <MaterialReactTable columns={columns} data={productData}
                                        enableColumnActions={false}
                                        enableColumnFilters={false}
                                        enablePagination={false}
                                        id="my-table"
                                        enableSorting={false}
                                        enableBottomToolbar={false}
                                        enableTopToolbar={true}
                                        muiTableBodyRowProps={{ hover: false }}/>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            </>
            )}
            <Footer />

        </>
    );
}