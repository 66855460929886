import React, {useEffect, useState} from "react";
import {sendGetRequestAll, sendPostRequestAll} from "../../helpers/httprequesthelper";
import IndexNavbar from "../../components/Navbars/IndexNavbar";
import Footer from "../../components/Footers/Footer";
import 'react-responsive-modal/styles.css';
import {HttpStatusCode} from "axios";
import UserCheckoutComponent from "../../components/Checkout/UserCheckoutComponent";
import {useHistory} from "react-router-dom";
import * as Constants from "constants/constants.js";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "helpers/firebase.js";
import Loader from "components/Loader/Loader.js"

export default function UserCheckoutView(props) {
    const [data, setData] = useState(null);
    const maxRetryCount = 5;
    const [orderPlacedData, setOrderPlacedData] = useState(null);
    var retryCount = 0;
    const navigate = useHistory();
    const [user, loading, error] = useAuthState(auth);

    async function placeOrder() {
        try {
            var response = await sendPostRequestAll(Constants.backendUrl + "/order", {}, true);
            if (response.status == HttpStatusCode.Ok) {
                navigate.push('/orderplaced/' + response.data.id,{params:response.data})
            } else {
                console.log("error while placing the order");
            }
        } catch (err) {
            if (err && err.response && err.response.data) {
                alert(err.response.data);
            }
            console.log("Error in placeOrder : ");
            console.error(err);
        }
    }

    const fetchData = async () => {
        try {
            var response = await sendGetRequestAll(Constants.backendUrl + "/checkout", true);
            console.log(response);
            if (response.status == HttpStatusCode.Ok) {
                setData(response.data);
            } else {
                setData(null);
            }
        } catch (err) {
            setData(null);
            if (err instanceof TypeError && retryCount < maxRetryCount) {
                console.log("Retrying");
                retryCount = retryCount + 1;
                setTimeout(fetchData, 1000);
            }
        }
    };

    useEffect(async () => {
        if (loading) return;
        if (!user) return navigate.push("/");
        fetchData();
    }, [user, loading]);

    return (
        <>
            <IndexNavbar />
            {!data && (
                           <Loader/>
                        )}

                {data && (
                    <>
                    <section className="relative block h-400-px">
                        <div
                            className="absolute top-0 w-full h-full bg-center bg-cover"
                            style={{
                                backgroundImage: "url(" + Constants.userCheckoutViewBackgroundImage + ")",
                            }}
                        >
                    <span
                        id="blackOverlay"
                        className="w-full h-full absolute opacity-50 bg-black"
                    ></span>
                        </div>
                        <div
                            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                            style={{ transform: "translateZ(0)" }}
                        >
                            <svg
                                className="absolute bottom-0 overflow-hidden"
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                version="1.1"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="text-blueGray-200 fill-current"
                                    points="2560 0 2560 100 0 100"
                                ></polygon>
                            </svg>
                        </div>
                    </section>


                    <section className="relative py-16 bg-blueGray-200">
                        <div className="container mx-auto px-2">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-80">
                                <div className="p-2">
                                    <div className="mb-4 flex flex-wrap justify-between w-full">
                                        <div className="w-full p-3">
                                            <h3 className="text-4xl text-center uppercase font-semibold leading-normal mb-2 text-blueGray-700">
                                                CheckOut
                                            </h3>
                                        </div>
                                        {data != null && data.productsMap != null && data.productsMap.size != 0 && (
                                            <>
                                                <div className="w-full">
                                                    <UserCheckoutComponent data={data} placeOrder={placeOrder}/>
                                                </div>
                                            </>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    </>
                )}
            <Footer />

        </>
    );
}