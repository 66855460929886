import {Link} from "react-router-dom";

export default function PlacardHeader(props) {
    return (
    <>
    <div
        className="flex flex-wrap justify-between w-full mb-4 p-2 border-radius-5px" style=
        {{
            backgroundColor: 'rgba(256, 256, 256, 0.8)',
            borderRadius: '5px!important'
        }}>
            <div>
                { props.title && (
                    <h1 className="font-semibold text-2xl text-left">
                        {props.title}
                    </h1>
                )}
                { props.subtitle && (
                    <h1 className="text-lg text-left">
                        {props.subtitle}
                    </h1>
                )}
                { props.description && (
                    <h1 className="text-md text-left">
                        {props.description}
                    </h1>
                )}
            </div>
            { props.viewAllLink && (
                <Link to={props.viewAllLink}>
                    <button
                        className="bg-indigo-500 text-white p-2 active:bg-indigo-600 text-xs font-bold uppercase px-4 rounded outline-none focus:outline-none ease-linear transition-all duration-150"
                        type="button"><i className="fas fa-arrow-right"></i></button>
                </Link>
            )}

    </div>
    </>
    );
}