import React from "react";
import * as Constants from "../../constants/constants";

export default function StandardPricingAndCartSection(props) {
    return (
        <>
            { props.mrp!=null && props.sellingPrice!=null && !props.invertColors && (
                <div>
                    <h4 className="text-sm text-left font-light mrp ml-3">Mrp : {Constants.currency + " " + props.mrp}</h4>
                    <h4 className="text-lg font-bold text-left ml-3">
                        {Constants.currency + " " + props.sellingPrice} &nbsp; &nbsp;
                        {props !=null && props.discountPercentage!=null && (
                            <span className="mb-3 text-2xs mt-4 font-semibold inline-block  px-2 uppercase rounded text-emerald-600 bg-emerald-200 uppercase last:mr-0 mr-1">
                                      {Math.trunc(props.discountPercentage)} % off
                                    </span>
                        )}
                    </h4>
                </div>
            )}

            { props.mrp!=null && props.sellingPrice!=null && props.invertColors && (
                <div
                    className="flex flex-wrap justify-between w-full">
                    <div>
                        <p className="text-lg font-bold text-white">
                            &nbsp;&nbsp; {Constants.currency + " " + props.sellingPrice} &nbsp; <span className="text-sm font-light text-white mrp">{Constants.currency + " " + props.mrp}</span> &nbsp;
                            {props !=null && props.discountPercentage!=null && (
                                <span className="text-2xs mt-4 font-semibold text-black inline-block py-1 px-2 uppercase rounded text-emerald-600 bg-emerald-200 uppercase last:mr-0 mr-1">
                                          {Math.trunc(props.discountPercentage)} % off
                                        </span>
                            )}
                        </p>
                    </div>
                </div>
            )}
        </>
    );
}