import {Link} from "react-router-dom";
import Product from "../ProductComponents/Product";
import PlacardHeader from "../Headers/PlacardHeader";

export default function StaticProducts4Half(props) {
    return (
        <div className="w-full p-2 card">
            <PlacardHeader title={props.title} subtitle={props.subtitle} description={props.description} viewAllLink={props.viewAllLink}/>
            <div className="mb-2 flex flex-wrap">
                { props.data !=null && props.data.map(function(dataelement, index){
                    return (
                        <>
                            <div className="xs:w-6/12 sm:w-6/12 p-1">
                                <div className="card-light h-full p-1">
                                    <Product dataelement={dataelement}/>
                                </div>
                            </div>
                        </>
                    );
                })}
            </div>
            { props.viewAllLink && (
            <div className="mt-6 mb-3 w-full flex flex-wrap justify-center">
                <div className="lg:w-3/12 w-full px-4">
                    <Link to={props.viewAllLink}>
                    <button
                        className="bg-primary text-white active:bg-lightBlue-600 w-full text-xs font-bold uppercase px-4 py-2 rounded shadow"
                        type="button"
                    >
                        View All &nbsp; &nbsp;<i className="fas fa-arrow-right"></i>
                    </button>
                    </Link>
                </div>
            </div>
            )}
        </div>
    );
}