import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import PlacardHeader from "../Headers/PlacardHeader";

export default function StaticVideoPlayer(props) {
    return (
        <>
            <PlacardHeader title={props.title} subtitle={props.subtitle} description={props.description} viewAllLink={props.viewAllLink}/>
            <div
                className="mb-2">

                {props.data!=null && props.data.map(function(dataelement, index){
                    return <div className="lg:w-4/12 w-full float-left" key={dataelement.id}>
                        <div className="w-full">
                            <div
                                className="flex flex-wrap w-full mb-2 p-3 ">

                                <ReactPlayer width='100%' height="250px" url={dataelement.mediaLink} />

                            </div>
                        </div>
                    </div>
                })}

            </div>
        </>
    );
}