import React, {useEffect, useState, useMemo} from "react";
import IndexNavbar from "../../components/Navbars/IndexNavbar";
import Footer from "../../components/Footers/Footer";
import 'react-responsive-modal/styles.css';
import { MaterialReactTable } from 'material-react-table';
import {HttpStatusCode} from "axios";
import * as Constants from "../../constants/constants";
import { useLocation, Link, useHistory } from "react-router-dom";
import {sendGetRequestAll, sendPostRequestAll} from "../../helpers/httprequesthelper";
import {getDateFromTimestamp, getAddressLabelString, getDateAndTimeFromTimestamp} from "../../helpers/formathelper";
import {Modal} from "react-responsive-modal";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "helpers/firebase.js";
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import Loader from "components/Loader/Loader.js"

export default function UserOrderDetailView(props) {

    var retryCount = 0;
    const maxRetryCount = 5;
    const [data, setData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [message, setMessage] = useState(null);
    const [openOrderUpdatesModal, setOpenOrderUpdatesModal] = useState(false);
    const onCloseOrderUpdatesModal = () => setOpenOrderUpdatesModal(false);
    const onOpenOrderUpdatesModal = () => setOpenOrderUpdatesModal(true);

    const [openCancelOrderModal, setOpenCancelOrderModal] = useState(false);
    const onCloseCancelOrderModal = () => setOpenCancelOrderModal(false);
    const onOpenCancelOrderModal = () => setOpenCancelOrderModal(true);
    const [user, loading, error] = useAuthState(auth);
    const navigate = useHistory();

    function hideMessage() {
        setMessage(null);
    }

    function printOrderSummary() {
        if (data != null) {
            const doc = new jsPDF()
            doc.setFontSize(8);
            doc.text("Downloaded on " + getDateAndTimeFromTimestamp(Date.now()), 15, 5);
            doc.addImage(Constants.logoUrlForInvoice, 'JPEG', 10, 10, 30, 30);
            doc.setFontSize(14)
            doc.text(Constants.nameForInvoice, 15, 42);
            doc.setFontSize(10)
            doc.text(Constants.addressLine1ForInvoice, 15, 48);
            doc.text(Constants.addressLine2ForInvoice, 15, 53);
            doc.text(Constants.addressLine3ForInvoice, 15, 58);
            doc.text(Constants.contactNumberForInvoice, 15, 65);
            doc.setFontSize(12)
            doc.text("Order Summary / Estimate", 140, 20);
            doc.setFontSize(9)
            doc.setFont(undefined, 'bold')
            doc.text("Order Id", 155, 60);
            doc.setFont(undefined, 'normal')
            doc.setFontSize(10)
            doc.text(data.id, 140, 65);
            doc.autoTable({ html: '.MuiTable-root', rowPageBreak: 'avoid' , startY:70, theme: 'grid', font: 'helvetica', columnStyles: { 1: {halign: 'center', valign : 'middle', cellWidth: 23}, 2: {cellWidth: 90 }, 6: {fontStyle: 'bold'} },
                bodyStyles: {minCellHeight: 25, fontSize: 8, cellPadding:1, valign:'middle', lineWidth:{top: 0, right: 0, bottom: 1, left: 0}},
                didDrawCell: function(data) {
                    try {
                      if (data.column.index === 1 && data.cell.section === 'body') {
                         var td = data.cell.raw;
                         var img = td.getElementsByTagName('img')[0];

                         var dim = data.cell.height - data.cell.padding('vertical');
                         var textPos = data.cell;
                         doc.addImage(img.src, textPos.x + 3,  textPos.y + 3, 17, 17);
                      }
                    } catch (e) {
                        console.log(e);
                    }
                }
            })
            let finalY = doc.previousAutoTable.finalY; //this gives you the value of the end-y-axis-position of the previous autotable.
            doc.setFont(undefined, 'bold')
            doc.setFontSize(12)
            doc.text("Shipping Address", 15, finalY + 10);
            doc.setFont(undefined, 'normal')
            doc.setFontSize(10)
            doc.text(getAddressLabelString(data.addressInfo), 15, finalY + 17);
            doc.setFont(undefined, 'normal')
            doc.setFontSize(11)
            doc.text("Total MRP", 120, finalY + 28);
            doc.text("Discount", 120, finalY + 35);
            doc.text("Order Total", 120, finalY + 42);
            doc.text("Pending Payment", 120, finalY + 50);

            doc.setFont(undefined, 'bold')
            doc.text("Rs " + data.totalMrp.toString(), 170, finalY + 28);
            var discount = data.totalMrp - data.totalAmount;
            doc.setFont(undefined, 'normal')
            doc.text("- Rs " + discount.toString(), 168, finalY + 35);
            doc.text("Rs " + data.totalAmount.toString(), 170, finalY + 42);
            var pendingPayment = data.totalAmount - data.paymentReceived;
            doc.setFont(undefined, 'bold')
            doc.text("Rs " + pendingPayment.toString(), 170, finalY + 50);
//            doc.setFontSize(13)
//            doc.setFont(undefined, 'bold')
//            var pendingPayment = data.totalAmount - data.paymentReceived;
//            doc.text("Rs " + pendingPayment.toString(), 15, finalY + 29);


            doc.save('order.pdf')
        }

    }

    const createProductDetailsObject = (data) => {
        if (data == null) {
            return;
        }
        var productData = [];
        Object.keys(data.productsMap).map((key, index) => {
            productData.push({"index" : index + 1,
              "image" : {"img" : data.productsMap[key].mediaLink, "url" : data.productsMap[key].urlLink},
              "description" : {"title" : data.productsMap[key].title, "subtitle" : data.productsMap[key].subtitle},
              "mrp" : data.productsMap[key].mrp,
              "splprice" : data.productsMap[key].sellingPrice,
              "quantity" : data.productsMap[key].quantity,
              "subtotal" : data.productsMap[key].sellingPrice * data.productsMap[key].quantity})
        });
        setProductData(productData);
        console.log(" productData : " + productData);
    }

    const columns = useMemo(
        () => [
            {
                header: "N.",
                accessorKey: 'index', //access nested data with dot notation
                size: 6
            },
            {
                header: "Product",
                accessorKey: 'image', //access nested data with dot notation
                Cell: ({ cell }) => (
                    <a href={cell.getValue().url}>
                        <img
                          alt="avatar"
                          height={10}
                          src={cell.getValue().img}
                          loading="lazy"
                          className="w-auto h-auto align-middle max-h-120-px max-w-120-px"
                        />
                    </a>
                ),
                size: 30
            },
            {
                header: "Description",
                accessorKey: 'description', //access nested data with dot notation
                Cell: ({ cell }) => (
                    <>
                        <p className="font-bold">
                            {cell.getValue().title}
                        </p>
                        <br/>
                        <br/>
                        <p className="text-sm">
                            {cell.getValue().subtitle}
                        </p>
                    </>
                ),
                size: 300
            },
            {
                header: "MRP",
                accessorKey: 'mrp', //access nested data with dot notation
                size: 10
            },
            {
                header: "Spl Price",
                accessorKey: 'splprice', //access nested data with dot notation
                size: 10
            },
            {
                header: "Qty",
                accessorKey: 'quantity', //access nested
                size: 10
            },
            {
                header: "Sub total",
                accessorKey: 'subtotal', //access nested
                Cell: ({ cell }) => (
                    <>
                        <p className="font-bold">
                            {cell.getValue()}
                        </p>
                    </>
                ),
                size: 10
            }
        ],
        [],
    );

    async function cancelOrder(id) {
        try {
            var response = await sendPostRequestAll(Constants.backendUrl + "/order/cancel?orderId=" + id, {}, true);
            if (response.status == HttpStatusCode.Ok) {
                setMessage("Your Order has been cancelled successfully.");
                setTimeout(hideMessage, 3000);
                onCloseCancelOrderModal();
                fetchData();
            } else {
                console.log("Error while cancelling the order");
            }
        } catch (err) {
            console.log("Error in cancelOrder : ");
            console.error(err);
        }
    }

    async function orderToCart (orderId) {
        var data = {
            "orderId" : orderId
        }
        try {
            var response = await sendPostRequestAll(Constants.backendUrl + "/mycart/ordertocart", data, true);

            if (response.status == HttpStatusCode.Ok) {
                if (response.data != null) {
                    setMessage(response.data);
                    setTimeout(hideMessage, 3000);
                }
            }
            console.log("Here the response : ");
            console.log(response);
        } catch (err) {
            console.log("Error in orderToCart : ");
            console.error(err);
        }
    }

    const fetchData = async () => {
        try {
            var response = await sendGetRequestAll(Constants.backendUrl + "/order/"+props.match.params.orderId, true);
            if (response.status == HttpStatusCode.Ok) {
                createProductDetailsObject(response.data);
                setData(response.data);
            } else {
                setData(null);
            }
        } catch (err) {
            setData(null);
            if (err instanceof TypeError && retryCount < maxRetryCount) {
                console.log("Retrying");
                retryCount = retryCount + 1;
                setTimeout(fetchData, 1000);
            }
        }
    };

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate.push("/");
        fetchData();
    }, [user, loading]);

    return (
        <>
            <IndexNavbar />
            {!data && (
               <Loader/>
            )}
            {data != null && (
                <>
                    {console.log(data)}
                    <section className="relative block h-400-px">
                        <div
                            className="absolute top-0 w-full h-full bg-center bg-cover"
                            style={{
                                backgroundImage: "url(" + Constants.userOrderDetailViewBackgroundImage + ")",
                            }}
                        >
                    <span
                        id="blackOverlay"
                        className="w-full h-full absolute opacity-50 bg-black"
                    ></span>
                        </div>
                        <div
                            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                            style={{ transform: "translateZ(0)" }}
                        >
                            <svg
                                className="absolute bottom-0 overflow-hidden"
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                version="1.1"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="text-blueGray-200 fill-current"
                                    points="2560 0 2560 100 0 100"
                                ></polygon>
                            </svg>
                        </div>
                    </section>


                    <section className="relative py-16 bg-blueGray-200">
                        <div className="container mx-auto px-2">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-80">
                                <div className="p-2">
                                    <div className="mb-4 flex flex-wrap justify-between w-full">
                                        <div className="w-full p-3 pl-4">
                                            <h3 className="text-3xl uppercase font-semibold leading-normal mb-2 text-blueGray-700">
                                                Order Details
                                            </h3>

                                            <p>
                                                Ordered on {getDateFromTimestamp(data.createdAt)}
                                            </p>
                                            <p className="text-xs font-bold text-lightBlue-600">
                                                Order Id #{data.id}
                                            </p>

                                            <p className="text-lg font-bold mt-3">
                                                {data.userOrderStatus}
                                            </p>
                                            <p className="text-sm mt-1">
                                                {data.statusMessage}
                                            </p>
                                            {data != null && !data.orderCancelled && data.deliveryDate != null && (
                                            <p className="mt-1 text-lightBlue-600 text-sm">
                                                Expected Delivery - <span className="font-bold">{data.deliveryDate}</span>
                                            </p>
                                            )}
                                            <button
                                                className="bg-lightBlue-500 text-white active:bg-lightBlue-400 text-sm mt-3 px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                type="button"
                                                onClick={onOpenOrderUpdatesModal}
                                            >
                                                View All Order Updates
                                            </button>

                                        </div>



                                        <div className="w-full">
                                              <hr/>
                                            { data!= null && data.productsMap != null && Object.keys(data.productsMap).length > 0 && (
                                                <div className="flex justify-between">
                                                    <div>
                                                        <p className="p-2 pl-4 text-lg mt-2">Items in this order </p>
                                                    </div>
                                                    <div>
                                                        <button className="btn-outline btn-default-outline p-1 mt-2" onClick={printOrderSummary}>Print <i className="fa fa-print" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            )}


                                        <Modal open={openOrderUpdatesModal} onClose={onCloseOrderUpdatesModal} center>
                                             <h2 className="text-xl mb-4 font-bold border-b pb-2 pr-12 addAddressModal">Order Updates</h2>
                                             {data != null && data.timeline != null && Object.keys(data.timeline).map((key, index) => {
                                                return <div className="mt-2" key={index}>
                                                    <p>{getDateAndTimeFromTimestamp(key)} - <span className="font-bold">{data.timeline[key]}</span></p>
                                                </div>
                                              //                console.log(key);
                                              //                var date1 = getDateFromTimestamp(key);
                                              //                console.log(date1);
                                              //                console.log(data.timeline[key]);
                                              //                items.push({"cardTitle": getDateAndTimeFromTimestamp(key), "cardSubtitle" : data.timeline[key]});
                                              //            });}
                                              })}
                                              {data != null && data.timeline != null && !data.orderCancelled && (
                                                <div className="mt-6 text-lightBlue-600">
                                                    <p>Order expected to be delivered on <span className="font-bold">{data.deliveryDate}</span></p>
                                                </div>
                                              )}
                                        </Modal>
                                        <Modal open={openCancelOrderModal} onClose={onCloseOrderUpdatesModal} center>
                                            <h2 className="text-xl mb-4 font-bold border-b pb-2 pr-12 addAddressModal">Confirm Cancellation</h2>
                                             <p>Are you sure you want to cancel this order ?</p>
                                             <div className="flex mt-3">
                                                <button className="text-white bg-lightBlue-600 font-bold uppercase text-sm p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                    onClick={onCloseCancelOrderModal}>No</button>
                                                <button className="ml-2 text-white bg-lightBlue-600 font-bold uppercase text-sm p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                    onClick={() => cancelOrder(data.id)}>Yes</button>
                                             </div>

                                        </Modal>
                                            { data!= null && data.productsMap != null && (
                                                <div className="mt-4">
                                                    <MaterialReactTable columns={columns} data={productData}
                                                        enableColumnActions={false}
                                                        enableColumnFilters={false}
                                                        enablePagination={false}
                                                        id="my-table"
                                                        enableSorting={false}
                                                        enableBottomToolbar={false}
                                                        enableTopToolbar={true}
                                                        muiTableBodyRowProps={{ hover: false }}/>
                                                </div>
                                            )}
                                        </div>

                                        <div className="w-full flex flex-wrap px-3">
                                            <div className="md:w-6/12 lg:w-5/12 w-full mt-4">
                                                <div className="p-2">
                                                    {data != null && data.addressInfo != null && (
                                                    <div>
                                                    <p className="font-bold text-md">
                                                        Shipping Address
                                                    </p>

                                                    <p>
                                                        {getAddressLabelString(data.addressInfo)}
                                                    </p>
                                                    </div>
                                                    )}


                                                    {data.paymentMethod != null && (
                                                        <div className="mt-3">
                                                            <h1 className="font-bold">Payment Mode</h1>
                                                            <p className="mt-1">{data.paymentMethod}</p>
                                                        </div>
                                                    )}

                                                    {data != null && !data.isOrderCancelled && (
                                                        <div className="mt-3">
                                                        <p className="font-bold text-md">
                                                            Pending Amount
                                                        </p>
                                                        <p>{Constants.currency + " " + (data.totalAmount - data.paymentReceived)}</p>
                                                        </div>
                                                    )}
                                                </div>



                                            </div>
                                            <div className="md:w-6/12 lg:w-7/12 w-full mt-4 p-2">
                                                <div className="w-full card p-2" id="doc">
                                                    <p className="font-bold text-md">
                                                        Order Summary
                                                    </p>
                                                    {data.totalMrp != null && data.totalMrp > 0 && (
                                                        <div className="flex justify-between mt-2">
                                                            <h1>MRP ({data.totalQuantity + " items"})</h1>
                                                            <p>{Constants.currency + " " + data.totalMrp}</p>
                                                        </div>

                                                    )}
                                                    {data.totalMrp - data.totalAmount > 0 && (
                                                        <div className="flex justify-between mt-2 mb-2">
                                                            <h1 className="text-md font-green">
                                                                Total Discount
                                                            </h1>
                                                            <p className="font-green">{" - " + Constants.currency + " " + (data.totalMrp - data.totalAmount)}</p>
                                                        </div>
                                                    )}
                                                    <hr/>
                                                    {data.totalAmount != null && data.totalAmount > 0 && (
                                                        <div className="flex justify-between mt-2">
                                                            <h1 className="font-bold">Total Price</h1>
                                                            <p className="font-bold">{Constants.currency + " " + data.totalAmount}</p>

                                                        </div>

                                                    )}

                                                </div>

                                                { (data.totalMrp - data.totalAmount) > 0 && (
                                                    <div className="w-full flex flex-wrap justify-center mt-4 font-green">
                                                        <p>Congratulations 🥳, You have saved {Constants.currency + " " + (data.totalMrp - data.totalAmount)} with this order </p>
                                                    </div>
                                                )}


                                            </div>
                                            {data!=null && !data.isOrderCancelled && (
                                                <div className="mt-4">
                                                    <button
                                                        className="bg-orange-500 w-full text-white active:bg-lightBlue-600 font-bold uppercase text-sm p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                        type="button"
                                                        onClick={onOpenCancelOrderModal}
                                                    >
                                                            <i className="fa fa-ban" aria-hidden="true"></i>&nbsp;&nbsp; Cancel Order
                                                    </button>
                                                </div>
                                            )}

                                            <div className="mt-4 ml-2">
                                                <button
                                                    className="bg-indigo-500 w-full text-white active:bg-lightBlue-600 font-bold uppercase text-sm p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                    type="button"
                                                    onClick={() => orderToCart(data.id)}
                                                >
                                                        <i className="fa fa-shopping-cart" aria-hidden="true"></i>&nbsp;&nbsp; Move Items to Cart
                                                </button>
                                            </div>


                                            { message!= null && (
                                                <div className="text-white px-6 py-3 border-0 w-full mt-4 rounded relative mb-4 bg-emerald-500">
                                                  <span className="text-xl inline-block mr-5 align-middle">
                                                    <i className="fas fa-bell"></i>
                                                  </span>
                                                    <span className="inline-block align-middle mr-8">
                                                     {message}
                                                  </span>
                                                    <button className="absolute bg-transparent text-2xl font-semibold leading-none right-10 top-0 mt-4 mr-6 outline-none focus:outline-none" onClick={()=>hideMessage()}>
                                                        <span>×</span>
                                                    </button>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )}
            <Footer />

        </>
    );
}