import axios from 'axios'

import { getAuth, onAuthStateChanged } from "firebase/auth";
import {app, firebaseConfig, auth} from "./firebase";
import {initializeApp} from "firebase/app";

export async function sendGetRequestAll(url, authorised) {
    if (!url) {
        throw new Error("URL is null for get request.");
    }
    if (!urlPatternValidation(url)) {
        throw new Error("URL validation failed");
    }
    if (authorised) {
        console.log(auth.currentUser);
        var token = await auth.currentUser.getIdToken();
        console.log(token);
        var data = await axios.get(url, {
            headers: {
                Authorization: token
            }
        });
        console.log(data);
        return data;
    } else if (authorised && !auth) {
        throw new Error("User is not logged in. Kindly login to proceed further");
    } else {
        var data = await axios.get(url);
        console.log(data);
        return data;
    }
}

export async function sendPostRequestAll(url, body, authorised) {
    if (!url) {
        throw new Error("URL is null for post request.");
    }
    if (!urlPatternValidation(url)) {
        throw new Error("URL validation failed");
    }
    if (authorised) {
        console.log(auth.currentUser);
        var token = await auth.currentUser.getIdToken();
        console.log(token);
        var data = await axios.post(url, body, {
            headers: {
                Authorization: token
            }
        });
        console.log(data);
        return data;
    } else if (authorised && !auth) {
        throw new Error("User is not logged in. Kindly login to proceed further");
    } else {
        var data = await axios.post(url, body);
        console.log(data);
        return data;
    }
}

export async function sendDeleteRequestAll(url, body, authorised) {
    if (!url) {
        throw new Error("URL is null for delete request.");
    }
    if (!urlPatternValidation(url)) {
        throw new Error("URL validation failed");
    }
    if (authorised) {
        console.log(auth.currentUser);
        var token = await auth.currentUser.getIdToken();
        console.log(token);
        var data = await axios.delete(url, body, {
            headers: {
                Authorization: token
            }
        });
        console.log(data);
        return data;
    } else if (authorised && !auth) {
        throw new Error("User is not logged in. Kindly login to proceed further");
    } else {
        var data = await axios.post(url, body);
        console.log(data);
        return data;
    }
}

export function urlPatternValidation(url) {
    return true;

    // const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
    // return regex.test(url);
};