import React, {useEffect, useState, useMemo} from "react";
import {sendGetRequestAll} from "../../helpers/httprequesthelper";
import IndexNavbar from "../../components/Navbars/IndexNavbar";
import Footer from "../../components/Footers/Footer";
import 'react-responsive-modal/styles.css';
import MerchantCart from "../../components/Cart/MerchantCart";
import {HttpStatusCode} from "axios";
import * as FormatHelper from "../../helpers/formathelper";
import * as Constants from "../../constants/constants";
import * as cartViewConstant from "../../constants/cartViewConstant";
import {Link, useHistory} from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "helpers/firebase.js";
import Loader from "components/Loader/Loader.js"

export default function UserCartView(props) {
    const [cartData, setCartData] = useState(null);
    var retryCount = 0;
    const maxRetryCount = 5;
    const [emptyCartData, setEmptyCartData] = useState(false);
    const [errorOnCartData, setErrorOnCartData] = useState(false);
    const [user, loading, error] = useAuthState(auth);
    const navigate = useHistory();

    const fetchData = async () => {
        try {
            var response = await sendGetRequestAll(Constants.backendUrl + "/mycart", true);
            if (response.status == HttpStatusCode.Ok) {
                setCartData(response.data);
                if (response.data.productsMap == null || response.data.productsMap.size == 0) {
                    setEmptyCartData(true);
                }
            } else {
                setCartData(null);
                setErrorOnCartData(true);
            }
        } catch (err) {
            setCartData(null);
        }
    };


    useEffect(() => {
        if (loading) return;
        if (!user) return navigate.push("/");
        fetchData();
    }, [user, loading]);

    return (
        <>
            <IndexNavbar />
            {!cartData && (
               <Loader/>
            )}
            {cartData && (
            <>
            <section className="relative block h-400-px">
                <div
                    className="absolute top-0 w-full h-full bg-center bg-cover"
                    style={{
                        backgroundImage:
                            "url(" + Constants.userCartViewBackgroundImage + ")",
                    }}
                >
            <span
                id="blackOverlay"
                className="w-full h-full absolute opacity-50 bg-black"
            ></span>
                </div>
                <div
                    className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                    style={{ transform: "translateZ(0)" }}
                >
                    <svg
                        className="absolute bottom-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="text-blueGray-200 fill-current"
                            points="2560 0 2560 100 0 100"
                        ></polygon>
                    </svg>
                </div>
            </section>


            <section className="relative py-16 bg-blueGray-200">
                <div className="container mx-auto px-2">
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-80">
                        <div className="p-2">

                            <div className="mb-4 flex flex-wrap justify-between w-full">
                                <div className="w-full p-3">
                                    <h3 className="text-3xl text-center uppercase font-semibold leading-normal mb-2 text-blueGray-700">
                                        My Cart
                                    </h3>

                                </div>
                                {cartData != null && cartData.productsMap != null && cartData.productsMap.size != 0 && (
                                    <>
                                    <div className="w-full">
                                            <MerchantCart data={cartData}/>
                                    </div>

                                    <div className="w-full mt-8 flex flex-wrap justify-center">
                                        <div className="pt-6 border-t">
                                            <p className="text-center text-blueGray-400">
                                                Products in cart can be delivered only in pincode <b>{Constants.pincode}</b> covering below areas :
                                            </p>
                                            <p className="text-center mt-2 text-blueGray-500">
                                                {FormatHelper.formatAreasWithCommaAnd(Constants.areas)}
                                            </p>
                                        </div>
                                    </div>
                                    </>
                                )}
                                {emptyCartData && (
                                    <div className="w-full">
                                        <div className="flex flex-wrap justify-center">
                                            <img src={cartViewConstant.emptyCartImageUrl} className="cart-view-empty-cart" />
                                        </div>
                                        <div>
                                            <p className="text-center font-bold text-xl">Your cart is empty !!</p>
                                            <p className="text-center text-lg">Explore our hot categories and save more.</p>
                                        </div>
                                        <div className="flex flex-wrap justify-center mt-6">
                                            <Link to="/store">
                                                <button
                                                    className="bg-primary text-white active:bg-lightBlue-600 font-bold uppercase text-md px-4 p-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                    type="button"
                                                >
                                                    <i className="fa-solid fa-store"></i> &nbsp;&nbsp; Shop Now
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                )}

                                {errorOnCartData && (
                                    <div className="w-full">
                                        <div className="flex flex-wrap justify-center">
                                            <img src={cartViewConstant.emptyCartImageUrl} className="cart-view-empty-cart" />
                                        </div>
                                        <div>
                                            <p className="text-center font-bold text-xl">There's some error loading the cart!!</p>
                                            <p className="text-center text-lg">Explore our hot categories and save more.</p>
                                        </div>
                                        <div className="flex flex-wrap justify-center mt-6">
                                            <Link to="/store">
                                                <button
                                                    className="bg-primary text-white active:bg-lightBlue-600 font-bold uppercase text-md px-4 p-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                    type="button"
                                                >
                                                    <i className="fa-solid fa-store"></i> &nbsp;&nbsp; Shop Now
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </>
            )}
            <Footer />

        </>
    );
}