import {React,useState,useEffect} from "react";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {sendGetRequestAll, sendPostRequestAll, sendDeleteRequestAll} from "../../helpers/httprequesthelper";
import * as Constants from "constants/constants.js";
import {Modal} from "react-responsive-modal";
import {HttpStatusCode} from "axios";
import Multiselect from 'multiselect-react-dropdown';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "helpers/firebase.js";
import {useHistory} from "react-router-dom";

export default function UpdateCategory() {
    var fetchCategoryDataRetryCount = 0;
    var fetchPlacardDataRetryCount = 0;
    const [showForm, setShowForm] = useState(false);
    const maxRetryCount = 5;
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [title, setTitle] = useState(null);
    const [selectedPlacardIds, setSelectedPlacardIds] = useState(null);
    const [id, setId] = useState(null);
    const [data, setData] = useState(null);
    const [searchId, setSearchId] = useState(null);
    const [showParentCategoryInput, setShowParentCategoryInput] = useState(false);
    const [showChildCategoryInput, setShowChildCategoryInput] = useState(false);
    const [parentCategoryDataOptions, setParentCategoryDataOptions] = useState(null);
    const [childCategoryDataOptions, setChildCategoryDataOptions] = useState(null);
    const [description, setDescription] = useState(null);
    const [placardDataOptions, setPlacardDataOptions] = useState(null);
    const [selectedLevel, setSelectedLevel] = useState(0);
    const [mediaLink, setMediaLink] = useState(null);
    const [selectedParentCategory, setSelectedParentCategory] = useState(null);
    const [selectedChildCategory, setSelectedChildCategory] = useState(null);
    const [shouldShowChecked, setShouldShowChecked] = useState(true);
    const [openDeleteCategoryModal, setOpenDeleteCategoryModal] = useState(false);
    const [user, loading, error] = useAuthState(auth);
    const navigate = useHistory();

    async function deleteCategory() {
        try {
            console.log("id ==== "  + id);
            var response = await sendDeleteRequestAll(Constants.backendUrl + "/admin/category/delete/" + id, {}, true);

            if (response.status == HttpStatusCode.Ok) {
                if (response.data != null) {
                    setOpenDeleteCategoryModal(false);
                    setSuccessMessage("Delete successful");
                    setOpenSuccessModal(true);
                    console.log(response.data);
                    setData(null);
                    setShowForm(false);
                }
            } else {
                setErrorMessage(response.data);
                setOpenErrorModal(true);
            }
        } catch (err) {
            console.log("Error in deleting the category data : ");
            console.error(err);
            setOpenErrorModal(true);
            setErrorMessage(err.response.data);
        }
    }

    function onCloseErrorModal() {
        setOpenErrorModal(false);
        setErrorMessage(null);
    }

    function onCloseSuccessModal() {
        setOpenSuccessModal(false);
        setSuccessMessage(null);
    }

    const onOpenDeleteCategoryModal = () => setOpenDeleteCategoryModal(true);

    async function handleSearch(e) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        var formJson = Object.fromEntries(formData.entries());
        if(!formJson.searchId || !formJson.searchId.trim()) {
            setErrorMessage("Search Id is empty");
            setOpenErrorModal(true);
            return;
        }
        console.log("formJson");
        console.log(formJson)
        await fetchCategoryDataById(formJson.searchId);
    }

    function onCloseDeleteCategoryModal() {
        setOpenDeleteCategoryModal(false);
    }

    async function fetchCategoryDataById(searchId) {
        try {
            var response = await sendGetRequestAll(Constants.backendUrl + "/admin/category/" + searchId, true);

            if (response.status == HttpStatusCode.Ok) {
                if (response.data != null) {
                    console.log("Hello : " + JSON.stringify(response.data));
                    await updateFormData(response.data);
                }
            } else {
                setErrorMessage(response.data);
                setOpenErrorModal(true);
            }
            console.log("Here the response : ");
            console.log(response);
        } catch (err) {
            console.log("Error in getting the product data : ");
            console.error(err);
            setOpenErrorModal(true);
            setErrorMessage(err.response.data);
        }
    }

    async function updateFormData(data) {
        console.log("updateFormData : " + JSON.stringify(data));
        setId(data.id);
        await fetchCategories(data.level);
        setTitle(data.title);
        setSelectedLevel({"value" : data.level, "label" : data.level});
        setDescription(data.description);
        setShouldShowChecked(data.shouldShow);
        setMediaLink(data.mediaLink);
        if (data.parentCategoryId) {
            setSelectedParentCategory({"value" : data.parentCategoryId, "label" : data.parentCategoryId});
            setShowParentCategoryInput(true);
        }
        if (data.childCategoryIds) {
            setSelectedChildCategory(getMultiCategoriesOptionFromArray(data.childCategoryIds));
            setShowChildCategoryInput(true);
        }
        if (data.plaCardIds) {
            setSelectedPlacardIds(getMultiCategoriesOptionFromArray(data.plaCardIds));
        }

        setShowForm(true);
        setData(data);
    }

    function getArrayFromSelectedMultipleOptions(data) {
        var arr = [];
        if (!data) {
            return null;
        }
        data.forEach(function(item, index) {
            arr.push(item.id);
        })
        return arr;
    }

    function selectChildCategories(selectedList, selectedItem) {
        setSelectedChildCategory(selectedList);
    }

    function selectPlacardIds(selectedList, selectedItem) {
        setSelectedPlacardIds(selectedList);
    }

    async function fetchPlacardData() {
        try {
            var response = await sendGetRequestAll(Constants.backendUrl + "/admin/placard", true);
            if (response.data != null) {
                setPlacardDataOptions(getMultiPlacardOption(response.data));
            }
        } catch (err) {
            console.error(err);
            console.error("Error Occurred while fetchPlacardData");
            if (err instanceof TypeError && fetchPlacardDataRetryCount < maxRetryCount) {
                console.log("Retrying fetchPlacardData");
                fetchPlacardDataRetryCount = fetchPlacardDataRetryCount + 1;
                setTimeout(fetchPlacardData, 1000);
            }
        }
    };

    async function fetchCategoryData(level) {
        try {
            var response = await sendGetRequestAll(Constants.backendUrl + "/admin/category?level=" + level, true);
            if (response.data != null) {
                return response.data;
            }
        } catch (err) {
            console.error(err);
            console.error("Error Occurred while fetchCategoryData");
            if (err instanceof TypeError && fetchCategoryDataRetryCount < maxRetryCount) {
                console.log("Retrying");
                fetchCategoryDataRetryCount = fetchCategoryDataRetryCount + 1;
                setTimeout(fetchCategoryData, 1000);
            }
        }
    };

    function getCategoriesOption(data) {
        var categoriesOption = [];
        if (data != null) {
            data.forEach(function (category, index) {
              categoriesOption.push({value : category.id, label : category.id})
            });
        }
        return categoriesOption;
    }

    function getMultiCategoriesOption(data) {
        var categoriesOption = [];
        if (data != null) {
            data.forEach(function (category, index) {
              categoriesOption.push({name : category.id, id : category.id})
            });
        }
        return categoriesOption;
    }

    function getMultiCategoriesOptionFromArray(data) {
        var categoriesOption = [];
        if (data != null) {
            data.forEach(function (id, index) {
              categoriesOption.push({name : id, id : id})
            });
        }
        return categoriesOption;
    }

    function getMultiPlacardOption(data) {
        var placardOptions = [];
        if (data != null) {
            data.forEach(function (item, index) {
              placardOptions.push({name : item.id, id : item.id})
            });
        }
        return placardOptions;
    }

    const fetchCategories = async(level) => {
        if (level == 1 || level == 2) {
            fetchCategoryDataRetryCount = 0;
            var parentCategories = await fetchCategoryData(level - 1);
            setParentCategoryDataOptions(getCategoriesOption(parentCategories));
            setShowParentCategoryInput(true);
        } else {
            setSelectedParentCategory(null);
            setParentCategoryDataOptions(null);
        }
        if (level == 0 || level == 1) {
            fetchCategoryDataRetryCount = 0;
            var childCategories = await fetchCategoryData(level + 1)
            setChildCategoryDataOptions(getMultiCategoriesOption(childCategories));
            setShowChildCategoryInput(true);
        } else {
            setSelectedChildCategory(null);
            setChildCategoryDataOptions(null);
        }
    }

    const fetchParentChildCategories = async(e) => {
        console.log("e : " + JSON.stringify(e));
        setSelectedLevel(e);
        setSelectedParentCategory(null);
        setParentCategoryDataOptions(null);
        setSelectedChildCategory(null);
        setChildCategoryDataOptions(null);
        if (e.value == 1 || e.value == 2) {
            fetchCategoryDataRetryCount = 0;
            var parentCategories = await fetchCategoryData(e.value - 1);
            setParentCategoryDataOptions(getCategoriesOption(parentCategories));
        } else {
            setSelectedParentCategory(null);
            setParentCategoryDataOptions(null);
        }
        if (e.value == 0 || e.value == 1) {
            fetchCategoryDataRetryCount = 0;
            var childCategories = await fetchCategoryData(e.value + 1)
            setChildCategoryDataOptions(getMultiCategoriesOption(childCategories));
        } else {
            setSelectedChildCategory(null);
            setChildCategoryDataOptions(null);
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        var formJson = Object.fromEntries(formData.entries());
        if(!formJson.id || !formJson.id.trim()) {
            setErrorMessage("Id is empty");
            setOpenErrorModal(true);
            return;
        }
        if(!formJson.title || !formJson.title.trim()) {
            setErrorMessage("Title is empty");
            setOpenErrorModal(true);
            return;
        }
        if(selectedLevel == null) {
            setErrorMessage("Level is not selected");
            setOpenErrorModal(true);
            return;
        }
        if(selectedLevel.value > 0 && !selectedParentCategory) {
            setErrorMessage("Parent Category is not selected when level is > 0");
            setOpenErrorModal(true);
            return;
        }
        if(!formJson.mediaLink || !formJson.mediaLink.trim()) {
            setErrorMessage("Media Link is empty");
            setOpenErrorModal(true);
            return;
        }
        formJson.shouldShow = shouldShowChecked;

        if (selectedParentCategory != null) {
            formJson.parentCategoryId = selectedParentCategory.value;
        }
        formJson.childCategoryIds = getArrayFromSelectedMultipleOptions(selectedChildCategory);
        if (selectedPlacardIds != null) {
            formJson.plaCardIds = getArrayFromSelectedMultipleOptions(selectedPlacardIds);
        }
        formJson.level = selectedLevel.value;

        console.log(formJson)

        try {
            var response = await sendPostRequestAll(Constants.backendUrl + "/admin/category/update", formJson, true);

            if (response.status == HttpStatusCode.Ok) {
                if (response.data != null) {
                    setSuccessMessage("Category Updated successfully");
                    setOpenSuccessModal(true);
                    console.log(response.data);
                }
            } else {
                setErrorMessage(response.data);
                setOpenErrorModal(true);
            }
            console.log("Here the response : ");
            console.log(response);
        } catch (err) {
            console.log("Error in submitting the category data : ");
            console.error(err);
        }
    }

    const addBulletPoint = () => {
        document.getElementById("bullet_point_div")
        .insertAdjacentHTML("beforeend",
            `<input
               type="text"
               class="border-0 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               name="bulletpoints"
            />`);
    }

    const addImageUrls = () => {
        document.getElementById("image_urls_div")
        .insertAdjacentHTML("beforeend",
            `<input
               type="text"
               class="border-0 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               name="imageUrls"
            />`);
    }

    const addVideoUrls = () => {
        document.getElementById("video_urls_div")
        .insertAdjacentHTML("beforeend",
            `<input
               type="text"
               class="border-0 mt-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               name="videoUrls"
            />`);
    }

    const levelOptions = [
      0,1,2
    ];


     useEffect(() => {
        if (loading) return;
        if (!user) return navigate.push("/");
        fetchPlacardData();
     }, [user, loading]);

    useEffect(async() => {
       const queryParams = new URLSearchParams(window.location.search);
       const value = queryParams.get('id');
       if (value && placardDataOptions && placardDataOptions.length > 0) {
           console.log("value : " + value);
           await fetchCategoryDataById(value);
       }
     }, [placardDataOptions]);

    return (
        <>
            <div className="flex-auto px-4 pt-0">
            <Modal open={openErrorModal} onClose={onCloseErrorModal} center>
                <h2 className="text-xl mb-4 font-bold border-b pb-2">Oops, An Error Occurred ! &nbsp; &nbsp; &nbsp; &nbsp;</h2>
                <p>{errorMessage}</p>
            </Modal>
            <Modal open={openSuccessModal} onClose={onCloseSuccessModal} center>
                <h2 className="text-xl mb-4 font-bold border-b pb-2">Operation successful &nbsp; &nbsp; &nbsp; &nbsp;</h2>
                <p>{successMessage}</p>
            </Modal>

            <Modal open={openDeleteCategoryModal} onClose={onCloseDeleteCategoryModal} center>
                <h2 className="text-xl mb-4 font-bold border-b pb-2">Confirm Delete this category?</h2>
                <p>Do you want to delete this category?</p>
                <p className="text-sm mb-2">(This is irreversible process)</p>
                <div className="flex mt-3">
                    <button className="text-white bg-lightBlue-600 font-bold uppercase text-sm p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        onClick={onCloseDeleteCategoryModal}>No</button>
                    <button className="ml-2 text-white bg-lightBlue-600 font-bold uppercase text-sm p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        onClick={deleteCategory}>Yes</button>
                 </div>
            </Modal>
                <h6 className="text-blueGray-700 text-md mt-3 mb-6 font-bold uppercase">
                 Update Category
                </h6>
              {showForm && (
              <form onSubmit={handleSubmit}>
                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Category Details
                </h6>
                <div className="flex flex-wrap">
                   <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Category Id (Unique *)
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        readOnly = {true}
                        name="id" value={id}
                      />
                    </div>
                  </div>

                  <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Category Title (*)
                      </label>
                      <input
                        type="text"
                        onChange={e => setTitle(e.target.value)}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        name="title" value={title}
                      />
                    </div>
                  </div>
                  <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Level
                      </label>
                      <Dropdown options={levelOptions} onChange={(e) => {fetchParentChildCategories(e)}} value={selectedLevel} placeholder="Select an option" />
                    </div>
                  </div>
                  {showParentCategoryInput && parentCategoryDataOptions != null && parentCategoryDataOptions.length > 0 && (
                    <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Parent Category
                          </label>
                          <Dropdown options={parentCategoryDataOptions} onChange={(e) => {setSelectedParentCategory(e)}} value={selectedParentCategory} placeholder="Select an option" />
                        </div>
                      </div>
                  )}

                  {showChildCategoryInput && childCategoryDataOptions != null && childCategoryDataOptions.length > 0 && (

                    <div className="w-full px-4">
                      <div className="relative w-full mb-3 mt-4">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Child Categories
                        </label>
                        <Multiselect
                          selectedValues={selectedChildCategory}
                          options={childCategoryDataOptions}
                          onSelect={selectChildCategories}
                          onRemove={selectChildCategories}
                          displayValue="name"
                          />
                      </div>
                    </div>

                  )}

                    {placardDataOptions != null && placardDataOptions.length > 0 && (

                        <div className="w-full px-4">
                          <div className="relative w-full mb-3 mt-4">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              Placard Ids
                            </label>
                            <Multiselect
                              selectedValues={selectedPlacardIds}
                              options={placardDataOptions}
                              onSelect={selectPlacardIds}
                              onRemove={selectPlacardIds}
                              displayValue="name"
                              />
                          </div>
                        </div>

                      )}

                  <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Description (*)
                    </label>
                    <textarea
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      rows="4"
                      onChange={e => setDescription(e.target.value)}
                      name="description" value={description}
                    ></textarea>
                  </div>
                </div>
                </div>

                <hr className="mt-6 border-b-1 border-blueGray-300" />

                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Images
                </h6>
                <div className="relative w-full mb-3 mt-4 px-4">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Media Link (*)
                  </label>
                  <div>
                      <input
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          name="mediaLink"
                          onChange={e => setMediaLink(e.target.value)}
                          value={mediaLink}
                       />
                   </div>
                   {mediaLink != null && (
                                            <div className="mt-4">
                                                 <p className="mb-2 text-sm">Media Link Preview</p>
                                                 <img src={mediaLink} className="max-h-150-px" />
                                            </div>
                                         )}
                </div>

                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                  Other Attributes (Optional)
                                </h6>
                 <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Should Show &nbsp;&nbsp; <input type="checkbox" id="shouldShow" name="shouldShow" checked={shouldShowChecked} onChange={e => setShouldShowChecked(e.target.checked)} />
                      </label>

                    </div>
                 </div>

                <hr className="mt-6 border-b-1 border-blueGray-300" />


                <div className="w-full mt-4 mb-3">
                    <button
                        className="bg-primary text-white font-bold uppercase text-sm px-4 p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="submit"
                    >
                        Update Category
                    </button>
                    <button
                        className="bg-red-500 text-white font-bold uppercase text-sm px-4 p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={onOpenDeleteCategoryModal}
                    >
                        Delete Category
                    </button>
                </div>

              </form>
              )}

              <hr className="mt-6 border-b-1 border-blueGray-300" />
                <div className="card p-4 mt-4 bg-white">
                <h2 className="text-xl mb-4 font-bold pb-2">Search Category by ID</h2>
                <form onSubmit={handleSearch} className="mt-3">
                    <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        name="searchId" value={searchId}
                      />
                    <button
                        className="bg-primary mt-4 text-white font-bold uppercase text-sm px-4 p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="submit"
                    >
                        <i class="fa fa-search" aria-hidden="true"></i> &nbsp; &nbsp; Search Category
                    </button>
                </form>
                </div>
            </div>
        </>
    )
}