import { Link } from "react-router-dom";
import PlacardHeader from "../Headers/PlacardHeader";

export default function StaticImages(props) {
    return (
        <>
            <PlacardHeader title={props.title} subtitle={props.subtitle} description={props.description} viewAllLink={props.viewAllLink}/>
            <div
                className="mb-2 flex flex-wrap">
                { props.data!=null && props.data.map(function(dataelement, index){
                    return  <Link to={dataelement.urlLink} className="lg:w-4/12 sm:w-6/12 xs:w-full" key={dataelement.id}>
                        <div className="p-1 w-full">
                            <img
                                src={dataelement.mediaLink}
                                className=" max-h-400-px"/>
                            { dataelement.title && (
                                <h4 className="static-images-title font-bold mt-2">{dataelement.title}</h4>
                            )}
                        </div>
                    </Link>
                })}

                { props.data!=null && props.data.length == 4 && props.data.map(function(dataelement, index){
                    return  <Link to={dataelement.urlLink} className="lg:w-6/12 sm:w-6/12 xs:w-6/12">
                        <div className="p-1 w-full">
                            <img
                                src={dataelement.mediaLink}
                                className="align-middle max-h-400-px"/>
                            { dataelement.title && (
                                <h4 className="static-images-title font-bold mt-2">{dataelement.title}</h4>
                            )}
                        </div>
                    </Link>
                })}

            </div>
        </>
    );
}