/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "helpers/firebase.js";
import * as Constants from "../../constants/constants";
import {
  query,
  getDocs,
  collection,
  where,
} from "firebase/firestore";
// components

import IndexDropdown from "components/Dropdowns/IndexDropdown.js";

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [user, loading, error] = useAuthState(auth);
    const [name, setName] = useState("");

  useEffect(() => {
      if (loading) return;
    }, [user, loading]);

  return (
    <>
      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              to="/"
              className="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
            >
              {Constants.name}
            </Link>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="flex items-center">
                <Link to="/about"
                      className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold">
                  About Us
                </Link>
              </li>

              <li className="flex items-center">
                <Link to="/contact"
                      className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold">
                  Contact Us
                </Link>
              </li>

              <li className="flex items-center">
                  <Link to="/store"
                        className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold">
                    Store
                  </Link>
                </li>


                { user && (
              <li className="flex items-center">
                <Link to="/manage/account" className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold">
                  Your Account
                </Link>
              </li>
              )}

              { user && (
                  <>
                    <li className="flex items-center">
                      <Link to="/mycart" className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold">
                        My Cart &nbsp;<i className="fa fa-shopping-cart"></i>
                      </Link>
                    </li>
                  </>
              )}
              { !user && (
                <li className="flex items-center">
                <Link to="/auth/login">
                  <button
                    className="bg-primary text-white active:bg-lightBlue-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                    type="button"
                  >
                    <i className="fas fa-arrow-right"></i> &nbsp; &nbsp; Register or Login
                  </button>
                  </Link>
                </li>
                )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
