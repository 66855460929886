import { Switch, Route, Redirect } from "react-router-dom";
import React, {useEffect, useState} from "react";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";
import ProductDashboard from "views/admin/ProductDashboard.js"
import AddNewProduct from "views/admin/AddNewProduct.js"
import UpdateProduct from "views/admin/UpdateProduct.js"
import FindProduct from "views/admin/FindProduct.js"
import AddNewCategory from "views/admin/AddNewCategory.js"
import UpdateCategory from "views/admin/UpdateCategory.js"
import AddNewBrand from "views/admin/AddNewBrand.js"
import UpdateBrand from "views/admin/UpdateBrand.js"
import UpdateUser from "views/admin/UpdateUser.js"
import AllBrand from "views/admin/AllBrand.js"
import AllUser from "views/admin/AllUser.js"
import AllCategory from "views/admin/AllCategory.js"
import AllProduct from "views/admin/AllProduct.js"
import {sendGetRequestAll, sendPostRequestAll} from "../helpers/httprequesthelper";
import * as Constants from "constants/constants.js";
import {HttpStatusCode} from "axios";

// views

import Dashboard from "views/admin/Dashboard.js";
import Maps from "views/admin/Maps.js";
import Settings from "views/admin/Settings.js";
import Tables from "views/admin/Tables.js";
import { useLocation, Link, useHistory} from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "helpers/firebase.js";

export default function Admin() {
    const [user, loading, error] = useAuthState(auth);
    const navigate = useHistory();
    useEffect (() => {
        if (loading) return;
        if (!user) return navigate.push("/");
        fetchIfAdmin()
    }, [user, loading]);

    const fetchIfAdmin = async () => {
        try {
            var response = await sendGetRequestAll(Constants.backendUrl + "/user/isadmin", true);
            if (response.status != HttpStatusCode.Ok) {
                return navigate.push("/");
            }
        } catch (err) {
            return navigate.push("/");
        }
    };

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        {/* Header */}

        <div className="relative md:pt-32 pb-32 pt-8">
            <div className="px-4 mx-auto w-full">
              <Switch>
                <Route path="/admin/dashboard" exact component={Dashboard} />
                <Route path="/admin/products" exact component={ProductDashboard} />
                <Route path="/admin/products/add" exact component={AddNewProduct} />
                <Route path="/admin/products/update" exact component={UpdateProduct} />
                <Route path="/admin/products/find" exact component={FindProduct} />
                 <Route path="/admin/products/all" exact component={AllProduct} />

                <Route path="/admin/categories/add" exact component={AddNewCategory} />
                <Route path="/admin/categories/update" exact component={UpdateCategory} />
                <Route path="/admin/categories/all" exact component={AllCategory} />

                <Route path="/admin/brands/add" exact component={AddNewBrand} />
                <Route path="/admin/brands/update" exact component={UpdateBrand} />
                <Route path="/admin/brands/all" exact component={AllBrand} />

                <Route path="/admin/users/update" exact component={UpdateUser} />
                <Route path="/admin/users/all" exact component={AllUser} />

                <Route path="/admin/maps" exact component={Maps} />
                <Route path="/admin/settings" exact component={Settings} />
                <Route path="/admin/tables" exact component={Tables} />
                <Redirect from="/admin" to="/admin/dashboard" />
              </Switch>
              <FooterAdmin />
            </div>
        </div>
      </div>
    </>
  );
}
